import React from 'react';
import { GlobalSystemParametersNames } from '../../../constants/GlobalSystemParametersNames';
import { formatDate, formatToCurrency, formatToCurrencyWithScale, getScaleForCurrencyPair, roundAmountByCurrency } from '../../../helpers/FormatHelper';
import { getNostroBankAccountsForFunds, getVirtualBankAccountsByCurrencyID } from '../../../networking/NetworkingBankAccounts';
import { getBankAccountsByCurrency, getFundOnHoldByCustomerAndCurrency } from '../../../networking/NetworkingCustomers';
import { GetRateForMultiplePaymentsOnPartner, getProfit, getRate } from '../../../networking/NetworkingRates';
import { getEntityProperties } from '../../../networking/NetworkingEntityProperties';
import { generateSMSCode, validateSMSCode } from '../../../networking/Networking';
import { getContactByID } from '../../../networking/NetworkingContacts';
import { notFilledSelectClass, regularSelectClass } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import SMSValidationBox from '../../shared/SMSValidationBox';
import DynamicSelect from '../../shared/DynamicSelect';
import EntityPropertiesContainer from '../../shared/EntityPropertiesContainer';
import LoadingBox from '../../shared/LoadingBox';
import NewDynamicTable from '../../shared/NewDynamicTable';
import NumberInput from '../../shared/NumberInput';
import WizardDealSummary from './WizardDealSummary';
import WizardIncomingPaymentPopup from './WizardIncomingPaymentPopup';

export default class WizardSettlementForm extends React.Component {
    state = {
        accountList: [],
        bankCustomers: [],
        beneficiaryList: [],
        buyAmount: 0,
        buyCurrency: '',
        coverCustomerID: 0,
        customerRate: 0,
        customerRateEnableUpdatesTrigger: true,
        customerRefNo: '',
        dealType: 0,
        deliveryMethods: {},
        depositID: 0,
        enableInstrumentUpdateTrigger: true,
        enableBankAccountUpdateTrigger: true,
        enablePayorUpdateTrigger: true,
        entityProperties: [],
        externalComments: '',
        externalInterface: '',
        heldFundsBalance: 0,
        incomingFundsID: 0,
        instrumentList: [],
        internalComments: '',
        listNostroAccounts: [],
        loading: false,
        loadingNostroAccounts: false,
        lockedSide: 'Sell',
        marketRate: 0,
        marketRateEnableUpdatesTrigger: true,
        profitAmount: 0,
        rateQuoteID: '',
        rateTerms: '',
        selectedAccountDescription: '',
        selectedAccountID: 0,
        selectedBeneficiaryID: 0,
        selectedBeneficiaryName: '',
        selectedInstrument: '',
        selectedNostroAccount: 0,
        selectedNostroDescription: '',
        sellAmount: 0,
        sellCurrency: '',
        showBalanceAlert: false,
        showPendingTasksAlert: false,
        spreadPercentage: 0,
        spreadPoints: 0,
        thirdPartyBeneficiaryPayment: false,
        totalFees: 0,
        useInterface: false,
        valueDate: undefined,
        showPopupPayments: false,
        allowAdditionalSettlements: false,
        forwardPointsScale: localStorage.getItem(GlobalSystemParametersNames.ForwardPointsScale) || 8,
        pendingBalance:0,
        converaQuoteBatch: false,
        forwardApplyDeposit: false,
        
        //SMS Validation
        showValidationSMSCode: false,
        titleValidationSMSCode: '',
        messageValidationSMSCode: '',
        useSMSValidation: false,
        phoneValidated: true,
        confirmedByClient: false,
        contactMobile: '',

        //Global alert
        showAlert: false,
        alertTitle: '',
        alertMessage: ''
    }

    componentDidMount = async () => {

        this.setState({loadingNostroAccounts: true});
        let objDealHeader = this.props.objDealHeader;        

        var smsValidation = false;
        let phoneNoValidated = true;
        getEntityProperties(this.props.objCustomer["Customer ID"], 'Customer').then(response => {
            let property = response.entityProperties.find(x => x.Description === 'Ask SMS Confirmation');
            if(property !== undefined && property !== null) {
                if(property.TextValue === 'Yes') { smsValidation = true; phoneNoValidated = false; }
                this.setState({ useSMSValidation: smsValidation, phoneValidated: phoneNoValidated });
            }
        })
        /*if(this.props.objContact['Mobile Phone'] !== undefined && this.props.objContact['Mobile Phone'] !== null && this.props.objContact['Mobile Phone'] !== '') {
            this.setState({ contactMobile: this.props.objContact['Mobile Phone'] });
        }*/
        getContactByID(this.props.objContact["Contact ID"]).then(
            (value) => {
                this.setState({ contactMobile: value['Mobile Number'] });
            }
        );

        //Load customer bank accounts
        getBankAccountsByCurrency(this.props.objCustomer["Customer ID"],
            objDealHeader.BuyCurrency).then(
            (json) => {
                var helper = [];
                if(json.accounts !== undefined){
                    for(var i=0; i<json.accounts.length; i++){
                        json.accounts[i].value = json.accounts[i].BankAccountID;
                        json.accounts[i].name = json.accounts[i].Description;
                        helper.push(json.accounts[i]);
                    }
                }
                this.setState({accountList: helper});
            }
        );

        //Load nostro accounts
        var nostroAccounts = [];
        await getNostroBankAccountsForFunds(objDealHeader.BuyCurrency, objDealHeader.CustomerID, true).then(
            async (response) => {
                if (response != null && response.nostroBankAccounts != null) {
                    //nostroAccounts = response.nostroBankAccounts.map(ba => ({ ...ba, value: ba.BankAccountID, name: 'Nostro - ' + ba.BankName }));
                    nostroAccounts = response.nostroBankAccounts.map(ba => ({ ...ba, value: ba.BankAccountID, name: ba.BankName }));
                    await getVirtualBankAccountsByCurrencyID(objDealHeader.CustomerID, objDealHeader.BuyCurrency).then(
                        (json) => {
                            if (json != null && json.virtualBankAccounts != null) {
                                if (json.virtualBankAccounts.length > 0) {
                                    let virtualBankAccounts = json.virtualBankAccounts.map(ba => ({ ...ba, value: ba.BankAccountID, name: ba.BankName }));
                                    nostroAccounts = [...nostroAccounts, ...virtualBankAccounts];
                                }
                            }
                        }
                    )
                }
                this.setState({ loadingNostroAccounts: false, listNostroAccounts: nostroAccounts });
            }
        );

        //Load held fund balance
        this.loadFundOnHold();

        //Set values for state
        var 
            buyAmount = Number(objDealHeader.BuyAmount), 
            buyCurrency = objDealHeader.BuyCurrency,
            coverCustomerID = objDealHeader.CoverCustomerID,
            customerRate = Number(objDealHeader.CustomerRate),
            dealType = objDealHeader.DealType,
            externalInterface = objDealHeader.ExternalInterface,
            lockedSide = objDealHeader.LockedSide,
            nostroAccountID = objDealHeader.NostroAccountID,
            profitAmount = Number(objDealHeader.ProfitAmount),
            rateQuoteID = objDealHeader.RateQuoteID,
            rateTerms = objDealHeader.RateTerms,
            sellAmount = Number(objDealHeader.SellAmount),
            sellCurrency = objDealHeader.SellCurrency,
            totalFees = objDealHeader.TotalFees,
            useInterface = objDealHeader.UseInterface;

        //Validate if disbursement amount is different than sell amount
        var totalDisbursementAmount = 0;
        let pendingBalance = 0;
        if (this.props.disbursements.length > 0) {
            this.props.disbursements.forEach(element => {
                totalDisbursementAmount += Number(element.PaymentAmount);
            });
            pendingBalance = Number(objDealHeader.SellAmount) - totalDisbursementAmount;
        } else {
            totalDisbursementAmount = Number(objDealHeader.SellAmount);
        }

        if (Number(objDealHeader.SellAmount) !== totalDisbursementAmount &&
        totalDisbursementAmount > Number(objDealHeader.SellAmount) &&
            objDealHeader.DealType === 1) {
            lockedSide = 'Sell';
            sellAmount = totalDisbursementAmount;
            buyAmount = this.calculateBuyAmount(sellAmount, customerRate, rateTerms);

            //Calculate profit
            getProfit(rateQuoteID,
                dealType, 
                sellCurrency,
                buyCurrency,
                sellAmount,
                buyAmount,
                formatDate(objDealHeader.ValueDate),
                totalFees,
                customerRate,
                objDealHeader.MarketRate).then(
                    (json) => {
                        profitAmount = json.Profit;
                        this.setState({profitAmount: profitAmount,})
                    }
                );
        }

        //Set current state
        var deliveryMethods = {};
        let instrumentList = this.props.incomingInstrumentList;
        for(var i=0; i<instrumentList.length; i++){
            var list = [];
            if(instrumentList[i].deliveryMethods !== ''){
                var methodsList = instrumentList[i].deliveryMethods.split('§');
                for(var j=0; j<methodsList.length; j++){
                    var item = {value:methodsList[j], name:methodsList[j]};
                    list.push(item);
                }
            }
            deliveryMethods[instrumentList[i].value] = list;
        }

        var customerRefNo = objDealHeader.CustomerRefNo;
        if(customerRefNo === undefined){
            customerRefNo = '';
        }

        //Load incoming fund values
        var incomingFundsID = 0, selectedAccountID = 0, selectedBeneficiaryID = 0, selectedIncomingNostroBankId = 0;
        var selectedInstrument = '', selectedDeliveryMethod = '';
        var thirdPartyBeneficiaryPayment = false;

        if (objDealHeader.IncomingFunds !== undefined &&
            objDealHeader.IncomingFunds !== null &&
            objDealHeader.IncomingFunds.length > 0) {
            //alert(JSON.stringify(this.props.objDealHeader.IncomingFunds));
            let objIncoming = objDealHeader.IncomingFunds[0];
            incomingFundsID = objIncoming.IncomingFundsID;
            selectedInstrument = objIncoming.SettlementInstrument;
            selectedDeliveryMethod = objIncoming.SettlementDeliveryMethod;
            if (objIncoming.BankAccountID !== null) {
                selectedAccountID = objIncoming.BankAccountID;
            }
            if (objIncoming.BeneficiaryID !== null) {
                selectedBeneficiaryID = objIncoming.BeneficiaryID;
            }
            thirdPartyBeneficiaryPayment = objIncoming.ThirdPartyBeneficiaryPayment;
            nostroAccountID = objIncoming.NostroAccount;
            //update incoming funds in the Wizard Screen
            this.props.updateIncomingFunds(objDealHeader.IncomingFunds.filter(x => x.Status !== 'Void'))
        }

        //Set deposit info values
        var depositID = 0;
        if(objDealHeader.Deposits !== null &&
            objDealHeader.Deposits.length > 0){
            let objDeposit = objDealHeader.Deposits[0];
            
            selectedInstrument = objDeposit.SettlementInstrument;
            selectedAccountID = objDeposit.CustomerBankAccount;
            selectedBeneficiaryID = objDeposit.BeneficiaryID;
            thirdPartyBeneficiaryPayment = objDeposit.ThirdPartyBeneficiaryPayment;
            depositID = objDeposit.DepositID;
            selectedDeliveryMethod = objDeposit.DeliveryMethod;
        }

        //Set customer defaults for instrument and delivery
        if(selectedInstrument === ''){
            selectedInstrument = this.props.objCustomer['DefaultSettlementInstrument$'];
        }

        if(selectedDeliveryMethod === ''){
            selectedDeliveryMethod = this.props.objCustomer['DefaultDeliveryMethod$'];
        }

        var selectedNostroDescription = '';
        if(Number(nostroAccountID) > 0){
            var objNostroAccount = nostroAccounts.find((account) => { return Number(account.BankAccountID) === Number(nostroAccountID); });
            if(objNostroAccount !== undefined){
                selectedNostroDescription = objNostroAccount.BankName;
            }
        }
        else
        {

            if(selectedIncomingNostroBankId === 0)
            {
                nostroAccounts.forEach(banks => {
                    if(banks.BankID === this.props.objCustomer['DefaultIncomingNostroBankId$'])
                    {
                        selectedIncomingNostroBankId = banks.BankAccountID;
                        selectedNostroDescription = banks.BankName;
                    }
                });
            }
        }

        let allowAdditionalSettlements = localStorage.getItem(GlobalSystemParametersNames.TradeScreenAllowAdditionalSettlements) === 'True'
       
        //Set state
        this.setState({
            enableBankAccountUpdateTrigger: false,
            enableInstrumentUpdateTrigger: false,
            enablePayorUpdateTrigger: false,
            customerRateEnableUpdatesTrigger: false,
            marketRateEnableUpdatesTrigger: false,

            bankCustomers: this.props.bankCustomers,
            beneficiaryList: this.props.beneficiaryList,
            buyAmount: buyAmount,
            buyCurrency: buyCurrency,
            coverCustomerID: coverCustomerID,
            customerRate: customerRate,
            customerRefNo: customerRefNo,
            dealType: dealType,
            deliveryMethods: deliveryMethods,
            depositID: depositID,
            externalComments: objDealHeader.ExternalComments,
            externalInterface: externalInterface,
            incomingFundsID: incomingFundsID,
            internalComments: objDealHeader.InternalComments,
            instrumentList: instrumentList,
            lockedSide: lockedSide,
            marketRate: objDealHeader.MarketRate,
            profitAmount: profitAmount,
            rateQuoteID: rateQuoteID,
            rateTerms: rateTerms,
            selectedAccountID: (nostroAccountID === null || nostroAccountID === 0) ? selectedIncomingNostroBankId : nostroAccountID,
            selectedBeneficiaryID: selectedBeneficiaryID,
            selectedDeliveryMethod: selectedDeliveryMethod,
            selectedInstrument: selectedInstrument,
            selectedNostroAccount: objDealHeader !== undefined && objDealHeader.NostroAccountID > 0 ? objDealHeader.NostroAccountID : 0,
            selectedNostroDescription: selectedNostroDescription,
            sellAmount: sellAmount,
            sellCurrency: sellCurrency,
            spreadPercentage: objDealHeader.SpreadPercentage,
            spreadPoints: objDealHeader.SpreadPoints,
            thirdPartyBeneficiaryPayment: thirdPartyBeneficiaryPayment,
            totalFees: totalFees,
            useInterface: useInterface,
            valueDate: objDealHeader.ValueDate,
            allowAdditionalSettlements: allowAdditionalSettlements,
            pendingBalance: pendingBalance
        }, () => {
            this.setState({enableInstrumentUpdateTrigger: true,
                enableBankAccountUpdateTrigger: true,
                enablePayorUpdateTrigger: true,
                customerRateEnableUpdatesTrigger: true,
                marketRateEnableUpdatesTrigger: true,
            })
        });

        let converaBatchModelCurrencies = localStorage.getItem(GlobalSystemParametersNames.ConveraBatchModelCurrencies);
        let converaBatchModelCurrenciesArray = []
        
        if (converaBatchModelCurrencies !== undefined & converaBatchModelCurrencies !== null && Array.isArray(converaBatchModelCurrencies.split(','))) {
            converaBatchModelCurrenciesArray = converaBatchModelCurrencies.split(',');
        }

        if (this.props.disbursements !== undefined && this.props.disbursements.length > 0
            && objDealHeader.ExternalInterface !== undefined && objDealHeader.ExternalInterface.includes('Convera')
            && converaBatchModelCurrenciesArray.includes(objDealHeader.SellCurrency)
        ) {            
            this.GetConveraBatchQuote(objDealHeader);
        }       
    }

    GetConveraBatchQuote = (objDealHeader) => {
        let objDealHeaderClone = { ...objDealHeader };
        objDealHeaderClone.OutgoingFunds = this.props.disbursements;
        GetRateForMultiplePaymentsOnPartner(objDealHeader.ExternalInterface, objDealHeader.ExternalInterfaceType, objDealHeaderClone,
            objDealHeader.UpdateBuyAmountField ? objDealHeader.SellAmount : objDealHeader.BuyAmount).then(jsonBody => {
                console.log('response', jsonBody);

                let rate = jsonBody.response;
                if (rate !== undefined) {
                    let customerRate = rate.CustomerRate;
                    let marketRate = rate.MarketRate;
                    let profitAmount = rate.Profit;
                    let converaBatchId = rate.BatchId;

                    let buyAmount = this.calculateBuyAmount(this.state.sellAmount, customerRate, this.state.rateTerms);
                    let profitPoints = Math.abs(customerRate - marketRate);
                    let spreadPoints = (profitPoints * 10000).toFixed(2);
                    var spreadPercentage = 0;
                    if (Number(marketRate) !== 0) {
                        spreadPercentage = ((profitPoints / marketRate) * 100).toFixed(2);
                    }

                    this.setState({
                        buyAmount: buyAmount,
                        customerRate: customerRate,
                        loading: false,
                        marketRate: marketRate,
                        profitAmount: profitAmount,
                        spreadPercentage: spreadPercentage,
                        spreadPoints: spreadPoints,
                        converaQuoteBatch: true,
                        converaBatchId: converaBatchId,
                        coverCustomerID: objDealHeaderClone.CoverCustomerID.toString(),
                        externalInterface: objDealHeaderClone.ExternalInterface
                    });
                }
            }
            );
    }

    loadFundOnHold(){
        getFundOnHoldByCustomerAndCurrency(this.props.objCustomer["Customer ID"],
            this.props.objDealHeader.BuyCurrency).then(
            (json) => {
                var balance = 0;
                if(json.balances !== undefined){
                    balance = json.balances[0].Amount;
                }
                this.setState({heldFundsBalance: balance});
            }
        );
    }

    handleBackClick = (event) => {
        this.props.onBackClicked();
    }

    handleSaveClick = (status, confirmedByClient) => {
        if(this.state.useSMSValidation && !this.state.phoneValidated) {
            //if(this.props.objContact['Mobile Phone'] !== undefined && this.props.objContact['Mobile Phone'] !== null && this.props.objContact['Mobile Phone'] !== '') {
            if(this.state.contactMobile !== undefined && this.state.contactMobile !== null && this.state.contactMobile !== '') {    
                let validationMessage = '';
                validationMessage = this.props.objContact['Name'] + ' - ****' + this.state.contactMobile.substring(this.state.contactMobile.length - 5);
                this.setState({ showValidationSMSCode: true, titleValidationSMSCode: 'Validation Code', messageValidationSMSCode: validationMessage, confirmedByClient: confirmedByClient });
            } else {
                this.showAlert('Warning', 'Selected customer contact does not have a mobile number registered.');
            }
            
        } 
        else {
            if (this.validateFields()) {
                if (Number(this.props.objDealHeader.DealHeaderID) === 0
                    && status === 'Accepted'
                    && this.props.objCustomer['HasPendingTasks$']) {
                    this.setState({ showPendingTasksAlert: true });
                }
                else {
                    if (status === 'Accepted' && this.props.objDealHeader.LastDrawDown && this.props.objDealHeader.DepositsBalance > 0) {
                        let useDepositBalanceAlertMessage = this.props.objDealHeader !== undefined && this.props.objDealHeader.DepositsBalanceInfo != undefined && this.props.objDealHeader.DepositsBalanceInfo != '' ?
                            "Do you want to apply the available balance for this drawdown settlement? (" + this.props.objDealHeader.DepositsBalanceInfo + ")" :
                            "There are available deposits for the forward, do you want to apply the available balance for this drawdown settlement?";
                        this.setState({ showUseDepositBalanceAlert: true, confirmedByClient: confirmedByClient, useDepositBalanceAlertMessage: useDepositBalanceAlertMessage })
                    }
                    else {
                        this.saveDealHeader(status, confirmedByClient);
                    }
                }
            }
        }
    }

    saveDealHeader(status, confirmedByClient){

        let incomingFunds = [];
        let selectedNostroAccount = this.state.selectedNostroAccount;
        
        if(this.props.incomingFunds && this.state.allowAdditionalSettlements)
        {

            incomingFunds = this.props.incomingFunds;
            if(incomingFunds.length === 1)
            {
                selectedNostroAccount = incomingFunds[0].NostroAccount;
            }
            else
            {
                if(incomingFunds.length > 1)
            {
                selectedNostroAccount = null;
            }
            }
        }

        var objDealHeader = {
            BankAccountDescription: this.state.selectedAccountDescription,
            BankAccountID: this.state.selectedAccountID,
            BeneficiaryID: this.state.selectedBeneficiaryID,
            BeneficiaryName: this.state.selectedBeneficiaryName,
            BuyAmount: this.state.buyAmount,
            ConfirmedByClient: confirmedByClient,
            CustomerRate: this.state.customerRate,
            CustomerRefNo: this.state.customerRefNo,
            DepositID: this.state.depositID,
            EntityProperties: this.state.entityProperties,
            EntityPropertiesJson: JSON.stringify(this.state.entityProperties),
            ExternalComments: this.state.externalComments,
            IncomingFundsID: this.state.incomingFundsID,
            InternalComments: this.state.internalComments,
            MarketRate: this.state.marketRate,
            NostroAccountID: selectedNostroAccount,
            ProfitAmount: this.state.profitAmount,
            RateQuoteID: this.state.rateQuoteID,
            SellAmount: this.state.sellAmount,
            SettlementInstrument: this.state.selectedInstrument,
            SettlementDeliveryMethod: this.state.selectedDeliveryMethod,
            Status: status,
            ThirdPartyBeneficiaryPayment: this.state.thirdPartyBeneficiaryPayment,
            ExternalInterface: this.props.objDealHeader.ExternalInterface,
            ExternalInterfaceType: this.props.objDealHeader.ExternalInterfaceType,
            ConveraBatchId: this.state.converaQuoteBatch ? this.state.converaBatchId : '',
            ForwardApplyDeposit: this.state.forwardApplyDeposit
        };
        this.props.onSaveClicked(objDealHeader);
        if(this.state.selectedInstrument === 'Client\'s Vostro Account'){
            this.loadFundOnHold();
        }
    }

    validateFields(){        
        var totalOwnerByCustomer = Number(this.props.objDealHeader.TotalFees) + Number(this.state.buyAmount);
        var heldFundsBalance = this.state.heldFundsBalance
        if(this.state.selectedInstrument === 'Client\'s Vostro Account' &&
            totalOwnerByCustomer > heldFundsBalance){
            this.setState({showBalanceAlert:true});
            return false;
        }
        
        if(this.state.selectedInstrument === 'Direct Debit' &&
            (this.state.selectedDeliveryMethod === undefined ||
            this.state.selectedDeliveryMethod === '' ||
            this.state.selectedDeliveryMethod === 'select')){
            return false;
        }
        
        let objDealHeader = this.props.objDealHeader;   
        let validatideInstrumentAndNostro = Number(objDealHeader.DealType) !== 4 || (objDealHeader.DepositAmount > 0)
        || (Number(objDealHeader.DealType === 4 && objDealHeader.ForwardType === 'Closed'))
        
        if(validatideInstrumentAndNostro)
        {
            if (this.state.selectedInstrument === undefined ||
                this.state.selectedInstrument === 'select' || this.state.selectedInstrument === '' ||
                (this.state.selectedInstrument !== undefined && this.state.selectedInstrument.includes('select'))) {
                return false;
            }
        }

        if(this.state.selectedInstrument === 'Direct Debit' &&
            (this.state.selectedAccountID === undefined ||
            this.state.selectedAccountID === 'select' ||
            this.state.selectedAccountID === 0)){
            return false;
        }

        if (this.state.thirdPartyBeneficiaryPayment &&
            (this.state.selectedBeneficiaryID === undefined ||
                this.state.selectedBeneficiaryID === 'select' ||
                this.state.selectedBeneficiaryID === 0)) {
            return false;
        }

        if (!this.state.allowAdditionalSettlements && Number(this.state.selectedNostroAccount) === 0) {
            /*let objDealHeader = this.props.objDealHeader;            
            let validatideNostroAccount = Number(objDealHeader.DealType) !== 4 || (objDealHeader.DepositAmount > 0)
                || (Number(objDealHeader.DealType === 4 && objDealHeader.ForwardType === 'Closed'))
            if (validatideNostroAccount) {*/
            if (validatideInstrumentAndNostro) {
                return false;
            }
        }

        return true;

    } 
    
    calculateBuyAmount(sellAmount, customerRate, rateTerms){
        //alert(sellAmount + '-' + customerRate + '-' + rateTerms);
        var buyAmount = 0;
        if(rateTerms === 'D'){
            buyAmount = sellAmount * customerRate;
        }
        else{
            if(customerRate !== 0){
                buyAmount = sellAmount / customerRate;
            }
            else{
                buyAmount = 0;
            }
        }
        buyAmount = roundAmountByCurrency(buyAmount, this.state.buyCurrency);
        return buyAmount;
    }

    handleRefreshClick = (event) => {
        this.setState({loading: true});
        //Get external interface
        var externalInterface = '', externalInterfaceType = '';
        let coverCustomerID = this.state.coverCustomerID;
        if(this.state.useInterface){
            
            let bank = this.state.bankCustomers.find(function(element) {
                return Number(element.CustomerID) === Number(coverCustomerID);
            });
            
            if(bank !== undefined){
                externalInterface = bank.BankInterface;
                externalInterfaceType = bank.InterfaceType;
            }
        }

        getRate(this.props.objCustomer["Customer ID"],
                    this.props.objContact["Contact ID"],
                    this.state.dealType, 
                    formatDate(this.state.valueDate), 
                    null,//forwardNearDate
                    this.state.buyCurrency, 
                    this.state.sellCurrency, 
                    this.state.sellAmount, 
                    this.state.lockedSide,
                    this.state.totalFees, 
                    false,
                    null,
                    null, 
                    externalInterface,
                    externalInterfaceType).then(
                        (json) => {
                            let rateQuoteID = json.RateQuoteID;
                            let customerRate = json.CustomerRate;
                            let marketRate = json.MarketRate;
                            let profitAmount = json.Profit;
                            let sellAmount = this.state.sellAmount;
                            
                            //Calculate buy amount
                            var buyAmount = this.calculateBuyAmount(sellAmount, customerRate, this.state.rateTerms);

                            //Spread points and percentage
                            let profitPoints = Math.abs(customerRate - marketRate);
                            let spreadPoints = (profitPoints * 10000).toFixed(2);
                            var spreadPercentage = 0;
                            if(Number(marketRate) !== 0){
                                spreadPercentage = ((profitPoints / marketRate) * 100).toFixed(2);
                            }

                            this.setState({
                                buyAmount: buyAmount,
                                customerRate: customerRate,
                                loading: false,
                                marketRate: marketRate,
                                profitAmount: profitAmount,
                                rateQuoteID: rateQuoteID,
                                sellAmount: sellAmount,
                                spreadPercentage: spreadPercentage,
                                spreadPoints: spreadPoints,
                            });
                        }
                    );
    }

    handleUpdateInstrument = (obj) => {
        if(this.state.enableInstrumentUpdateTrigger){
            //alert('handleUpdateInstrument');
            let selectedInstrument = obj.value;
            var thirdPartyBeneficiaryPayment = this.state.thirdPartyBeneficiaryPayment;
            var selectedAccountID = this.state.selectedAccountID;
            var selectedBeneficiaryID = this.state.selectedBeneficiaryID;
            if(selectedInstrument !== 'Direct Debit'){
                selectedAccountID = 0;
                if(selectedInstrument !== 'Electronic Funds Transfer'){
                    thirdPartyBeneficiaryPayment = false;
                    selectedBeneficiaryID = 0;
                }
            }
            if(selectedInstrument === "Client's Vostro Account"){
                this.setState({selectedInstrument: selectedInstrument,
                    thirdPartyBeneficiaryPayment: thirdPartyBeneficiaryPayment,
                    selectedAccountID: selectedAccountID,
                    selectedBeneficiaryID: selectedBeneficiaryID,
                    selectedDeliveryMethod: '',
                });
            }else{
                this.setState({selectedInstrument: selectedInstrument,
                    thirdPartyBeneficiaryPayment: thirdPartyBeneficiaryPayment,
                    selectedAccountID: selectedAccountID,
                    selectedBeneficiaryID: selectedBeneficiaryID,
                    selectedDeliveryMethod: '',
                });
            }
        }
    }

    handleUpdateDeliveryMethod = (obj) => {
        if(obj !== undefined && obj.value !== undefined){
            this.setState({selectedDeliveryMethod: obj.value});
        }
    }

    handleUpdateNostroAccount = (obj) => {
        //alert(JSON.stringify(obj));
        if(obj !== undefined && obj.value !== undefined){
            this.setState({selectedNostroAccount: obj.value,
                selectedNostroDescription: obj.name,
            });
        }
    }

    handleUpdateAccount = (obj) => {
        if(this.state.enableBankAccountUpdateTrigger){
            if(obj.value !== undefined && obj.Description !== undefined){
                this.setState({selectedAccountID: obj.value,
                    selectedAccountDescription: obj.Description,
                });
            }
        }
    }

    handleUpdateThirdPartyBeneficiaryPayment = (control) => {
        let thirdPartyBeneficiaryPayment = control.target.checked;
        let selectedBeneficiaryID = this.state.selectedBeneficiaryID;
        if(!thirdPartyBeneficiaryPayment){
            selectedBeneficiaryID = 0;            
        }
        this.setState({thirdPartyBeneficiaryPayment: thirdPartyBeneficiaryPayment,
            selectedBeneficiaryID: selectedBeneficiaryID,
        });        
    }

    handleUpdateBeneficiaryID = (obj) => {
        if(this.state.enablePayorUpdateTrigger){
            //alert('handleUpdateBeneficiaryID');
            if(obj.value !== undefined && obj.Description !== undefined){
                this.setState({selectedBeneficiaryID: obj.value,
                    selectedBeneficiaryName: obj.Description,
                });
            }
        }
    }

    handleUpdateCustomerRefNo = (event) => {
        this.setState({customerRefNo: event.target.value})
    }

    handleUpdateInternalComments = (event) => {
        this.setState({internalComments: event.target.value});
    }

    handleUpdateExternalComments = (event) => {
        this.setState({externalComments: event.target.value});
    }

    calculateAmounts(customerRate, marketRate, sellAmount){
        //alert('calculateAmounts:' + customerRate);
        var amounts = {};
        //Spread points and percentage
        let profitPoints = Math.abs(customerRate - marketRate);
        let spreadPoints = (profitPoints * 10000).toFixed(2);
        var spreadPercentage = 0;
        if(Number(marketRate) !== 0){
            spreadPercentage = ((profitPoints / marketRate) * 100).toFixed(2);
        }

        //Calculate buy amount
        let buyAmount = this.calculateBuyAmount(sellAmount, customerRate, this.state.rateTerms);

        //Calculate profit
        getProfit(this.state.rateQuoteID,
            this.state.dealType, 
            this.state.sellCurrency,
            this.state.buyCurrency,
            sellAmount,
            buyAmount,
            formatDate(this.state.valueDate),
            this.state.totalFees,
            customerRate,
            marketRate).then(
                (json) => {
                    let profitAmount = json.Profit;
                    this.setState({profitAmount: profitAmount,})
                }
            );
        
        amounts.BuyAmount = buyAmount;
        amounts.SpreadPercentage = spreadPercentage;
        amounts.SpreadPoints = spreadPoints;
        return amounts;
    }

    handleUpdateMarketRate = (event) => {
        if(this.state.marketRateEnableUpdatesTrigger &&
            this.state.marketRate !== Number(event.target.value)){
            //alert('handleUpdateMarketRate' + event.target.value);
            let marketRate = event.target.value;
            let amounts = this.calculateAmounts(this.state.customerRate, marketRate, this.state.sellAmount)
            this.setState({buyAmount: amounts.BuyAmount,
                marketRate: marketRate,
                spreadPercentage: amounts.SpreadPercentage,
                spreadPoints: amounts.SpreadPoints,
            });
        }
    }

    handleUpdateCustomerRate = (event) => {
        if(this.state.customerRateEnableUpdatesTrigger &&
            this.state.customerRate !== Number(event.target.value)){
            //alert('handleUpdateCustomerRate' + event.target.value);
            let customerRate = event.target.value;
            let amounts = this.calculateAmounts(customerRate, this.state.marketRate, this.state.sellAmount);
            this.setState({buyAmount: amounts.BuyAmount,
                customerRate: customerRate,
                spreadPercentage: amounts.SpreadPercentage,
                spreadPoints: amounts.SpreadPoints,
            });
        }
    }

    handleUpdateCoverCustomer = (obj) => {
        if(obj!== undefined 
            && obj.value !== undefined){
            //alert(JSON.stringify(obj));
            let coverCustomerID = Number(obj.value);
            let objCoverBank = this.state.bankCustomers.find(function(element){
                return Number(element.CustomerID) === coverCustomerID;
            });
            var externalInterface = '';
            var useInterface = false;
            if(objCoverBank !== undefined){
                externalInterface = objCoverBank.BankInterface;
                if(objCoverBank.BankInterface !== ''){
                    useInterface = this.state.useInterface;
                }
            } 
            this.setState({coverCustomerID: coverCustomerID,
                externalInterface: externalInterface,
                useInterface: useInterface,
            });
        }
    }

    handleUpdateUseInterface = (event) => {
        this.setState({useInterface: event.target.checked});
    }

    okBalanceAlert = () => {
        this.setState({showBalanceAlert: false});
    }

    closePendingTasksAlert = () => {
        this.setState({showPendingTasksAlert: false});
    }

    yesPendingTasksAlert = () => {
        this.saveDealHeader('Pending', false);
        this.setState({showPendingTasksAlert: false});
    }

    onRefreshProperties = (entityProperties) => {
        this.setState({entityProperties: entityProperties});
    }

    onShowPopupPayments = () => {
        this.setState({ showPopupPayments: true })
    }

    onHidePopupPayments = () => {
        this.setState({ showPopupPayments: false })
    }

    closeValidationSMSCode = () => {
        this.setState({ showValidationSMSCode: false, messageValidationSMSCode: '', titleValidationSMSCode: '' });
    }

    sendSMSCode = async () => {
        //Code to send sms.
        let mobileNumber = this.state.contactMobile;//this.props.objContact['Mobile Phone'];
        let companyName = this.props.objCustomer['Customer Name'];
        const model = {
            numberMobile: mobileNumber,
            token: '',
            companyName: companyName != null ? companyName : ''
        };
        const json = await generateSMSCode(model)
        if (json.httpStatusCode !== 200) {
            this.showAlert('Error', json.Message);
        } else {
            this.showAlert('Success', 'New code sent.');
        }
    }

    validatePhone = async (smsCode) => {
        if(smsCode!== undefined && smsCode!== null && smsCode !== '') {
            const model = {
                numberMobile: this.state.contactMobile,//this.props.objContact['Mobile Phone'],
                token: smsCode
            };
            const json = await validateSMSCode(model);
            if (json.httpStatusCode !== 200) {
                this.showAlert('Error', json.Message);
            } else {
                if (json.validation) {
                    this.setState({ showValidationSMSCode: false, messageValidationSMSCode: '', titleValidationSMSCode: '', phoneValidated: true });
                    //this.handleSaveClick('Accepted', this.state.confirmedByClient);
                    this.handleSaveClick('Accepted', true);
                } else {
                    this.showAlert('Error', 'Invalid code.');
                }
            }
        }
    }

    showAlert(title, message){
        this.setState({alertTitle: title,
            alertMessage: message,
            showAlert: true,
        });
    }

    okGlobalAlert = () => {
        this.setState({showAlert: false, alertTitle: '', alertMessage: ''});
    }

    onApplyPendingDeposits = (applyDeposit) => {
        this.setState({ showUseDepositBalanceAlert: false, forwardApplyDeposit: applyDeposit, useDepositBalanceAlertMessage: '' },
            () => { this.saveDealHeader('Accepted', this.state.confirmedByClient) }
        )
    }

    render() {
        let objDealHeader = this.props.objDealHeader;

        //Build disbursements section
        var totalDisbursementAmount = 0;
        var disbursementsSection;
        if(Number(objDealHeader.DealType) !== 4){
            var disbursements = [];
            for(var i=0;i<this.props.disbursements.length;i++){
                var name = this.props.disbursements[i].Name;
                switch(this.props.disbursements[i].BeneficiaryType){
                    case 1:
                        name = this.props.objCustomer["Customer Name"];
                        break;
                    case 2:
                        name = 'TBA';
                        break;
                    default:
                        break;
                }
                
                disbursements.push({"Name":name, 
                    "Amount":formatToCurrencyWithScale(this.props.disbursements[i].PaymentAmount, this.props.objDealHeader.SellCurrency), 
                    "Country":this.props.disbursements[i].CountryName,
                });

                totalDisbursementAmount += Number(this.props.disbursements[i].PaymentAmount);
            }
            disbursementsSection = (
                <div className="uk-width-2-2">
                    <div className="uk-width-2-2">
                    <label className="uk-form-label">Beneficiaries</label> 
                    {this.state.pendingBalance > 0 &&<label className='uk-form-label'>{` (Undisbursed balance: ${formatToCurrencyWithScale(this.state.pendingBalance, this.props.objDealHeader.SellCurrency)})`}</label>}
                    </div>
                    <NewDynamicTable 
                        data={disbursements}
                        enableClick={false}
                        useDeleteButton={false}/>
                </div>
            );
        }        

        //For validation classes
        var classNameDeliveryMethod = regularSelectClass;
        if(this.state.selectedInstrument !== undefined && this.state.selectedInstrument === 'Direct Debit'
        && (this.state.selectedDeliveryMethod === '' || this.state.selectedDeliveryMethod === 'select')){
            classNameDeliveryMethod = notFilledSelectClass;
        }

        var classNameInstrument = regularSelectClass;
        if(this.state.selectedInstrument === undefined ||
            this.state.selectedInstrument === 'select' || this.state.selectedInstrument === '' ||
            (this.state.selectedInstrument !== undefined && this.state.selectedInstrument.includes('select'))
            ){
            classNameInstrument = notFilledSelectClass;
        }

        var classNameNostroAccount = regularSelectClass;
        if(this.state.selectedNostroAccount === undefined ||
            (Number(this.state.selectedNostroAccount) === 0) && !this.state.allowAdditionalSettlements){
                classNameNostroAccount = notFilledSelectClass;
        }

        var classNameBankAccount = regularSelectClass;
        if(this.state.selectedAccountID === undefined ||
            this.state.selectedAccountID === 'select' ||
            this.state.selectedAccountID === 0){
            classNameBankAccount = notFilledSelectClass;
        }

        var classNameBeneficiary = regularSelectClass;
        if(this.state.selectedBeneficiaryID === undefined ||
            this.state.selectedBeneficiaryID === 'select' ||
            this.state.selectedBeneficiaryID === 0){
            classNameBeneficiary = notFilledSelectClass;
        }

        //Instrument and delivery controls
        var instrumentControl;
        var deliveryMethodControl;
        var nostroAccountControl;
        if(Number(objDealHeader.DealType) !== 4 ||
            (objDealHeader.DepositAmount > 0) || 
            (Number(objDealHeader.DealType === 4 && objDealHeader.ForwardType === 'Closed'))){
                instrumentControl = (
                    <div>
                        <label className="uk-form-label">Settlement instrument</label>
                        <div>
                            <DynamicSelect
                                className={classNameInstrument}
                                listHasPlaceholder={false}
                                placeholder="Select an instrument..."
                                data={this.state.instrumentList}
                                objValue={{value:this.state.selectedInstrument}}
                                getValue={this.handleUpdateInstrument}/>
                        </div>
                    </div>
                );

                var deliveryMethods = this.state.deliveryMethods[this.state.selectedInstrument];
                if(deliveryMethods === undefined || this.state.selectedInstrument === "Client's Vostro Account"){
                    deliveryMethods = [];
                }

                deliveryMethodControl = (
                    <div>
                        <label className="uk-form-label">Delivery Method</label>
                        <div>
                            <DynamicSelect id="select-delivery-method"
                                className={classNameDeliveryMethod}
                                listHasPlaceholder={false}
                                placeholder="Select a delivery method..."
                                placeholderValue=""
                                data={deliveryMethods}
                                objValue={{value:this.state.selectedDeliveryMethod}}
                                getValue={this.handleUpdateDeliveryMethod}
                            />
                        </div>
                    </div>
                );

                //Nostro account control
                nostroAccountControl = (
                    <div>
                        <label className="uk-form-label">Nostro account</label>
                        <div>
                            <DynamicSelect
                                className={classNameNostroAccount}
                                listHasPlaceholder={false}
                                placeholder="Select an account..."
                                placeholderValue={0}
                                data={this.state.listNostroAccounts}
                                objValue={{value:this.state.selectedNostroAccount}}
                                getValue={this.handleUpdateNostroAccount}
                                disabled={this.state.allowAdditionalSettlements}/>
                        </div>
                    </div>
                );
        }

        //Third party payment control
        var thirdPartyPaymentControl;
        if(this.state.selectedInstrument === 'Electronic Funds Transfer'){
            thirdPartyPaymentControl = (
            <div uk-grid="true">
                <div className="uk-width-1-2" style={{paddingTop:5}}>
                    {/*<label className="uk-form-label">Third-party beneficiary payment?</label>*/}
                    <label 
                        className="uk-form-label" 
                        htmlFor="form-beneficiary-bypassValidation"
                    >
                        <input 
                            className="uk-checkbox" 
                            type="checkbox" 
                            checked={this.state.thirdPartyBeneficiaryPayment} 
                            onChange={this.handleUpdateThirdPartyBeneficiaryPayment}
                        /> Third-party beneficiary payment?
                    </label>
                                        
                </div>
                {/*<div className="uk-width-1-2">
                    <input className="uk-checkbox" type="checkbox" checked={this.state.thirdPartyBeneficiaryPayment} onChange={this.handleUpdateThirdPartyBeneficiaryPayment}/>
                </div>*/}
            </div>);
        }

        //Customer bank account control
        var customerBankAccountControl;
        if(this.state.selectedInstrument === 'Direct Debit'){
            customerBankAccountControl = (
                <div>
                    <label className="uk-form-label">Bank Account</label>
                    <div>
                        <DynamicSelect
                            className={classNameBankAccount}
                            placeholder="Select an Account..."
                            listHasPlaceholder={false}
                            data={this.state.accountList}
                            objValue={{value:this.state.selectedAccountID}}
                            getValue={this.handleUpdateAccount}/>
                    </div>
                </div>
            );
        }

        //Third party payor control
        var thirdPartyPayorControl;
        if(this.state.thirdPartyBeneficiaryPayment){
            thirdPartyPayorControl = (
                <div>
                    <label className="uk-form-label">Third-party payor</label>
                    <div>
                        <DynamicSelect
                            className={classNameBeneficiary}
                            placeholder="Select a Beneficiary..."
                            listHasPlaceholder={false}
                            data={this.state.beneficiaryList}
                            objValue={{value:this.state.selectedBeneficiaryID}}
                            getValue={this.handleUpdateBeneficiaryID}/>
                    </div>
                </div>
            );
        }

        //Total owned
        var totalOwnerByCustomer = roundAmountByCurrency(Number(this.props.objDealHeader.TotalFees) + Number(this.state.buyAmount), this.props.objDealHeader.BuyCurrency);

        //Fees section
        
        //Total sell side fees
        var sellSideFees = '';
        if(objDealHeader.SellSideFess){
            sellSideFees = formatToCurrencyWithScale(objDealHeader.SellSideFess, this.props.objDealHeader.SellCurrency) + ' ' + this.props.objDealHeader.SellCurrency;
        }

        var feeSection;
        if(Number(objDealHeader.DealType) !== 4){
            feeSection = (
                <tr>
                    <td><label className="uk-form-label">Total Fees</label></td>
                    <td  align="right">{formatToCurrencyWithScale(objDealHeader.TotalFees, objDealHeader.BuyCurrency) + ' ' + objDealHeader.BuyCurrency}</td>
                    <td  align="right">{sellSideFees}</td>
                </tr>
            );
        }

        //Deposit amount section
        var depositAmountSection;
        if(Number(objDealHeader.DealType) === 4 &&
            Number(objDealHeader.DepositAmount) > 0){
            depositAmountSection = (
                <tr>
                    <td><label className="uk-form-label">Deposit Amount</label></td>
                    <td align="right">{formatToCurrencyWithScale(objDealHeader.DepositAmount, objDealHeader.BuyCurrency) + ' ' + this.props.objDealHeader.BuyCurrency}</td>
                </tr>
            );
        }

        //Deal summary
        var dealSummary;
        var dealIDLabel;
        if (objDealHeader.DealHeaderID !== undefined &&
            objDealHeader.DealHeaderID !== 0) {
            dealIDLabel = (<div style={{ textAlign: "right", marginRight: '10px' }}><h3 className="" style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>Deal ID: {objDealHeader.DealHeaderID}</h3></div>);
            dealSummary = (//<div className="input-number" >
                <WizardDealSummary objDealHeader={objDealHeader} disbursements={this.props.disbursements} />);
        }

        var acceptButton, acceptAndConfirmButton, saveAsPendingButton;
        if(this.props.objCustomer.Status === 'Authorized to deal'){
            //Accept button
            acceptButton = (<button className="uk-button uk-button-green" onClick={() => this.handleSaveClick('Accepted', false)}>Accept</button>);
            
            //Accept and confirm button
            acceptAndConfirmButton = (<button className="uk-button uk-button-green" onClick={() => this.handleSaveClick('Accepted', true)}>Accept + Confirm</button>);
            
            //Save as pending button
            if(objDealHeader.DealHeaderID === 0 || 
                (objDealHeader.DealHeaderID !== 0 &&
                    objDealHeader.Status !== 'Accepted')){
                        saveAsPendingButton = (<button className="uk-button uk-button-green" onClick={() => this.handleSaveClick('Pending', false)}>Save as Pending</button>);
            }
        }        

        //Display rate info section
        var ratesInfoSection;
        
        let totalDisbursementAmountExceedsSellAmount = objDealHeader.SellAmount !== totalDisbursementAmount && totalDisbursementAmount > objDealHeader.SellAmount
        let showRateInfoSection = objDealHeader.DealHeaderID === 0
            && (totalDisbursementAmountExceedsSellAmount || this.state.converaQuoteBatch)
            && objDealHeader.DealType === 1

        if (showRateInfoSection) {
            //Disable use interface is bank does not have interface
            var useInterfaceDisabled = false;
            if (this.state.externalInterface === '' || this.state.converaQuoteBatch) {
                useInterfaceDisabled = true;
            }

            //Disable cover dropdown for bank customers
            var coverCustomerDisabled = false;
            if (this.props.objCustomer['Customer Type'] === 'Bank' || this.state.converaQuoteBatch) {
                coverCustomerDisabled = true;
            }

            var bankCustomersControls;
            bankCustomersControls = (
                <tr>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <td width="75%">
                                        <label className="uk-form-label" htmlFor="form-one-click-cover">One Click Cover</label>
                                        <DynamicSelect
                                            className="uk-select"
                                            placeholder="Select a Bank..."
                                            placeholderValue={0}
                                            data={this.state.bankCustomers}
                                            objValue={{value:this.state.coverCustomerID}}
                                            getValue={this.handleUpdateCoverCustomer}
                                            disabled={coverCustomerDisabled}/>
                                    </td>
                                    <td width="25%">
                                        <label className="uk-form-label" htmlFor="form-use-interface">Use interface?</label>
                                        <input className="uk-checkbox" type="checkbox" checked={this.state.useInterface} onChange={this.handleUpdateUseInterface} disabled={useInterfaceDisabled} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td></td>
                </tr>
            );

            var marketRateDisabled = false;
            if(this.state.useInterface){
                marketRateDisabled = true;
            }

            ratesInfoSection = (
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <label className="uk-form-label" htmlFor="form-market-rate">Market Rate</label>
                                <NumberInput className="uk-input" id="form-market-rate" type="Rate" scale={getScaleForCurrencyPair(objDealHeader.BuyCurrency, objDealHeader.SellCurrency)} value={this.state.marketRate} onChange={this.handleUpdateMarketRate} disabled={marketRateDisabled}/>
                            </td>
                            <td></td>
                        </tr>
                        {bankCustomersControls}
                        <tr>
                            <td>
                                <label className="uk-form-label" htmlFor="form-customer-rate">Customer Rate</label>
                                <NumberInput className="uk-input" id="form-customer-rate" type="Rate" scale={getScaleForCurrencyPair(objDealHeader.BuyCurrency, objDealHeader.SellCurrency)} value={this.state.customerRate} onChange={this.handleUpdateCustomerRate}/>
                            </td>
                            {this.state.pendingBalance < 0 &&
                            <td valign="bottom">
                                <i className="fa fa-fw fa-refresh" style={{ fontSize: '1.75em', verticalAlign: 'middle', cursor:'pointer'  }} onClick={this.handleRefreshClick}/>
                            </td>
            }
                        </tr>
                        <tr>
                            <td>
                                <table className="uk-table uk-table-divider border-table uk-margin-small-top">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <label className="uk-form-label" htmlFor="form-spread-points">Spread(points)</label>
                                                <div className="uk-form-controls">
                                                    {formatToCurrencyWithScale(this.state.spreadPoints, this.state.forwardPointsScale)}
                                                </div>
                                            </td>
                                            <td>
                                                <label className="uk-form-label" htmlFor="form-spread-percentage">Spread(%)</label>
                                                <div className="uk-form-controls">
                                                    {formatToCurrency(this.state.spreadPercentage)}
                                                </div>
                                            </td>
                                            <td>
                                                <label className="uk-form-label" htmlFor="form-profit">Profit amount</label>
                                                <div className="uk-form-controls">
                                                    {formatToCurrencyWithScale(this.state.profitAmount, objDealHeader.BuyCurrency)}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            );
        }

        //Locked side symbol
        var buyLocked = '', sellLocked = '';
        if(this.state.lockedSide === 'Buy'){
            buyLocked = '*';
        }
        else{
            sellLocked = '*';
        }

        let additionalSettlementbutton = []
        let additionalSettlementButtonText = 'Additional settlements'
        if ((this.props.incomingFunds || []).length > 0) {
            additionalSettlementButtonText = additionalSettlementButtonText + ' (' + this.props.incomingFunds.length + ')'
        }
        if (this.state.allowAdditionalSettlements) {
            additionalSettlementbutton.push(
                <div className="uk-width-1-1" uk-grid="true">
                    <div className="uk-width-1-2"></div>
                    <div className="uk-width-1-2"><button className="uk-button uk-button-green uk-margin-left"
                        onClick={this.onShowPopupPayments}>{additionalSettlementButtonText}</button></div>
                </div>)
        }
        else {
            additionalSettlementbutton.push(
                <div className="uk-width-1-1" >
                </div>)
        }


        return (
            <div>
                <div>
                    <div uk-grid="true">
                        <div className="uk-width-2-5">
                            <h3>Customer: {this.props.objCustomer["Customer Name"]}</h3>
                            <h3>Contact: {this.props.objContact.Name}</h3>
                        </div>
                        <div className="uk-width-3-5" style={{ display: 'inline-flex', justifyContent: 'flex-end'  }}>
                            {/*dealIDLabel*/}
                            {dealSummary}
                        </div>
                    </div>
                </div>

                <hr className="uk-hr"/>

                <div uk-grid="true">
                    <div className="uk-width-1-2">
                        <div uk-grid="true">
                            <div className="uk-width-1-2">
                                <table cellPadding="5" cellSpacing="5" width="100%">
                                    <tbody>
                                        <tr>
                                            <td><label className="uk-form-label">We Sell{sellLocked}</label></td>
                                            <td align="right">{formatToCurrencyWithScale(this.state.sellAmount, this.props.objDealHeader.SellCurrency) + ' ' + this.props.objDealHeader.SellCurrency}</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td><label className="uk-form-label">We Buy{buyLocked}</label></td>
                                            <td align="right">{formatToCurrencyWithScale(this.state.buyAmount, this.props.objDealHeader.BuyCurrency) + ' ' + this.props.objDealHeader.BuyCurrency}</td>
                                            
                                        </tr>                                    
                                        {feeSection}
                                        <tr>
                                            <td><label className="uk-form-label">Total owed by customer</label></td>
                                            <td align="right">{formatToCurrencyWithScale(totalOwnerByCustomer, this.props.objDealHeader.BuyCurrency) + ' ' + this.props.objDealHeader.BuyCurrency}</td>
                                        </tr>
                                        {depositAmountSection}
                                    </tbody>
                                </table>
                            </div>
                            <div className="uk-width-1-2">
                                {ratesInfoSection}                                
                            </div>
                            <div className="uk-width-2-2">
                                {disbursementsSection}
                            </div>                            
                        </div>
                    </div>
                
                    <div className="uk-width-1-2">
                        <div>
                            <div uk-grid="true">
                                <div className="uk-width-1-2">
                                    <label className="uk-form-label">Funds on hold balance</label>
                                </div>
                                <div className="uk-width-1-2">
                                    {formatToCurrencyWithScale(this.state.heldFundsBalance, this.props.objDealHeader.BuyCurrency) + ' ' + this.props.objDealHeader.BuyCurrency}
                                </div>
                            </div>
                        </div>
                        {instrumentControl}
                        {deliveryMethodControl}
                        {nostroAccountControl}
                        {customerBankAccountControl}
                        {thirdPartyPaymentControl}
                        {thirdPartyPayorControl}
                        <div>
                            <label className="uk-form-label">Customer reference number</label>
                            <input className="uk-input" type="text" value={this.state.customerRefNo} onChange={this.handleUpdateCustomerRefNo} />
                        </div>
                        <div>
                            <label className="uk-form-label">Internal comments</label>
                            <div>
                                <textarea className="uk-textarea"
                                    value={this.state.internalComments}
                                    onChange={this.handleUpdateInternalComments}></textarea>
                            </div>
                        </div>
                        <div>
                            <label className="uk-form-label">External comments</label>
                            <div>
                                <textarea className="uk-textarea"
                                    value={this.state.externalComments}
                                    onChange={this.handleUpdateExternalComments}></textarea>
                            </div>
                        </div>
                        
                    </div>

                    {additionalSettlementbutton}
                    
                    <WizardIncomingPaymentPopup
                        open={this.state.showPopupPayments}
                        close={this.onHidePopupPayments}
                        buyAmount={this.state.buyAmount}
                        buyCurrency={this.state.buyCurrency}
                        sellCurrency={this.state.sellCurrency}
                        customerId={this.props.objCustomer["Customer ID"]}
                        incomingFunds={this.props.objDealHeader.IncomingFunds}
                        updateIncomingFunds={this.props.updateIncomingFunds}
                        objDealHeader={this.props.objDealHeader} />

                    <div className="uk-width-1-1">
                        <EntityPropertiesContainer 
                            entityID            = {this.props.objDealHeader.DealHeaderID}
                            entityType          = "Deal"
                            onRefreshProperties = {this.onRefreshProperties}
                            showLikeCollapsable = {true}

                        />
                    </div>
                </div>

                <hr className="uk-hr"/>

                <div>
                    {acceptButton}
                    {acceptAndConfirmButton}
                    {saveAsPendingButton}
                    <button className="uk-button uk-button-green uk-align-right" onClick={this.handleBackClick}>Back</button>
                </div>

                <LoadingBox loading={this.state.loading || this.state.loadingNostroAccounts}/>

                <AlertBox id="balance-alert" 
                    open={this.state.showBalanceAlert} 
                    title="Error" 
                    message={"Not enough balance to complete this transaction! Please select another settlement instrument."}
                    type="Ok"
                    okClick={this.okBalanceAlert}
                />

                <AlertBox id="alert-pending-tasks" 
                    open={this.state.showPendingTasksAlert}
                    title="Warning"
                    message={"Customer has pending mandatory tasks. Deal will be saved in 'Pending' status. Do you want to continue?"}
                    type="Yes/No"
                    onClose={this.closePendingTasksAlert}
                    noClick={this.closePendingTasksAlert}
                    yesClick={this.yesPendingTasksAlert}
                />

                <AlertBox id="global-alert" 
                    open={this.state.showAlert} 
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    type="Ok"
                    okClick={this.okGlobalAlert}/>

                <AlertBox id="alert-dd-useDepositBalance"
                    open={this.state.showUseDepositBalanceAlert}
                    title="Alert"
                    message={this.state.useDepositBalanceAlertMessage}
                    type="Yes/No"
                    onClose={() => { this.setState({ showUseDepositBalanceAlert: false, forwardApplyDeposit: false, useDepositBalanceAlertMessage: '' }) }}
                    noClick={() => { this.setState({ showUseDepositBalanceAlert: false, forwardApplyDeposit: false }); this.onApplyPendingDeposits(false) }}
                    yesClick={() => this.onApplyPendingDeposits(true)}
                />

                {this.state.showValidationSMSCode && <SMSValidationBox id="validate-sms-code" 
                    open={this.state.showValidationSMSCode} 
                    onClose={this.closeValidationSMSCode} 
                    title={this.state.titleValidationSMSCode} 
                    message={this.state.messageValidationSMSCode} 
                    type="Validate" 
                    noClick={this.closeValidationSMSCode}
                    sendSMSCode={this.sendSMSCode}
                    validateSMSCode={this.validatePhone.bind(this)}/>}
            </div>
        );
    }
}