import React from 'react';
import { GlobalSystemParametersNames } from '../../../constants/GlobalSystemParametersNames';
import { formatDate, formatToCurrency, formatToCurrencyWithScale, getScaleByCurrency, getScaleForCurrencyPair, getTimehhmm, roundAmountByCurrency, roundAmountByCurrencyPair } from '../../../helpers/FormatHelper';
import { getDealFees } from '../../../networking/NetworkingDealFees';
import { adjustDealHeader, closeoutForward, getFullDealHeader, linkSwapToDeal, updateDealHeader, voidDealHeader, voidDealHeader2 } from '../../../networking/NetworkingDeals';
import { getQuoteByID, saveQuote } from '../../../networking/NetworkingQuotes';
import { getBroadcastRates, getProfit, getRate, getSupportingRates, saveSupportingRates } from '../../../networking/NetworkingRates';
import { getStyle, notFilledInputClass, notFilledSelectClass, regularInputClass, regularSelectClass } from '../../../styles/styles';
import { saveEntityProperty, getEntityProperties } from '../../../networking/NetworkingEntityProperties';
import { generateSMSCode, validateSMSCode } from '../../../networking/Networking';
import DealVersionViewer from '../../deal/DealVersionViewer';
import EmailConfirmationDialog from '../../deal/EmailConfirmationDialog';
import AlertBox from '../../shared/AlertBox';
import SMSValidationBox from '../../shared/SMSValidationBox';
import DatePicker from '../../shared/DatePicker';
import BroadcastRateSelectionDialog from '../../shared/Dialogs/BroadcastRateSelectionDialog';
import DynamicSelect from '../../shared/DynamicSelect';
import LoadingBox from '../../shared/LoadingBox';
import NumberInput from '../../shared/NumberInput';
import AdditionalInformationScreen from './AddInformation/AdditionalInformationScreen';
import WizardDrawdownsView from './WizardDrawdownsView';
import WizardSupportingRates from './WizardSupportingRates';
import WizardTransactionsView from './WizardTransactionsView';
import LinkToDeal from '../../deal/shared/LinkToDeal';
import { GetAmounts } from '../../../helpers/AmountsHelper';
import BuybackForm from './BuybackForm';
import NewDealConfirmationFormDialog from '../../deal/NewDealConfirmationFormDialog';



export default class WizardSpotForm extends React.Component {
    state = {
        adjustmentDealID: null,
        balanceCloseout:0,
        balanceCloseoutCurrency:'',
        bankCustomers: [],
        baseCurrency: '',
        beneficaryList: [],
        broadcastRateOptions: [],
        buyAmount: 0,
        buyCurrency: '',
        buyCurrencyDecimals: 2,
        closeoutMessage: '',
        closeoutComment: '',
        coverCustomerID: 0,
        coverDealID: 0,
        coveredDeals: '',
        currencyList: [],
        currentMarketRate: 0,
        customerID: 0,
        customerContactID: 0,
        customerName: '',
        customerRate: 0, 
        dealFeeList: [],
        dealHeaderID: 0,
        dealsRefreshFlag: 0,
        dealType: 0,
        depositAmount: 0,
        depositCurrencies: [],
        depositCurrency: '',
        depositID: 0,
        depositPercent: this.props.objCustomer['DefaultDepositPercent$'],
        externalInterface: '',
        externalInterfaceType: '',
        farLegBuyAmount: 0,
        farLegCustomerRate: 0,
        farLegDealHeaderID: 0,
        farLegEnableControlUpdates: true,
        farLegForwardPoints: 0,
        farLegMarketRate: 0,
        farLegOriginalMarketSpotRate: 0,
        farLegProfit: 0,
        farLegProfitCcy: 0,
        farLegSellAmount: 0,
        farLegSpread: 0,
        farLegUpdateToken: '',
        forwardBuyAmount: 0,
        forwardBuyTotalDisbursed: 0,
        forwardCoverDealID: 0,
        forwardDays: 0,
        forwardMaturityDate: '',
        forwardProfitAmount: 0,
        forwardProfitAmountDisbursed: 0,
        forwardSellAmount: 0,
        forwardSellTotalDisbursed: 0,
        forwardType: 'Open',
        forwardValueDate: '',
        isAdjustmentDeal: false,
        isCloseout: false,
        isSameCurrencyDealRefund: false,
        loading: false,
        lockedSide: 'Sell',
        marketRate: 0,
        maturityDate: new Date(this.props.systemDate),
        nearLegCustomerRate: 0,
        nearLegEnableControlUpdates: true,
        nearLegForwardPoints: 0,
        nearLegMarketRate: 0,
        nearLegOriginalMarketSpotRate: 0,
        nearLegProfit: 0,
        nearLegProfitCcy: 0,
        nearLegSpread: 0,
        nostroAccountID: 0,
        openDealVersions: false,
        originalBuyAmount: 0,
        originalProfit: 0,
        originalSellAmount: 0,
        parentDealHeaderID: 0,
        paymentCurrencies: [],
        profitAmount: 0, //base currency 
        profitAmountCcy: 0, 
        quoteNo: 0,
        quotesRefreshFlag: 0,
        ratePrecision: 8,
        rateQuoteID: '',
        rateQuoteIDNoInterface: '',
        rateTerms: '',
        requireDeposits: false,
        rightViewMode: 'regular',
        sellAmount: 0,
        sellCurrency : '',
        sellCurrencyDecimals: 2,
        settlementCurrencies: [],
        showAdjustConfirmation: false,
        showAlert: false,
        showCloseoutConfirmation: false,
        showCloseoutResult: false,
        showRateSelection: false,
        showVoidConfirmation: false,
        spreadPoints: 0,
        spreadPercentage: 0,     
        status: '',   
        totalFees: 0,
        totalDisbursed : 0,
        updateToken: '',
        useInterface: false,
        validations:{
            buyAmount: true,
            buyCurrency: true,
            depositCurrency: true,
            farLegBuyAmount: true,
            farLegSellAmount: true,
            maturityDate: true,
            sellAmount: true,
            sellCurrency: true,
            valueDate: true,
        },
        valueDate: new Date(this.props.systemDate),
        startDate: new Date(this.props.systemDate),
        startDateMaturity: new Date(this.props.systemDate),
        endDate: new Date(this.props.systemDate),
        depositsBalance: 0,
        depositsBalanceInfo: '',

        buyAmountEnableUpdatesTrigger: true,
        buyCurrencyEnableUpdatesTrigger: true,
        customerRateEnableUpdatesTrigger: true,
        depositAmountEnableUpdatesTrigger: true,
        depositPercentageEnableUpdatesTrigger: true,
        marketRateEnableUpdatesTrigger: true,
        sellAmountEnableUpdatesTrigger: true,
        sellCurrencyEnableUpdatesTrigger: true,
        forwardDaysEnableUpdatesTrigger: true,
        depositCurrencyEnableUpdatesTrigger: true,
        allowAdditionalSettlements: false,
        showDealConfirmation: false,        
        showLinkToDeal: false,

        //-----
        addInfo: false,
        addInfoObj: null,

        //Disable controls
        sellAmountDisabled: true,
        buyAmountDisabled: true,
        marketRateDisabled: true,
        customerRateDisabled: true,
        sellCurrencyDisabled: true,
        buyCurrencyDisabled: true,
        valueDateDisabled: true,
        forwardTypeDisabled: true,
        depositPercentageDisabled: true,
        depositAmountDisabled: true,
        requireDepositsDisabled: true,
        depositCurrencyDisabled: true,
        forwardDaysDisabled: true,
        maturityDateDisabled: true,
        farLegSellAmountDisabled: true,
        farLegBuyAmountDisabled: true,
        nearLegMarketRateDisabled: true,
        farLegMarketRateDisabled: true,
        nearLegCustomerRateDisabled: true,
        farLegCustomerRateDisabled: true,
        nearLegSpreadDisabled: true,
        farLegSpreadDisabled: true,
        nearLegOriginalMarketRateDisabled: true,
        farLegOriginalMarketRateDisabled: true,
        coverCustomerDisabled: true,
        useInterfaceDisabled: true,
        disableForwardType: true,
        editDeal: false,
        editingDeal: false, //after click on Edit button
        disableSupportingControls: false,
        editSupportingRates: false,
        showReverseButton: false,
        deal: {},
        disableTransactionSection: false,
        forwardPointsScale: 8,
        allowEditSwapFarLeg: false,

        //validationLimits
        originalMarketRate:0,
        originalCustomerRate:0,
        originalMarketSpotRate:0,
        originalFarCustomerRate:0,
        originalFarMarketRate: 0,
        originalFarMarketSpotRate:0,
        limit:0,
        oldMarketRate: 0,
        oldCustomerRate:0,
        oldMarketSpotRate:0,
        oldFarCustomerRate:0,
        oldFarMarketRate:0,
        oldFarMarketSpotRate:0,

        editForwardDate: false,
        dealAlreadyCoveredInInterface: false,

        jsonProperties:'',
        dealIdToLink: 0, //used for swap link deal
        supportingRates: {},

        showMessage: false,
        messageMessage: '',
        contacts_length: 0,

        enableDepositControls: true,
        
        //SMS Validation
        showValidationSMSCode: false,
        titleValidationSMSCode: '',
        messageValidationSMSCode: '',
        useSMSValidation: false,
        phoneValidated: true,
        confirmedByClient: false,
        contactMobile: '',
        contactEmail: '',
        showDealConfirmationForm: false,
        showIncludeInwardsConfirmation: false
    }

    componentDidMount(){        
        //Load working spot
        var sellAmount = 0, buyAmount = 0, profitAmount = 0, profitAmountCcy = 0,
            marketRate = 0, originalMarketRate = 0, customerRate = 0, spreadPoints = 0,
            spreadPercentage = 0, totalFees = 0, dealHeaderID = 0,
            adjustmentDealID = 0, sellCurrencyDecimals = 2, buyCurrencyDecimals = 2,
            forwardDays = 0, depositID = 0, depositPercent = this.props.objCustomer['DefaultDepositPercent$'],
            depositAmount = 0, parentDealHeaderID = 0, forwardBuyAmount = 0,
            forwardBuyTotalDisbursed = 0, forwardSellAmount = 0, forwardSellTotalDisbursed = 0,
            totalDisbursed = 0, originalBuyAmount = 0, originalSellAmount = 0,
            originalProfit = 0, forwardProfitAmount = 0, forwardProfitAmountDisbursed = 0,
            coverCustomerID = 0, coverDealID = 0, forwardCoverDealID = 0,
            nostroAccountID = 0;
        
        let forwardPointsScale = localStorage.getItem(GlobalSystemParametersNames.ForwardPointsScale)

        var buyCurrency = '', sellCurrency = '', valueDate = '',
        rateQuoteID = '', rateTerms = '', lockedSide = 'Sell', 
        updateToken = '', dealType = 1, maturityDate = '',
        forwardType = 'Open', depositCurrency = '', status = '',
        coveredDeals = '', externalInterface = '', externalInterfaceType = '',
        forwardMaturityDate = '', forwardValueDate = '';

        //Swap near leg parameters
        var originalCustomerRate = 0, originalMarketSpotRate = 0, nearLegCustomerRate = 0, nearLegForwardPoints = 0,
            nearLegMarketRate = 0, nearLegOriginalMarketSpotRate = 0, nearLegProfit = 0,nearLegProfitCcy = 0,
            nearLegSpread = 0;

        //Swap far leg parameters
        var farLegBuyAmount = 0, farLegCustomerRate = 0, originalFarMarketRate = 0, originalFarMarketSpotRate = 0, originalFarCustomerRate = 0, farLegForwardPoints = 0,
            farLegMarketRate = 0, farLegOriginalMarketSpotRate = 0, farLegProfit = 0,farLegProfitCcy = 0,
            farLegSellAmount = 0, farLegSpread = 0, farLegDealHeaderID = 0;

        var farLegUpdateToken = '';

        var isAdjustmentDeal = false, requireDeposits = false, useInterface = false,
            isSameCurrencyDealRefund = false;

        var dealFees;

        let objDealHeader = this.props.objDealHeader;        

        //If customer is Bank, set default one click cover if possible
        let customerID = this.props.objCustomer['Customer ID'];
        if(this.props.objCustomer['Customer Type'] === 'Bank'){
            let objBank = this.props.bankCustomers.find(function(element){
                return Number(element.CustomerID) === Number(customerID);
            });
            if(objBank !== undefined){
                coverCustomerID = objBank.CustomerID;
            }
        }
        
        let screenPermisions = JSON.parse(localStorage.getItem('ScreenShowPermissions'));
        if(screenPermisions != undefined && screenPermisions.EditDeal != undefined){
            this.setState({editDeal: screenPermisions.EditDeal})
        }
        /*for (var i = 0; i < screenPermisions.length; i++) {
            if(screenPermisions[i] == 'EditDeal'){
                this.setState({editDeal: true});
            }
        }*/

        
        debugger
        this.getSMSConfig(customerID);



        var alertMessage = '', alertTitle = '';
        var showAlert = false;

        let allowAdditionalSettlements = localStorage.getItem(GlobalSystemParametersNames.TradeScreenAllowAdditionalSettlements).toUpperCase() === 'TRUE';

        //Load deal header values
        var loadSpotDays = false;
        if(objDealHeader !== undefined)
        {
            if(objDealHeader.BuyAmount !== undefined){
                buyAmount = objDealHeader.BuyAmount;
            }
            
            if(objDealHeader.BuyCurrency !== undefined){
                buyCurrency = objDealHeader.BuyCurrency;
            }

            if(objDealHeader.SellAmount !== undefined){
                sellAmount = objDealHeader.SellAmount;
            }
            
            if(objDealHeader.SellCurrency !== undefined){
                sellCurrency = objDealHeader.SellCurrency;
            }
            
            if(objDealHeader.LockedSide !== undefined){
                lockedSide = objDealHeader.LockedSide;
            }
            
            if(objDealHeader.DealType !== undefined){
                dealType = objDealHeader.DealType;
            }

            if(buyCurrency !== undefined && sellCurrency !== undefined){
                loadSpotDays = true;
            }

            if(objDealHeader.RateQuoteID !== undefined){
 
                //Show covered deal warning
                if(Number(objDealHeader.DealHeaderID) > 0
                    && Number(objDealHeader.CoverDealID) > 0){
                    alertTitle  = 'Warning';
                    alertMessage += 'Please notice that this deal is covered by ' + objDealHeader.CoverDealID + '. ';
                    showAlert = true;
                }

                //Show cover deal warning
                if (objDealHeader.CoveredDeals != null && objDealHeader.CoveredDeals !== '') {
                    alertTitle = 'Warning';
                    alertMessage += 'Please notice that this deal covers these deal(s): ' + objDealHeader.CoveredDeals + '. ';
                    showAlert = true;
                }

                adjustmentDealID = objDealHeader.AdjustmentDealID;                
                buyCurrencyDecimals = this.getNumberOfDecimals(objDealHeader.BuyCurrency);
                coverCustomerID = objDealHeader.CoverCustomerID;
                coverDealID = objDealHeader.CoverDealID;
                coveredDeals = objDealHeader.CoveredDeals;
                customerRate = objDealHeader.CustomerRate;
                dealFees = objDealHeader.DealFees;
                dealHeaderID = objDealHeader.DealHeaderID;
                
                depositAmount = objDealHeader.DepositAmount;
                depositCurrency = objDealHeader.DepositCurrency;
                depositID = objDealHeader.DepositID;
                if(objDealHeader.DepositPercent !== undefined){
                    depositPercent = objDealHeader.DepositPercent;
                }
                /*if(objDealHeader.Deposits === null || 
                    objDealHeader.Deposits === undefined ||
                    objDealHeader.Deposits.length === 0){
                        this.setState({depositPercentageDisabled: false, depositAmountDisabled: false, depositCurrencyDisabled: false})
                }*/
                if(objDealHeader.Deposits !== null &&
                    objDealHeader.Deposits !== undefined &&
                    objDealHeader.Deposits.length > 0){
                    depositAmount = objDealHeader.Deposits[0].Amount;
                    depositCurrency = objDealHeader.Deposits[0].CurrencyID;
                    depositID = objDealHeader.Deposits[0].DepositID;
                    /*var initialDeposit = objDealHeader.Deposits.find(deposit => deposit.DepositType === 'InitialDeposit');
                    if(initialDeposit !== null && initialDeposit !== undefined &&
                        initialDeposit.ReceivedStatus === 'Outstanding'){
                        this.setState({depositPercentageDisabled: false, depositAmountDisabled: false, depositCurrencyDisabled: false})
                    }*/
                } /*else {
                    this.setState({depositPercentageDisabled: false, depositAmountDisabled: false, depositCurrencyDisabled: false})
                }*/
                externalInterface = objDealHeader.ExternalInterface;
                externalInterfaceType = objDealHeader.ExternalInterfaceType;
                if(Number(objDealHeader.DealType) === 4){
                    forwardDays = this.daysBetweenDates(objDealHeader.ValueDate, objDealHeader.MaturityDate);
                }
                forwardBuyAmount = objDealHeader.ForwardBuyAmount;
                forwardBuyTotalDisbursed = objDealHeader.ForwardBuyTotalDisbursed;
                forwardCoverDealID = objDealHeader.ForwardCoverDealID;
                forwardMaturityDate = objDealHeader.ForwardMaturityDate;
                forwardProfitAmount = objDealHeader.ForwardProfitAmount;
                forwardProfitAmountDisbursed = objDealHeader.ForwardProfitAmountDisbursed;
                forwardSellAmount = objDealHeader.ForwardSellAmount;
                forwardSellTotalDisbursed = objDealHeader.ForwardSellTotalDisbursed;
                forwardType = objDealHeader.ForwardType;
                forwardValueDate = objDealHeader.ForwardValueDate;
                isAdjustmentDeal = objDealHeader.IsAdjustmentDeal;
                if(objDealHeader.IsSameCurrencyDealRefund !== undefined){
                    isSameCurrencyDealRefund = objDealHeader.IsSameCurrencyDealRefund;
                }
                marketRate = objDealHeader.MarketRate;
                originalCustomerRate = objDealHeader.OriginalCustomerRate;
                originalMarketRate= objDealHeader.OriginalMarketSpotRate;
                maturityDate = objDealHeader.MaturityDate;
                nostroAccountID = objDealHeader.NostroAccountID;
                originalBuyAmount = objDealHeader.OriginalBuyAmount;
                originalProfit = objDealHeader.OriginalProfit;
                originalSellAmount = objDealHeader.OriginalSellAmount;
                parentDealHeaderID = objDealHeader.ParentDealHeaderID;
                profitAmount = objDealHeader.ProfitAmount;
                profitAmountCcy = 0;
                rateQuoteID = objDealHeader.RateQuoteID;
                rateTerms = objDealHeader.RateTerms;
                requireDeposits = objDealHeader.RequireDeposits;
                sellCurrencyDecimals = this.getNumberOfDecimals(objDealHeader.SellCurrency);
                status = objDealHeader.Status;
                //spreadPercentage = objDealHeader.SpreadPercentage;
                //spreadPoints = objDealHeader.SpreadPoints;
                totalFees = objDealHeader.TotalFees;
                totalDisbursed = objDealHeader.TotalDisbursed;
                updateToken = objDealHeader.UpdateToken;
                useInterface = objDealHeader.UseInterface;
                valueDate = objDealHeader.ValueDate;
                loadSpotDays = true;

                //Spread points and percentage
                let profitPoints = Math.abs(customerRate - marketRate);
                if(marketRate !== 0){
                    spreadPercentage = ((profitPoints / marketRate) * 100).toFixed(2);
                }            
                var scaleCCYPair = getScaleForCurrencyPair(objDealHeader.BuyCurrency, objDealHeader.SellCurrency);
                spreadPoints = (profitPoints * 10000).toFixed(scaleCCYPair);

                //Far leg swap
                if(dealType === 6){
                    let swapNearLegDealHeader = objDealHeader.SwapNearLegDealHeader;
                    originalCustomerRate = swapNearLegDealHeader.OriginalCustomerRate;
                    originalMarketSpotRate = swapNearLegDealHeader.OriginalMarketSpotRate;
                    nearLegCustomerRate = swapNearLegDealHeader.CustomerRate;
                    nearLegForwardPoints = swapNearLegDealHeader.ForwardPoints;
                    nearLegMarketRate = swapNearLegDealHeader.MarketRate;
                    nearLegOriginalMarketSpotRate = swapNearLegDealHeader.OriginalMarketSpotRate;
                    nearLegProfit = swapNearLegDealHeader.ProfitAmount;
                    nearLegProfitCcy = 0;
                    nearLegSpread = swapNearLegDealHeader.Spread;

                    let swapFarLegDealHeader = objDealHeader.SwapFarLegDealHeader;
                    //alert(JSON.stringify(swapFarLegDealHeader));
                    farLegBuyAmount = swapFarLegDealHeader.BuyAmount;
                    farLegCustomerRate = swapFarLegDealHeader.CustomerRate;
                    originalFarCustomerRate = swapFarLegDealHeader.OriginalCustomerRate;
                    originalFarMarketRate = swapFarLegDealHeader.OriginalMarketSpotRate;
                    originalFarMarketSpotRate = swapFarLegDealHeader.OriginalMarketSpotRate;
                    farLegDealHeaderID = swapFarLegDealHeader.DealHeaderID;
                    farLegForwardPoints = swapFarLegDealHeader.ForwardPoints;
                    farLegMarketRate = swapFarLegDealHeader.MarketRate;
                    farLegOriginalMarketSpotRate = swapFarLegDealHeader.OriginalMarketSpotRate;
                    farLegProfit = swapFarLegDealHeader.ProfitAmount;
                    farLegProfitCcy = 0;
                    farLegSellAmount = swapFarLegDealHeader.SellAmount;
                    farLegSpread = swapFarLegDealHeader.Spread;
                    farLegUpdateToken = swapFarLegDealHeader.UpdateToken;

                    maturityDate = swapFarLegDealHeader.ValueDate;
                    forwardDays = this.daysBetweenDates(valueDate, maturityDate);
                }
            }
        }
        
        //Customer defaults
        if(sellCurrency === '' &&
            this.props.objCustomer['DefaultSellCurrencyID$'] !== ''){
            sellCurrency = this.props.objCustomer['DefaultSellCurrencyID$'];
            sellCurrencyDecimals = this.getNumberOfDecimals(this.props.objCustomer['DefaultSellCurrencyID$']);
            loadSpotDays = true;
        }

        if(this.props.objCustomer['DefaultBuyCurrencyID$'] !== ''){
            if(buyCurrency === ''){
                buyCurrency = this.props.objCustomer['DefaultBuyCurrencyID$'];
                buyCurrencyDecimals = this.getNumberOfDecimals(this.props.objCustomer['DefaultBuyCurrencyID$']);
                loadSpotDays = true;
            }
            if(depositCurrency === ''){
                depositCurrency = this.props.objCustomer['DefaultBuyCurrencyID$'];
            }
        }

        //Handle deals to cover
        var dealsToCover = [];
        if(this.props.objDealHeader !== undefined
            && this.props.objDealHeader.DealsToCover !== undefined){
            dealsToCover = this.props.objDealHeader.DealsToCover;
            //alert(JSON.stringify(dealsToCover));
        }

        //Verify currency selection
        let paymentCurrencies = this.props.paymentCurrencies;
        let settlementCurrencies = this.props.settlementCurrencies;

        var showCoverCurrenciesWarning = false;
        let buyCurrencyFound = settlementCurrencies.find(function(element){
            return element.value === buyCurrency;
        });
        if(buyCurrencyFound === undefined){
            buyCurrency = '';
            if(dealsToCover.length > 0){
                showCoverCurrenciesWarning = true;
                buyAmount = sellAmount = 0;
                sellCurrency = '';
            }
            dealsToCover = [];
        }

        let sellCurrencyFound = paymentCurrencies.find(function(element){
            return element.value === sellCurrency;
        });
        if(sellCurrencyFound === undefined){
            sellCurrency = '';
            if(dealsToCover.length > 0){
                showCoverCurrenciesWarning = true;
                buyAmount = sellAmount = 0;
                buyCurrency = '';
            }
            dealsToCover = [];
        }
        
        if(showCoverCurrenciesWarning){
            alertMessage += 'Selected currency or currencies are not available for this customer. ';
            alertTitle = 'Warning';
            showAlert = true;
        }

        //Load spot days
        let cutoffTime = localStorage.getItem('CutoffTime');
        if(cutoffTime === null){
            cutoffTime = '12:00';
        }
        
        var currentDate = new Date();
        var startDate = new Date(this.props.systemDate);
        if(dealType === 2 && new Date(forwardValueDate) >= startDate){
            startDate = new Date(forwardValueDate);
        }
        startDate.setHours(currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds(), 0);
        if(getTimehhmm(startDate) > cutoffTime){
            startDate = this.getNextBusinessDate(startDate, 1, buyCurrency, sellCurrency);
        }
        
        var endDate = startDate;

        switch(dealType){
            case 1:
                if(loadSpotDays){
                    endDate = this.calculateEndDate(startDate, buyCurrency, sellCurrency);
                    if(buyCurrency !== undefined 
                        && sellCurrency !== undefined 
                        && buyCurrency !== '' 
                        && sellCurrency !== ''
                        && dealHeaderID === 0)
                    {
                        valueDate = this.setValueDateOnLoad(startDate, buyCurrency, sellCurrency);
                    }
                }
                break;
            case 2:
                endDate = maturityDate;
                if (objDealHeader !== undefined && objDealHeader !== null) {
                    endDate = objDealHeader.ForwardMaturityDate
                }
                break;
            case 4:
                endDate = this.getNextBusinessDateForward(startDate, this.props.maxForwardWindow, buyCurrency, sellCurrency);                
                break;
            case 6: //swap- any date greather than system date (WILLY)              
                endDate = null;//undefined;
            default:
                break;
        }

        //Get rate precision
        let ratePrecision = this.getRatePrecision(sellCurrency, buyCurrency);
        let limit = this.getRateLimit(sellCurrency, buyCurrency);
        
        //Warning for same currency deal refund
        if(isSameCurrencyDealRefund){
            alertMessage += 'Please notice that this is a same currency deal refund. ';
            alertTitle = 'Warning';
            showAlert = true;
        }

        

        //Set state
        this.setState({
            sellCurrency: sellCurrency,
            sellCurrencyDecimals: sellCurrencyDecimals,
            buyCurrency: buyCurrency,
            buyCurrencyDecimals: buyCurrencyDecimals
            }, () => {
        this.setState({

            buyAmountEnableUpdatesTrigger: false,
            buyCurrencyEnableUpdatesTrigger: false,
            customerRateEnableUpdatesTrigger: false,
            depositCurrencyEnableUpdatesTrigger: false,
            farLegEnableControlUpdates: false,
            marketRateEnableUpdatesTrigger: false,
            nearLegEnableControlUpdates: false,
            sellAmountEnableUpdatesTrigger: false,
            sellCurrencyEnableUpdatesTrigger: false,

            allowAdditionalSettlements: allowAdditionalSettlements,

            adjustmentDealID: adjustmentDealID,
            alertMessage: alertMessage,
            alertTitle: alertTitle,
            bankCustomers: this.props.bankCustomers,
            baseCurrency: localStorage.getItem('BaseCurrency'),
            buyAmount: buyAmount,
            coverCustomerID: coverCustomerID,
            coverDealID: coverDealID,
            coveredDeals: coveredDeals,
            currentMarketRate: 0,
            customerContactID: this.props.objContact['Contact ID'],
            customerID: this.props.objCustomer['Customer ID'],
            customerRate: customerRate,
            dealHeaderID: dealHeaderID,
            dealsToCover: dealsToCover,
            dealType: dealType,
            depositAmount: depositAmount,
            depositCurrencies: this.props.settlementCurrencies,
            depositCurrency: depositCurrency,
            depositID: depositID,
            depositPercent: depositPercent,
            externalInterface: externalInterface,
            externalInterfaceType: externalInterfaceType,

            farLegBuyAmount: farLegBuyAmount,
            farLegCustomerRate: farLegCustomerRate,
            originalFarMarketRate: originalFarMarketRate,
            originalFarMarketSpotRate: originalFarMarketSpotRate,
            originalFarCustomerRate: originalFarCustomerRate,
            farLegDealHeaderID: farLegDealHeaderID,
            farLegForwardPoints: farLegForwardPoints,
            farLegMarketRate: farLegMarketRate,
            farLegOriginalMarketSpotRate: farLegOriginalMarketSpotRate,
            farLegProfit: farLegProfit,
            farLegProfitCcy: farLegProfitCcy,
            farLegSellAmount: farLegSellAmount,
            farLegSpread: farLegSpread,
            farLegUpdateToken: farLegUpdateToken,

            forwardBuyAmount: forwardBuyAmount,
            forwardBuyTotalDisbursed: forwardBuyTotalDisbursed,
            forwardCoverDealID: forwardCoverDealID,
            forwardMaturityDate: forwardMaturityDate,
            forwardProfitAmount: forwardProfitAmount,
            forwardProfitAmountDisbursed: forwardProfitAmountDisbursed,
            forwardDays: forwardDays,
            forwardSellAmount: forwardSellAmount,
            forwardSellTotalDisbursed: forwardSellTotalDisbursed,
            forwardType: forwardType,
            forwardValueDate: forwardValueDate,
            isAdjustmentDeal: isAdjustmentDeal,
            isSameCurrencyDealRefund: isSameCurrencyDealRefund,
            lockedSide: lockedSide,
            marketRate: marketRate,
            originalMarketRate: originalMarketRate,
            maturityDate: maturityDate,

            originalCustomerRate: originalCustomerRate,
            originalMarketSpotRate: originalMarketSpotRate,
            nearLegCustomerRate: nearLegCustomerRate,
            nearLegForwardPoints: nearLegForwardPoints,
            nearLegMarketRate: nearLegMarketRate,
            nearLegOriginalMarketSpotRate: nearLegOriginalMarketSpotRate,
            nearLegProfit: nearLegProfit,
            nearLegProfitCcy: nearLegProfitCcy,
            nearLegSpread: nearLegSpread,

            nostroAccountID: nostroAccountID,
            originalBuyAmount: originalBuyAmount,
            originalProfit: originalProfit,
            originalSellAmount: originalSellAmount,
            parentDealHeaderID: parentDealHeaderID,
            profitAmount: profitAmount,
            profitAmountCcy: profitAmountCcy,
            ratePrecision: ratePrecision,
            rateQuoteID: rateQuoteID,
            rateTerms: rateTerms,
            requireDeposits: requireDeposits,
            sellAmount: sellAmount,
            showAlert: showAlert,
            spreadPercentage: spreadPercentage,
            spreadPoints: spreadPoints,
            status: status,
            totalDisbursed:totalDisbursed,
            totalFees: totalFees,
            updateToken: updateToken,
            useInterface: useInterface,
            valueDate: valueDate,
            paymentCurrencies: this.props.paymentCurrencies,
            settlementCurrencies: this.props.settlementCurrencies,
            startDate: startDate,
            startDateMaturity: startDate,
            endDate: endDate,
            deal: objDealHeader,
            forwardPointsScale: forwardPointsScale,
            limit: limit,
            dealIdToLink: objDealHeader !== undefined && objDealHeader.CustomerDealID !== undefined ? Number(objDealHeader.CustomerDealID) : 0,
            allowEditSwapFarLeg: false,
            editingDeal: false,
        }, () => {            
            this.setState({buyAmountEnableUpdatesTrigger: true,
                buyCurrencyEnableUpdatesTrigger: true,
                customerRateEnableUpdatesTrigger: true,
                depositCurrencyEnableUpdatesTrigger: true,
                farLegEnableControlUpdates: true,
                marketRateEnableUpdatesTrigger: true,
                nearLegEnableControlUpdates: true,
                sellAmountEnableUpdatesTrigger: true,
                sellCurrencyEnableUpdatesTrigger: true,                
            }, () => {
                if(objDealHeader.RateQuoteID == null)
                {
                    this.getRate(true);
                }
                if (this.props.refreshSpot != null && this.props.refreshSpot) {
                    this.handleRefreshClick();
                    this.props.updatedRefreshSpot();
                }
            });
        });
    });

        //Get deal fees
        if(dealFees !== undefined){
            this.setState({dealFeeList: dealFees});
        }
        else{
            this.getDealFees(this.state.dealHeaderID);
        }
        if(objDealHeader.DealHeaderID == undefined || objDealHeader.DealHeaderID == 0){
            this.disableAllControls();
        }
    }

    getSMSConfig = async(customerID) =>{
        let smsValidation = false;
        let phoneValidated =true;
        let contactMobile= '';
        let response = await getEntityProperties(customerID, 'Customer')
        if(response && response.entityProperties)
        {            
            let property = response.entityProperties.find(x => x.Description === 'Ask SMS Confirmation');
                if(property !== undefined && property !== null) {
                    if(property.TextValue === 'Yes') { smsValidation = true; }
                }
                phoneValidated= false;
            
            if(this.props.objContact['Mobile Phone'] !== undefined && this.props.objContact['Mobile Phone'] !== null && this.props.objContact['Mobile Phone'] !== '') {
                contactMobile= this.props.objContact['Mobile Phone'];
            }

            this.setState({
                useSMSValidation: smsValidation,
                phoneValidated: phoneValidated,
                contactMobile: contactMobile
            });
        }
    }
    getRatePrecision(sellCurrency, buyCurrency){
        var ratePrecision = 8;
        if(this.props.currencyPairs[buyCurrency+sellCurrency] !== undefined){
            ratePrecision = this.props.currencyPairs[buyCurrency+sellCurrency].RatePrecision;
        }
        return ratePrecision;
    }

    getRateLimit(sellCurrency, buyCurrency){
        var limit = 0;
        if(this.props.currencyPairs[buyCurrency+sellCurrency] !== undefined){
            limit = this.props.currencyPairs[buyCurrency+sellCurrency].MarginError;
        }
        return limit;
    }

    setValueDateOnLoad(startDate, buyCurrency, sellCurrency){
       
        let setDays = 2;

        //Recent holidays
        var holidays = [];
        for(var i=0; i<this.props.holidays.length; i++){
            if(this.props.holidays[i].CurrencyID === 'ANY' ||
            this.props.holidays[i].CurrencyID === sellCurrency ||
            this.props.holidays[i].CurrencyID === buyCurrency){
                holidays.push(this.props.holidays[i].Date);
            }
        }

        //Calculate end date
        var valueDate = new Date(startDate);
        var daysToAdd = setDays;
        
        while(daysToAdd > 0){
            valueDate.setDate(valueDate.getDate() + 1);
            if(valueDate.getDay() === 6 || 
            valueDate.getDay() === 0 ||
                holidays.includes(formatDate(valueDate))){
                //Nothing at all
            }
            else{
                daysToAdd--;
            } 
        }
        return valueDate;
    }

    getNumberOfDecimals(currency){        
        var currencyDecimals = 2;
        if(this.props.customerCurrencyDictionary[currency] !== undefined){
            currencyDecimals = this.props.customerCurrencyDictionary[currency].numberOfDecimals;
        }
        return currencyDecimals;
    }

    getDealFees(dealHeaderID){
        getDealFees(dealHeaderID, 'Customer (Buy CCY)', 'Trade Screen').then(
            (json) => {
                let jsonDealFees = json.dealFees;
                var helper = [];
                for(var i=0; i<jsonDealFees.length; i++){
                    jsonDealFees[i].FeeDescription = jsonDealFees[i].Description;
                    helper.push(jsonDealFees[i]);
                }
                this.setState({dealFeeList: helper});
            }
        );
    }

    componentDidUpdate(prevProps, prevState){        
        let dealAlreadyCoveredInInterface = false;       
        
        if((Number(this.props.objDealHeader.DealHeaderID) !== 0 &&
            prevProps.objDealHeader.DealHeaderID !== this.props.objDealHeader.DealHeaderID) || 
            (Number(this.props.objDealHeader.QuoteNo) !== 0 &&
                prevProps.objDealHeader.QuoteNo !== this.props.objDealHeader.QuoteNo)
                || this.state.isCloseout
                || this.props.objDealHeader.ForDrawdownCreation != prevProps.objDealHeader.ForDrawdownCreation) {
            
            //Load new selected deal
            let objDealHeader = this.props.objDealHeader;

            let screenPermisions = JSON.parse(localStorage.getItem('ScreenShowPermissions'));
            if(screenPermisions != undefined && screenPermisions.EditDeal != undefined){
                this.setState({editDeal: screenPermisions.EditDeal})
            }

            //Handle drawdown creation
            if (objDealHeader.ForDrawdownCreation) {
                //Assing parent deal header id before overriding to 0
                objDealHeader.ParentDealHeaderID = objDealHeader.DealHeaderID;

                //Calculate drawdown amounts
                var drawdownSellAmount = roundAmountByCurrency((Number(objDealHeader.SellAmount) - Number(objDealHeader.TotalDisbursed)), objDealHeader.SellCurrency)
                var drawdownBuyAmount = roundAmountByCurrency((Number(objDealHeader.BuyAmount) - Number(objDealHeader.ForwardBuyTotalDisbursed)), objDealHeader.BuyCurrency)
                var drawdownProfitAmount = roundAmountByCurrency((Number(objDealHeader.ProfitAmount) - Number(objDealHeader.ForwardProfitAmountDisbursed)), this.state.baseCurrency)

                //Save forward values
                objDealHeader.ForwardBuyAmount = objDealHeader.BuyAmount;
                objDealHeader.ForwardCoverDealID = objDealHeader.CoverDealID;
                objDealHeader.ForwardMaturityDate = new Date(objDealHeader.MaturityDate);
                objDealHeader.ForwardSellAmount = objDealHeader.SellAmount;
                objDealHeader.ForwardProfitAmount = objDealHeader.ProfitAmount;
                objDealHeader.ForwardValueDate = new Date(objDealHeader.ValueDate);

                //Override forward value with drawdown values
                objDealHeader.BuyAmount = drawdownBuyAmount;
                objDealHeader.DealHeaderID = 0;
                objDealHeader.DealType = 2;
                objDealHeader.DepositPercent = undefined;
                objDealHeader.Deposits = null;
                objDealHeader.ForwardType = undefined;
                objDealHeader.ProfitAmount = drawdownProfitAmount;
                objDealHeader.RequireDeposits = undefined;
                objDealHeader.SellAmount = drawdownSellAmount;
                objDealHeader.Status = '';
            }

            var sellAmount = 0, buyAmount = 0, profitAmount = 0, profitAmountCcy = 0,
                marketRate = 0, originalMarketRate = 0, customerRate = 0, spreadPoints = 0,
                spreadPercentage = 0, dealHeaderID = 0, buyCurrencyDecimals = 2,
                sellCurrencyDecimals = 2, dealType = this.state.dealType, quoteNo = 0,
                forwardDays = 0, depositID = 0, depositPercent = this.props.objCustomer['DefaultDepositPercent$'],
                depositAmount = 0, parentDealHeaderID = 0, totalDisbursed = 0,
                forwardBuyAmount = 0, forwardBuyTotalDisbursed = 0, forwardSellAmount = 0,
                forwardSellTotalDisbursed = 0, forwardProfitAmount = 0, forwardProfitAmountDisbursed = 0,
                currentMarketRate = 0, farLegBuyAmount = 0, farLegSellAmount = 0,
                farLegOriginalMarketSpotRate = 0, farLegForwardPoints = 0, farLegMarketRate = 0,
                farLegSpread = 0, farLegCustomerRate = 0, farLegProfit = 0,farLegProfitCcy = 0,
                farLegDealHeaderID = 0,
                nearLegOriginalMarketSpotRate = 0, nearLegForwardPoints = 0, nearLegMarketRate = 0,
                nearLegSpread = 0, originalCustomerRate = 0, originalMarketSpotRate, nearLegCustomerRate = 0, nearLegProfitAmount = 0,nearLegProfitCcy = 0,
                forwardCoverDealID = 0, coverCustomerID = 0, nostroAccountID = 0;

            var buyCurrency = '', sellCurrency = '', valueDate = '',
                maturityDate = '', forwardType = 'Open',
                rateQuoteID = '', rateTerms = '', lockedSide = 'Buy', status = '',
                updateToken = '', depositCurrency = '', farLegUpdateToken = '',
                forwardValueDate = '', forwardMaturityDate = '';

            var isAdjustmentDeal = false, requireDeposits = false, isSameCurrencyDealRefund = false;

            var dealFees;
            buyAmount = objDealHeader.BuyAmount;
            buyCurrency = objDealHeader.BuyCurrency;
            buyCurrencyDecimals = this.getNumberOfDecimals(objDealHeader.BuyCurrency)
            if (objDealHeader.CoverCustomerID !== undefined) {
                coverCustomerID = objDealHeader.CoverCustomerID;
            }
            if (objDealHeader.CurrentMarketRate !== undefined) {
                currentMarketRate = objDealHeader.CurrentMarketRate;
            }
            customerRate = objDealHeader.CustomerRate;
            if (Number(objDealHeader.DealHeaderID) !== 0) {
                dealFees = objDealHeader.DealFees;
            }
            else {
                this.getDealFees(0);
            }
            dealHeaderID = objDealHeader.DealHeaderID;
            dealType = objDealHeader.DealType;
            if (objDealHeader.Deposits !== null &&
                objDealHeader.Deposits !== undefined &&
                objDealHeader.Deposits.length > 0) {
                depositAmount = objDealHeader.Deposits[0].Amount;
                depositCurrency = objDealHeader.Deposits[0].CurrencyID;
                depositID = objDealHeader.Deposits[0].DepositID;
            }
            depositPercent = objDealHeader.DepositPercent;

            if (objDealHeader.FarLeg !== undefined && objDealHeader.FarLeg !== null && objDealHeader.FarLeg.length > 0) {
                let farLeg = objDealHeader.FarLeg[0]
                farLegBuyAmount = farLeg.BuyAmount;
                farLegCustomerRate = farLeg.CustomerRate;
                farLegDealHeaderID = farLeg.DealHeaderID;
                farLegForwardPoints = farLeg.ForwardPoints;
                farLegMarketRate = farLeg.MarketRate;
                farLegProfit = farLeg.ProfitAmount;
                farLegProfitCcy = 0;
                farLegOriginalMarketSpotRate = farLeg.OriginalMarketSpotRate;
                farLegSellAmount = farLeg.SellAmount;
                farLegSpread = Number(((Number(farLeg.CustomerRate) - Number(farLeg.MarketRate)) * 10000).toFixed(2));
                farLegUpdateToken = farLeg.UpdateToken;
            }

            forwardBuyAmount = objDealHeader.ForwardBuyAmount;
            forwardBuyTotalDisbursed = objDealHeader.ForwardBuyTotalDisbursed;
            forwardCoverDealID = objDealHeader.ForwardCoverDealID;
            forwardMaturityDate = objDealHeader.ForwardMaturityDate;
            forwardProfitAmount = objDealHeader.ForwardProfitAmount;
            forwardProfitAmountDisbursed = objDealHeader.ForwardProfitAmountDisbursed;
            forwardSellAmount = objDealHeader.ForwardSellAmount;
            forwardSellTotalDisbursed = objDealHeader.ForwardSellTotalDisbursed;
            if (Number(objDealHeader.DealType) === 4) {
                forwardDays = this.daysBetweenDates(objDealHeader.ValueDate, objDealHeader.MaturityDate);
            }
            forwardType = objDealHeader.ForwardType;
            forwardValueDate = objDealHeader.ForwardValueDate;
            isAdjustmentDeal = objDealHeader.IsAdjustmentDeal;
            if (objDealHeader.IsSameCurrencyDealRefund !== undefined) {
                isSameCurrencyDealRefund = objDealHeader.IsSameCurrencyDealRefund;
            }
            lockedSide = objDealHeader.LockedSide;
            marketRate = objDealHeader.MarketRate;
            originalMarketRate = objDealHeader.OriginalMarketSpotRate;
            originalCustomerRate = objDealHeader.OriginalCustomerRate;

            if (Number(objDealHeader.DealType) === 6) {
                originalCustomerRate = objDealHeader.OriginalCustomerRate
                originalMarketSpotRate = objDealHeader.OriginalMarketSpotRate
                nearLegCustomerRate = objDealHeader.CustomerRate;
                nearLegForwardPoints = objDealHeader.ForwardPoints;
                nearLegMarketRate = objDealHeader.MarketRate;
                nearLegOriginalMarketSpotRate = objDealHeader.OriginalMarketSpotRate;
                nearLegProfitAmount = objDealHeader.ProfitAmount;
                nearLegProfitCcy = 0;
                nearLegSpread = Number(((Number(objDealHeader.CustomerRate) - Number(objDealHeader.MarketRate)) * 10000).toFixed(2));
            }

            nostroAccountID = objDealHeader.NostroAccountID;
            parentDealHeaderID = objDealHeader.ParentDealHeaderID;
            profitAmount = objDealHeader.ProfitAmount;
            profitAmountCcy = 0;
            quoteNo = objDealHeader.QuoteNo;
            rateQuoteID = objDealHeader.RateQuoteID;
            rateTerms = objDealHeader.RateTerms;
            requireDeposits = objDealHeader.RequireDeposits;
            sellAmount = objDealHeader.SellAmount;
            sellCurrency = objDealHeader.SellCurrency;
            sellCurrencyDecimals = this.getNumberOfDecimals(objDealHeader.SellCurrency)
            status = objDealHeader.Status;
            totalDisbursed = objDealHeader.TotalDisbursed;
            updateToken = objDealHeader.UpdateToken;
            valueDate = objDealHeader.ValueDate;
 
            if (Number(objDealHeader.DealType) === 6 && objDealHeader.FarLeg !== undefined && objDealHeader.FarLeg !== null && objDealHeader.FarLeg.length > 0) {
                let farLeg = objDealHeader.FarLeg[0]
                maturityDate = new Date(farLeg.ValueDate);
                forwardDays = this.daysBetweenDates(valueDate, maturityDate);
            }
            else {
                maturityDate = objDealHeader.MaturityDate;
            }

            //Spread points and percentage
            let profitPoints = Math.abs(customerRate - marketRate);
            if (marketRate !== 0) {
                spreadPercentage = ((profitPoints / marketRate) * 100).toFixed(2);
            }
            var scaleCCYPair = getScaleForCurrencyPair(objDealHeader.BuyCurrency, objDealHeader.SellCurrency);                
            spreadPoints = (profitPoints * 10000).toFixed(scaleCCYPair);

            //Calculate end date
            var startDate = this.state.startDate;
            var endDate = this.state.startDate;
            switch (dealType) {
                case 1:
                    endDate = this.calculateEndDate(this.state.startDate, buyCurrency, sellCurrency);
                    break;
                case 2:
                    endDate = objDealHeader.MaturityDate;
                    break;
                    case 4:
                        endDate = this.getNextBusinessDateForward(this.state.startDateMaturity, this.props.maxForwardWindow);
                        break;
                    case 6: //swap- any date greather than system date (WILLY)              
                        endDate = null;//undefined;
                default:
                    break;
            }

            if (objDealHeader.ForDrawdownCreation) {
                startDate = objDealHeader.ValueDate;
                if (this.props.systemDate > startDate) {
                    startDate = new Date(this.props.systemDate);
                }
                endDate = objDealHeader.MaturityDate;
                valueDate = startDate;
            }

            //Verify that currencies are in lists
            var settlementCurrencies = this.props.settlementCurrencies.slice();
            var paymentCurrencies = this.props.paymentCurrencies.slice();

            var isSettlementCurrencyAvailable = false;
            for (var i = 0; i < settlementCurrencies.length; i++) {
                if (buyCurrency === settlementCurrencies[i].value) {
                    isSettlementCurrencyAvailable = true;
                    break;
                }
            }

            var isPaymentCurrencyAvailable = false;
            for (var j = 0; j < paymentCurrencies.length; j++) {
                if (sellCurrency === paymentCurrencies[j].value) {
                    isPaymentCurrencyAvailable = true;
                    break;
                }
            }

            if (!isSettlementCurrencyAvailable) {
                let currency = this.props.currencyDictionary[buyCurrency];
                var value = '';
                var name = '';
                var numberOfDecimals = 2;
                if (currency !== undefined) {
                    value = currency.CurrencyID;
                    name = currency.Description;
                    numberOfDecimals = currency.NumberOfDecimals;
                }
                else {
                    value = buyCurrency;
                    name = buyCurrency;
                    numberOfDecimals = 2;
                }
                var currencyElement = {
                    value: value,
                    name: name,
                    numberOfDecimals: numberOfDecimals,
                };
                settlementCurrencies.push(currencyElement);
            }

            if (!isPaymentCurrencyAvailable) {
                let currency = this.props.currencyDictionary[sellCurrency];
                var value2 = '';
                var name2 = '';
                var numberOfDecimals2 = 2;
                if (currency !== undefined) {
                    value2 = currency.CurrencyID;
                    name2 = currency.Description;
                    numberOfDecimals2 = currency.NumberOfDecimals;
                }
                else {
                    value2 = sellCurrency;
                    name2 = sellCurrency;
                    numberOfDecimals2 = 2;
                }

                var currencyElement2 = {
                    value: value2,
                    name: name2,
                    numberOfDecimals: numberOfDecimals2,
                };
                paymentCurrencies.push(currencyElement2);
            }

            var depositCurrencies = this.props.settlementCurrencies.slice();
            if (objDealHeader.DealType === 4 && depositCurrency !== '') {
                var isDepositCurrencyAvailable = false;
                for (var k = 0; k < settlementCurrencies.length; k++) {
                    if (depositCurrency === settlementCurrencies[k].value) {
                        isDepositCurrencyAvailable = true;
                        break;
                    }
                }

                if (!isDepositCurrencyAvailable) {
                    let currency = this.props.currencyDictionary[depositCurrency];
                    var value3 = '';
                    var name3 = '';
                    var numberOfDecimals3 = 2;
                    if (currency !== undefined) {
                        value3 = currency.CurrencyID;
                        name3 = currency.Description;
                        numberOfDecimals3 = currency.NumberOfDecimals;
                    }
                    else {
                        value3 = depositCurrency;
                        name3 = depositCurrency;
                        numberOfDecimals3 = 2;
                    }

                    var currencyElement3 = {
                        value: value3,
                        name: name3,
                        numberOfDecimals: numberOfDecimals3,
                    };
                    depositCurrencies.push(currencyElement3);
                }
            }

            var alertMessage = '', alertTitle = '';
            var showAlert = false;
            //Show covered deal warning
            var coverDealID = 0;
            if (Number(objDealHeader.DealHeaderID) > 0
                && Number(objDealHeader.CoverDealID) > 0) {
                alertTitle = 'Warning';
                alertMessage += 'Please notice that this deal is covered by ' + objDealHeader.CoverDealID + '. ';
                showAlert = true;
                coverDealID = objDealHeader.CoverDealID;
            }

            //Show cover deal warning
            var coveredDeals = '';
            if (objDealHeader.CoveredDeals != null && objDealHeader.CoveredDeals !== '') {
                alertTitle = 'Warning';
                alertMessage += 'Please notice that this deal covers these deal(s): ' + objDealHeader.CoveredDeals + '. ';
                showAlert = true;
                coveredDeals = objDealHeader.CoveredDeals;
            }

            //Get rate precision
            let ratePrecision = this.getRatePrecision(sellCurrency, buyCurrency);
            let limit = this.getRateLimit(sellCurrency, buyCurrency);

            //Warning for same currency deal refund
            if (isSameCurrencyDealRefund) {
                alertMessage += 'Please notice that this is a same currency deal refund. ';
                alertTitle = 'Warning';
                showAlert = true;
            }
            
            let dealAlreadyCovered = Number(this.props.objDealHeader.CoverCustomerID)
            let coverInterfaceTradeNumber = this.props.objDealHeader.CoverCustomerID || ""
            if (dealAlreadyCovered > 0 && coverInterfaceTradeNumber !== "") {
                dealAlreadyCoveredInInterface = true;
            }
            this.setState({
                isCloseout: false,
                buyAmountEnableUpdatesTrigger: false,
                buyCurrencyEnableUpdatesTrigger: false,
                customerRateEnableUpdatesTrigger: false,
                depositCurrencyEnableUpdatesTrigger: false,
                farLegEnableControlUpdates: false,
                marketRateEnableUpdatesTrigger: false,
                nearLegEnableControlUpdates: false,
                sellAmountEnableUpdatesTrigger: false,
                sellCurrencyEnableUpdatesTrigger: false,
                forwardDaysEnableUpdatesTrigger: false,

                alertMessage: alertMessage,
                alertTitle: alertTitle,
                buyAmount: buyAmount,
                buyCurrency: buyCurrency,
                buyCurrencyDecimals: buyCurrencyDecimals,
                coverCustomerID: coverCustomerID,
                coverDealID: coverDealID,
                coveredDeals: coveredDeals,
                currentMarketRate: currentMarketRate,
                customerRate: customerRate,
                dealFeeList: dealFees,
                dealHeaderID: dealHeaderID,
                dealsToCover: [],
                dealType: dealType,
                depositID: depositID,
                depositAmount: depositAmount,
                depositCurrencies: depositCurrencies,
                depositCurrency: depositCurrency,
                depositPercent: depositPercent,

                farLegBuyAmount: farLegBuyAmount,
                farLegCustomerRate: farLegCustomerRate,
                farLegDealHeaderID: farLegDealHeaderID,
                farLegForwardPoints: farLegForwardPoints,
                farLegMarketRate: farLegMarketRate,
                farLegOriginalMarketSpotRate: farLegOriginalMarketSpotRate,
                farLegProfit: farLegProfit,
                farLegProfitCcy: farLegProfitCcy,
                farLegSellAmount: farLegSellAmount,
                farLegSpread: farLegSpread,
                farLegUpdateToken: farLegUpdateToken,

                forwardBuyAmount: forwardBuyAmount,
                forwardBuyTotalDisbursed: forwardBuyTotalDisbursed,
                forwardCoverDealID: forwardCoverDealID,
                forwardDays: forwardDays,
                forwardMaturityDate: forwardMaturityDate,
                forwardProfitAmount: forwardProfitAmount,
                forwardProfitAmountDisbursed: forwardProfitAmountDisbursed,
                forwardSellAmount: forwardSellAmount,
                forwardSellTotalDisbursed: forwardSellTotalDisbursed,
                forwardType: forwardType,
                forwardValueDate: forwardValueDate,
                isAdjustmentDeal: isAdjustmentDeal,
                isSameCurrencyDealRefund: isSameCurrencyDealRefund,
                lockedSide: lockedSide,
                marketRate: marketRate,
                originalMarketRate: originalMarketRate,
                maturityDate: maturityDate,

                originalCustomerRate: originalCustomerRate,
                originalMarketSpotRate: originalMarketSpotRate,
                nearLegCustomerRate: nearLegCustomerRate,
                nearLegForwardPoints: nearLegForwardPoints,
                nearLegMarketRate: nearLegMarketRate,
                nearLegOriginalMarketSpotRate: nearLegOriginalMarketSpotRate,
                nearLegProfit: nearLegProfitAmount,
                nearLegProfitCcy: nearLegProfitCcy,
                nearLegSpread: nearLegSpread,

                nostroAccountID: nostroAccountID,
                originalBuyAmount: buyAmount,
                originalProfit: profitAmount,
                originalSellAmount: sellAmount,
                paymentCurrencies: paymentCurrencies,
                parentDealHeaderID: parentDealHeaderID,
                profitAmount: profitAmount,
                profitAmountCcy: profitAmountCcy,
                quoteNo: quoteNo,
                ratePrecision: ratePrecision,
                rateQuoteID: rateQuoteID,
                rateTerms: rateTerms,
                requireDeposits: requireDeposits,
                sellAmount: sellAmount,
                sellCurrency: sellCurrency,
                sellCurrencyDecimals: sellCurrencyDecimals,
                settlementCurrencies: settlementCurrencies,
                showAlert: showAlert,
                spreadPercentage: spreadPercentage,
                spreadPoints: spreadPoints,
                startDate: startDate,
                startDateMaturity: startDate,
                status: status,
                totalDisbursed: totalDisbursed,
                updateToken: updateToken,
                valueDate: valueDate,
                endDate: endDate,
                limit: limit,
                dealAlreadyCoveredInInterface: dealAlreadyCoveredInInterface,
                dealIdToLink: objDealHeader !== undefined && objDealHeader.CustomerDealID !== undefined ? Number(objDealHeader.CustomerDealID) : 0,
                editingDeal: false
            }, () => {
                this.setState({
                    buyAmountEnableUpdatesTrigger: true,
                    buyCurrencyEnableUpdatesTrigger: true,
                    farLegEnableControlUpdates: true,
                    sellAmountEnableUpdatesTrigger: true,
                    sellCurrencyEnableUpdatesTrigger: true,
                    marketRateEnableUpdatesTrigger: true,
                    nearLegEnableControlUpdates: true,
                    customerRateEnableUpdatesTrigger: true,
                    depositCurrencyEnableUpdatesTrigger: true,
                    forwardDaysEnableUpdatesTrigger: true
                });
            });
        } 

        let coverInterfaceTradeNumber = ''
        if (this.props.objDealHeader !== undefined) {
            coverInterfaceTradeNumber = this.props.objDealHeader.CoverInterfaceTradeNumber || ''
        }
        
        if (this.props.showAlert != null && prevProps.showAlert !== this.props.showAlert && coverInterfaceTradeNumber.trim() === "") {
            this.setState({
                //SHOW ADDITIONAL INFORMATION
                addInfo: true
            }, () => this.props.closeSaveDealSuccess())
        }
    }

    handleNewCustomer = (event) => {
        //event.preventDefault();
        this.props.handleSelectCustomerTab();
    }

    handleBackClick = (event) => {
        //event.preventDefault();
        this.props.handleSelectContactTab();
    }

    handleNewDealClick = (event) =>{       
        
       /* let smsValidation = false;
        let phoneNoValidated = true;
        debugger;
        getEntityProperties(this.state.customerID, 'Customer').then(response => {
            let property = response.entityProperties.find(x => x.Description === 'Ask SMS Confirmation');
            if(property !== undefined && property !== null) {
                if(property.TextValue === 'Yes') { smsValidation = true; phoneNoValidated = false; }
                this.setState({ useSMSValidation: smsValidation, phoneValidated: phoneNoValidated });
            }
        })*/

        var dealFeeList = this.state.dealFeeList;
        for(var i=0; i<dealFeeList.length; i++){
            dealFeeList[i].FeeAmount = 0;
        }

        //Customer defaults
        var valueDate = '';
        var sellCurrency = '';
        var sellCurrencyDecimals = 2;
        if(this.props.objCustomer['DefaultSellCurrencyID$'] !== ''){
            sellCurrency = this.props.objCustomer['DefaultSellCurrencyID$'];
            sellCurrencyDecimals = this.getNumberOfDecimals(this.props.objCustomer['DefaultSellCurrencyID$']);
        }

        
        var buyCurrency = '', depositCurrency = '';
        var buyCurrencyDecimals = 2;
        if(this.props.objCustomer['DefaultBuyCurrencyID$'] !== ''){
            buyCurrency = depositCurrency = this.props.objCustomer['DefaultBuyCurrencyID$'];
            buyCurrencyDecimals = this.getNumberOfDecimals(this.props.objCustomer['DefaultBuyCurrencyID$']);
        }

        //Deal type
        var dealType = this.state.dealType;
        if(dealType === 2 || dealType === 11){
            dealType = 1;
        }

        //Dates
        let cutoffTime = localStorage.getItem('CutoffTime');
        if(cutoffTime === null){
            cutoffTime = '12:00';
        }

        var currentDate = new Date();
        var startDate = new Date(this.props.systemDate);
        startDate.setHours(currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds(), 0);
        var endDate = new Date(this.props.systemDate);
        if(getTimehhmm(startDate) > cutoffTime){
            startDate.setDate(startDate.getDate() + 1);
        }

        switch(dealType){
            case 1:
            default:
                endDate = this.calculateEndDate(startDate, buyCurrency, sellCurrency);
                if(buyCurrency !== undefined && sellCurrency !== undefined &&
                    buyCurrency !== '' && sellCurrency !== '')
                {
                    valueDate = this.setValueDateOnLoad(startDate, buyCurrency, sellCurrency);
                }
                break;
            case 4:
                if(buyCurrency !== undefined && sellCurrency !== undefined &&
                    buyCurrency !== '' && sellCurrency !== '')
                {
                    valueDate = this.setValueDateOnLoad(startDate, buyCurrency, sellCurrency);
                }
                endDate = this.getNextBusinessDateForward(startDate, this.props.maxForwardWindow);
                    break;
            case 6://swap- any date greather than system date (WILLY)        
                // endDate = '';
                endDate = null//undefined;//this.getNextBusinessDate(startDate, this.props.maxForwardWindow, buyCurrency, sellCurrency);
                //valueDate = this.setValueDateOnLoad(startDate, buyCurrency, sellCurrency);
                valueDate = new Date(this.props.systemDate)
                break;
        }
        
        var coverCustomerID = this.state.coverCustomerID;
        if(this.props.objCustomer['Customer Type'] !== 'Bank'){
            coverCustomerID = 0;
        }

        //Get rate precision
        let ratePrecision = this.getRatePrecision(sellCurrency, buyCurrency);
        let limit = this.getRateLimit(sellCurrency, buyCurrency);

        let phoneNoValidated = true;
        if(this.state.useSMSValidation)
        {
            phoneNoValidated = false;
        }

        this.setState({buyAmountEnableUpdatesTrigger: false,
            buyCurrencyEnableUpdatesTrigger: false,
            farLegEnableControlUpdates: false,
            sellAmountEnableUpdatesTrigger: false,
            sellCurrencyEnableUpdatesTrigger: false,
            depositAmountEnableUpdatesTrigger: false,
            depositPercentageEnableUpdatesTrigger: false,
            depositCurrencyEnableUpdatesTrigger: false,
            customerRateEnableUpdatesTrigger: false,
            marketRateEnableUpdatesTrigger: false,
            dealAlreadyCoveredInInterface: false,
            nearLegEnableControlUpdates: false,
            phoneValidated: phoneNoValidated
        }
            ,()=>{                
        this.setState({
            buyAmount: 0,
            buyCurrency: buyCurrency,
            buyCurrencyDecimals: buyCurrencyDecimals,
            coverCustomerID: coverCustomerID,
            coverDealID: 0,
            coveredDeals: '',
            currentMarketRate: 0,
            customerName: '',
            customerRate: 0, 
            dealFeeList: dealFeeList,
            dealHeaderID: 0,
            dealsToCover: [],
            dealType: dealType,
            depositID: 0,
            depositAmount: 0,
            depositCurrency: depositCurrency,
            depositCurrencies: this.props.settlementCurrencies,
            depositPercent: this.props.objCustomer['DefaultDepositPercent$'],
            endDate: endDate,
            externalInterface: '',
            externalInterfaceType: '',
            farLegBuyAmount: 0,
            farLegCustomerRate: 0,
            farLegDealHeaderID:0,
            farLegForwardPoints: 0,
            farLegMarketRate: 0,
            farLegOriginalMarketSpotRate: 0,
            farLegProfit: 0,
            farLegProfitCcy: 0,
            farLegSellAmount: 0,
            farLegSpread: 0,
            forwardBuyTotalDisbursed: 0,
            forwardDays: 0,
            forwardType: 'Open',
            isAdjustmentDeal: false,
            lockedSide: 'Sell',
            marketRate: 0,
            maturityDate: '',
            nearLegCustomerRate: 0,
            nearLegForwardPoints: 0,
            nearLegMarketRate: 0,
            nearLegOriginalMarketSpotRate: 0,
            nearLegProfit: 0,
            nearLegProfitCcy: 0,
            nearLegSpread: 0,
            originalBuyAmount: 0,
            originalProfit: 0,
            originalSellAmount: 0,
            parentDealHeaderID: 0,
            paymentCurrencies: this.props.paymentCurrencies,
            profitAmount: 0,
            profitAmountCcy : 0,
            quoteNo: 0,
            ratePrecision: ratePrecision,
            rateQuoteID: '',
            rateTerms: '',
            requireDeposits: false,
            sellAmount: 0,
            sellCurrency: sellCurrency,
            sellCurrencyDecimals: sellCurrencyDecimals,
            settlementCurrencies: this.props.settlementCurrencies,
            spreadPercentage: 0,
            spreadPoints: 0,
            startDate: startDate,
            startDateMaturity: startDate,
            status: '',
            totalDisbursed: 0,
            totalFees: 0,
            useInterface: false,
            valueDate: valueDate,
            disableSupportingControls: false,
            limit: limit
        }, () => { 
            if (this.state.buyCurrency !== undefined && this.state.sellCurrency !== undefined && this.state.buyCurrency !== '' && this.state.sellCurrency !== '') {
                this.getRate(true);
            }
            this.setState({buyAmountEnableUpdatesTrigger: true,
                buyCurrencyEnableUpdatesTrigger: true,
                farLegEnableControlUpdates: true,
                sellAmountEnableUpdatesTrigger: true,
                sellCurrencyEnableUpdatesTrigger: true,
                depositAmountEnableUpdatesTrigger: true,
                depositPercentageEnableUpdatesTrigger: true,
                depositCurrencyEnableUpdatesTrigger: true,
                nearLegEnableControlUpdates: true,
                customerRateEnableUpdatesTrigger: 0,
            marketRateEnableUpdatesTrigger: 0
            });
        });
    });

        this.getDealFees(0);
        //this.props.onClearDisbursements();
        let objDealHeader = {DealHeaderID: 0, QuoteNo: 0, OutgoingFunds:[]};
        this.props.onUpdateDealHeader(objDealHeader);

        this.enforceDisable(false);
        this.enforceDisableSupportingControls(false);
    }

    handleDuplicateClick = (event) =>{
        event.preventDefault();
       /* let smsValidation = false;
        let phoneNoValidated = true;
        debugger;
        getEntityProperties(this.state.customerID, 'Customer').then(response => {
            let property = response.entityProperties.find(x => x.Description === 'Ask SMS Confirmation');
            if(property !== undefined && property !== null) {
                if(property.TextValue === 'Yes') { smsValidation = true; phoneNoValidated = false; }
                this.setState({ useSMSValidation: smsValidation, phoneValidated: phoneNoValidated });
            }
        })*/
        let phoneNoValidated = true;
        if(this.state.useSMSValidation)
        {
            phoneNoValidated = false;
        }
        this.setState({
            dealHeaderID: 0,
            depositID: 0,
            rateQuoteID: '',
            status: '',
            updateToken: '',
            showReverseButton: false,
            disableSupportingControls: false,
            dealAlreadyCoveredInInterface: false,
            phoneValidated:phoneNoValidated
        });
        let objDealHeader = {DealHeaderID: 0, QuoteID: 0};
        this.enforceDisable(false);
        this.enforceDisableSupportingControls(false);
        this.props.onUpdateDealHeader(objDealHeader)
        this.props.onClearDisbursements();
        this.getRate(true);
    }

    enforceDisableSupportingControls(disable){
        return <WizardSupportingRates id="wizard-supporting-rates"
            buyCurrency={this.state.buyCurrency}
            dealType={this.state.dealType}
            maturityDate={this.state.maturityDate}
            rateQuoteID={this.state.rateQuoteID}
            sellCurrency={this.state.sellCurrency}
            valueDate={this.state.valueDate}
            customerRate={this.state.customerRate}
            marketRate={this.state.marketRate}
            nearLegOriginalMarketSpotRate ={this.state.nearLegOriginalMarketSpotRate}
            nearLegMarketRate ={this.state.nearLegMarketRate}
            farLegOriginalMarketSpotRate ={this.state.farLegOriginalMarketSpotRate}
            farLegMarketRate ={this.state.farLegMarketRate}
            nearLegCustomerRate={this.state.nearLegCustomerRate}
            farLegCustomerRate={this.state.farLegCustomerRate}
            supportingRates={this.state.supportingRates}
            updateLocalSupportingRates={this.updateLocalSupportingRates}
            disableControls={disable}
            onSaveClick={this.onSaveSupportingRatesClick}
            onCloseClick={this.onCloseSupportingRatesClick} 
        />
    }

    handleSaveQuoteClick = (event) => {
        if(this.state.rateQuoteID === ''){
            this.showAlert('Error', 'You must request a quote first!');
        }
        else{
            var maturityDate = null;
            if(this.state.dealType === 4){
                maturityDate = formatDate(this.state.maturityDate);
            }
            var objQuote = {DealType: this.state.dealType,
                DealOrigin: 'Retail',
                CustomerID: this.props.objCustomer["Customer ID"],
                ValueDate: formatDate(this.state.valueDate),
                MaturityDate: maturityDate,
                BuyCurrency: this.state.buyCurrency,
                SellCurrency: this.state.sellCurrency,
                MarketRate: this.state.marketRate,
                ForwardPoints: this.state.spreadPoints,
                CustomerRate: this.state.customerRate,
                ProfitAmount: this.state.profitAmount,
                Status: 'Active',
                BuyAmount: this.state.buyAmount,
                SellAmount: this.state.sellAmount,
                Comments: '',
                InputBy: localStorage.getItem('UserID'),
                ForwardType: this.state.forwardType,
                CreatedBy: localStorage.getItem('UserID'),
                IsRateDirect: this.state.rateTerms === 'D',
                UpdateBuyAmountField: this.state.lockedSide === 'Sell',
                CashIndicator: false,
                RateQuoteID: this.state.rateQuoteID,
            };

            saveQuote(objQuote).then(
                (json) => {
                    if(Number(json.httpStatusCode) === 200){
                        var quotesRefreshFlag = this.state.quotesRefreshFlag;
                        quotesRefreshFlag++;
                        this.setState({rateQuoteID: '',
                            quotesRefreshFlag: quotesRefreshFlag,
                        });
                        this.showAlert('Success', 'Quote saved!');
                    }
                    else{
                        this.showAlert('Error', 'Something went wrong. Please review logs.');
                    }
                    
                }
            );
        }
    }

    showAlert(title, message){
        this.setState({alertTitle: title,
            alertMessage: message,
            showAlert: true,
        });
    }

    validateNextClick(){
        var result = true;
        var validations = this.state.validations;

        if(this.state.buyAmount == 0 || this.state.buyAmount == null)
        {
            validations.buyAmount = false;
            this.setState({validations: validations})
            result = false;
        }

        if(this.state.sellAmount == 0 || this.state.sellAmount == null)
        {
            validations.sellAmount = false;
            this.setState({validations: validations})
            result = false;
        }

        if (this.state.dealType == 6 && (this.state.farLegSellAmount == 0 || this.state.farLegSellAmount == null)) {
            validations.farLegSellAmount = false;
            this.setState({ validations: validations })
            result = false;
        }

        if (this.state.dealType == 6 && (this.state.farLegBuyAmount == 0 || this.state.farLegBuyAmount == null)) {
            validations.farLegBuyAmount = false;
            this.setState({ validations: validations })
            result = false;
        }

        if (this.state.dealType == 4 || this.state.dealType == 6) {
            if (this.state.valueDate > this.state.maturityDate) {
                validations.valueDate = false;
                validations.maturityDate = false;
                this.setState({ validations: validations });
                result = false;
            }
        }

        //Rate quote
        if(this.state.rateQuoteID === ''){
            if(this.state.editForwardDate && this.props.objDealHeader != undefined && this.props.objDealHeader.DealHeaderID > 0)
            {
                result = true;
            }
            else
            {
            result = false;
            this.showAlert('Error', 'You must request a quote first!');
            }
        }

        //Deposit currency        
        validations.depositCurrency = true;
        if(this.state.dealType === 4 &&
            this.state.requireDeposits &&
            (['', 'select'].includes(this.state.depositCurrency))){
            validations.depositCurrency = false;
            result = false;
        }
        this.setState({ validations: validations  });

        //Update forwards validation
        if(this.state.dealType === 4 && this.state.forwardType === 'Open' &&
            this.state.totalDisbursed > 0 && !this.state.editForwardDate){
                this.showAlert('Error', 'Forward has drawdowns attached to it! If you want to update any information please void drawdowns first.');
                result = false;
        }

        return result;
    }

    readDealHeader = () => {             
        var incomingFunds = [];
        var deposits = [];
        var internalComments = '', externalComments = '', customerRefNo = '',
        updateToken = '', status = '';
        if(Number(this.state.dealHeaderID) !== 0){
            deposits = this.props.objDealHeader.Deposits;
            incomingFunds = this.props.objDealHeader.IncomingFunds;
            internalComments = this.props.objDealHeader.InternalComments;
            externalComments = this.props.objDealHeader.ExternalComments;
            updateToken = this.props.objDealHeader.UpdateToken;
            status = this.props.objDealHeader.Status;
            customerRefNo = this.props.objDealHeader.CustomerRefNo;

        }

        var depositPercent = 0, depositAmount = 0;
        if(this.state.requireDeposits){
            depositPercent = this.state.depositPercent;
            depositAmount = this.state.depositAmount;
        }

        var maturityDate = this.state.maturityDate;
        var swapFarLegDealHeader = null;
        var swapNearLegDealHeader = null;
        if(this.state.dealType === 6){
            swapNearLegDealHeader = {
                CustomerRate: this.state.nearLegCustomerRate,
                ForwardPoints: this.state.nearLegForwardPoints,
                MarketRate: this.state.nearLegMarketRate,
                OriginalMarketSpotRate: this.state.nearLegOriginalMarketSpotRate,
                ProfitAmount: this.state.nearLegProfit,
                Spread: this.state.nearLegSpread,
                ValueDate: this.state.valueDate,
            };
            swapFarLegDealHeader = {
                BuyAmount: this.state.farLegBuyAmount,
                CustomerRate: this.state.farLegCustomerRate,
                DealHeaderID: this.state.farLegDealHeaderID,
                ForwardPoints: this.state.farLegForwardPoints,
                MarketRate: this.state.farLegMarketRate,
                OriginalMarketSpotRate: this.state.farLegOriginalMarketSpotRate,
                ProfitAmount: this.state.farLegProfit,
                SellAmount: this.state.farLegSellAmount,
                Spread: this.state.farLegSpread,
                UpdateToken: this.state.farLegUpdateToken,
                ValueDate: this.state.maturityDate,
            };
            maturityDate = this.state.valueDate;
        }
        var externalInterface = '', externalInterfaceType = '';
        if(this.state.useInterface){
            externalInterface = this.state.externalInterface;
            externalInterfaceType = this.state.externalInterfaceType;
        }
        let bankReferenceForCoverDeal = false;
        if ('AutoCover' === this.state.externalInterfaceType) {
            bankReferenceForCoverDeal = true;
        }

        let sellAmountNumber = Number(this.state.sellAmount);

        let objDealHeader = {
            BuyAmount: this.state.buyAmount,
            BuyCurrency: this.state.buyCurrency,
            CoverCustomerID: this.state.coverCustomerID,
            CoverDealID: this.state.coverDealID,
            CoveredDeals: this.state.coveredDeals,
            CustomerContactID: this.state.customerContactID,
            CustomerID: this.state.customerID,
            CustomerRate: this.state.customerRate,
            CustomerRefNo: customerRefNo,
            //CustomerRefNo: this.props.objDealHeader.CustomerRefNo,
            //CustomerRefNo:'',
            DealFees: this.state.dealFeeList,
            DealHeaderID: this.state.dealHeaderID,
            DealOrigin: 'Retail',
            DealPaidStatus: this.props.objDealHeader.DealPaidStatus,
            DealsToCover: this.state.dealsToCover,
            DealType: this.state.dealType,
            DepositAmount: depositAmount,
            DepositCurrency: this.state.depositCurrency,
            DepositID: this.state.depositID,
            DepositPercent: depositPercent,
            Deposits: deposits,
            ExternalComments: externalComments,
            ExternalInterface: externalInterface,
            ExternalInterfaceType: externalInterfaceType,
            ForwardBuyAmount: this.state.forwardBuyAmount,
            ForwardBuyTotalDisbursed: this.state.forwardBuyTotalDisbursed,
            ForwardMaturityDate: this.state.forwardMaturityDate,
            ForwardProfitAmount: this.state.forwardProfitAmount,
            ForwardProfitAmountDisbursed: this.state.forwardProfitAmountDisbursed,
            ForwardSellAmount: this.state.forwardSellAmount,
            ForwardSellTotalDisbursed: this.state.forwardSellTotalDisbursed,
            ForwardType: this.state.forwardType,
            ForwardValueDate: this.state.forwardValueDate,
            IncomingFunds: incomingFunds,
            InternalComments: internalComments,
            IsSameCurrencyDealRefund: this.state.isSameCurrencyDealRefund,
            LinkQuoteNo: this.state.quoteNo,
            LockedSide: this.state.lockedSide,
            MarketRate: this.state.marketRate,
            MaturityDate: maturityDate,
            NostroAccountID: this.state.nostroAccountID,
            OriginalBuyAmount: this.state.originalBuyAmount,
            OriginalProfit: this.state.originalProfit,
            OriginalSellAmount: this.state.originalSellAmount,
            ParentDealHeaderID: this.state.parentDealHeaderID,
            ProfitAmount: this.state.profitAmount,
            RateQuoteID: this.state.rateQuoteID,
            RateTerms: this.state.rateTerms,
            RequireDeposits: this.state.requireDeposits,
            SellAmount: this.state.sellAmount,
            SellCurrency: this.state.sellCurrency,
            SpreadPercentage: this.state.spreadPercentage,
            SpreadPoints: this.state.spreadPoints,
            Status: status,
            SwapNearLegDealHeader: swapNearLegDealHeader,
            SwapFarLegDealHeader: swapFarLegDealHeader,
            TotalDisbursed: this.props.objDealHeader.TotalDisbursed,
            TotalFees: this.state.totalFees,
            TotalPaid: this.props.objDealHeader.TotalPaid,
            UpdateBuyAmountField: this.state.lockedSide === 'Sell',
            UpdateToken: updateToken,
            UseInterface: this.state.useInterface,
            ValueDate: this.state.valueDate,
            BankReferenceForCoverDeal: bankReferenceForCoverDeal,
            JsonProperties: this.state.jsonProperties,
            DepositsBalance: this.state.depositsBalance,
            DepositsBalanceInfo: this.state.depositsBalanceInfo,
            LastDrawDown: this.props.objDealHeader.ForwardSellAmount === (sellAmountNumber + this.props.objDealHeader.TotalDisbursed)
        }

        return objDealHeader;
    }
    
    handleNextClick = (event) => {
        if(this.validateNextClick()){
            let objDealHeader = this.readDealHeader();
            /*if(this.state.editForwardDate && this.props.objDealHeader != undefined && this.props.objDealHeader.DealHeaderID > 0)
            {
                this.props.onUpdateForwardDate(this.props.objDealHeader, this.state.maturityDate, false);
                this.props.onNextStepClicked(this.props.objDealHeader);
            }
            else
            {*/
            this.props.onNextStepClicked(objDealHeader);
            //}
        }
    }

    handleBookNowClick = (confirmedByClient) => {
        //BOOK NOW AFTER CLICK      
        debugger
        if(this.state.useSMSValidation && !this.state.phoneValidated) {
            //if(this.props.objContact['Mobile Phone'] !== undefined && this.props.objContact['Mobile Phone'] !== null && this.props.objContact['Mobile Phone'] !== '') {
            if(this.state.contactMobile !== undefined && this.state.contactMobile !== null && this.state.contactMobile !== '') {
                let validationMessage = '';
                validationMessage = this.props.objContact['Name'] + ' - ****' + this.state.contactMobile.substring(this.state.contactMobile.length - 5);
                this.setState({ showValidationSMSCode: true, titleValidationSMSCode: 'Validation Code', messageValidationSMSCode: validationMessage, confirmedByClient: confirmedByClient });
            } else {
                this.showAlert('Warning', 'Selected customer contact does not have a mobile number registered.');
            }            
        } 
        else {
            if (this.validateNextClick()) {
                /*if(this.state.editForwardDate && this.props.objDealHeader != undefined && this.props.objDealHeader.DealHeaderID > 0)
                {
                    this.props.onUpdateForwardDate(this.props.objDealHeader, this.state.maturityDate);
                    this.enforceDisable(true);
                    this.setState({showReverseButton: false});
                }
                else
                {*/
    
                var objDealHeader = this.readDealHeader();
                objDealHeader.Status = 'Accepted';
                objDealHeader.ConfirmedByClient = confirmedByClient;
                
                if (this.state.dealIdToLink > 0) {
                    objDealHeader.EntityProperties = [
                        {
                            'PropertyDescription': 'Customer deal ID',
                            "Type": "Numeric",
                            "PropertyValue": this.state.dealIdToLink,
                            "UpdateToken": 0,
                            "EntityType": "Deal",
                            "CreatedBy": objDealHeader.CreatedBy
                        }
                    ]
                }
                console.log('onBookNowClicked ', objDealHeader)
                this.props.onBookNowClicked(objDealHeader, true,
                    () => {
                        this.setState({ dealsRefreshFlag: this.state.dealsRefreshFlag + 1, editSupportingRates: false })
                        this.enforceDisable(true);
                        this.setState({ showReverseButton: false });
                    });                
                this.setState({ editingDeal: false })
                //}
            }
        }
    }

    closeAddInformation = (value) => {
        
        this.setState({ addInfo: false })
        if (value) {
            var objDealHeader = this.readDealHeader();
            objDealHeader.CustomerRefNo = this.state.addInfoObj != null ? this.state.addInfoObj.bankReference : '';
            objDealHeader.InternalComments = this.state.addInfoObj != null ? this.state.addInfoObj.internalComments : '';
            objDealHeader.ExternalComments = this.state.addInfoObj != null ? this.state.addInfoObj.clientComments : '';
            this.props.onUpdateDealHeader(objDealHeader)
            this.props.onBookNowClicked(objDealHeader, null)
        }
    }
    addInformationObj = (obj, value) => {
        this.setState({ addInfoObj: obj }, () => this.closeAddInformation(value))
    }

    handleSaveDealInfo = (obj) => {
        let model = {
            DealHeaderId: obj.DealHeaderID,
            InternalComment: obj.internalComments,
            ExternalComment: obj.clientComments,
            UpdateToken: obj.UpdateToken,
            CustomerRefNo: obj.bankReference
        }
        updateDealHeader(model).then(
            (json) => {
                var alertMessage = '', alertTitle = '';
                switch (json.httpStatusCode) {
                    case 200:
                        {
                            alertTitle = 'Success';
                            alertMessage = 'Deal ' + obj.DealHeaderID + ' has been saved';
                            var objDealHeader = this.readDealHeader();
                            objDealHeader.CustomerRefNo = obj != null ? obj.bankReference : '';
                            objDealHeader.InternalComments = obj != null ? obj.internalComments : '';
                            objDealHeader.ExternalComments = obj != null ? obj.clientComments : '';
                            objDealHeader.UpdateToken = json.updateToken;
                            
                            this.setState({
                                alertTitle: alertTitle,
                                alertMessage: alertMessage,
                                showAlert: true,
                                loading: false,
                            }, () => {
                                this.props.onUpdateDealHeader(objDealHeader);
                            });
                            break;
                        }
                    default:
                        {
                            alertMessage = 'Deal not saved! Please try again or contact administrator.';
                            alertTitle = 'Error';

                             if (json.Message !== undefined) {
                                 switch (json.Message) {
                                     case 'concurrency':
                                         {
                                             alertMessage = 'Please reload the information and try again. Otherwise contact your supervisor';
                                             break;
                                         }
                                     default:
                                         {
                                             alertMessage = alertMessage + ' (' + json.Message + ')';
                                             break;
                                         }
                                 }
                             } else {
                                 if (json.httpErrorMessage !== undefined) {
                                     alertTitle = 'Error';
                                     alertMessage = json.httpErrorMessage;
                                 }
                             }
                             this.setState({
                                alertTitle: alertTitle,
                                alertMessage: alertMessage,
                                showAlert: true,
                                loading: false,
                            });
                             break;
                         }
                 }
            }
        );
    }

    handleUncoveredDealsClick = () => {
        this.props.onUncoveredDealsClick();
    }

    onSupportingRatesClick = () => {
        this.setState({rightViewMode: 'supportingRates'});
    }

    handlePrintPDFClick = (event) => {
        event.preventDefault();
        this.props.getConfirmation(this.state.dealHeaderID);
    }

    handleSendInwardClick = (event) => {
        event.preventDefault();
        this.setState({ showDealConfirmationForm: true });
        /*const model = {
            DealHeaderID: this.state.dealHeaderID,
            Language    : 'English',
            DealMailInfo: [{ 
                MainInfo    : this.props.objContact['Online Confirmation Email'],
                DocType     : 'InwardConfirmation',
                MobilePhone : this.state.contactMobile
            }],
            SendTrader  : true
        };

        sendInwardReportByEmail(model).then(
            (response) => {
                debugger;
                switch (response.httpStatusCode) {
                    case 200: {
                        this.setState({ showAlert: true, alertMessage: 'Emails successfully sent.', alertTitle: 'Success' });
                        break;
                    }
                    default: {
                        switch (response.Message){
                            case 'There is no settlement information to send the inward instructions.': {
                                this.setState({ alertMessage: response.Message, showAlert: true, alertTitle: 'Error' });
                                break;
                            }
                            case 'Deal status must be on Accepted.': {
                                this.setState({ alertMessage: response.Message, showAlert: true, alertTitle: 'Error' });
                                break;
                            }
                            default: {
                                this.setState({ alertMessage: 'There was an error sending the email. Please try again.', showAlert: true, alertTitle: 'Error' });
                                break;
                            }
                        }
                        break;
                    }
                }
            }
        )*/
    }

    handleEditClick = () => {
        
        let dealAlreadyCoveredInInterface = false
        if (this.props.objDealHeader !== undefined) {
            let dealAlreadyCovered = Number(this.props.objDealHeader.CoverCustomerID)
            let coverInterfaceTradeNumber = this.props.objDealHeader.CoverCustomerID || ""
            if (dealAlreadyCovered > 0 && coverInterfaceTradeNumber !== "") {
                dealAlreadyCoveredInInterface = true;
            }
        }

        var date = this.state.valueDate;
        if (this.state.dealType === 4) {
            date = this.state.maturityDate;
        }

        console.log("***DEBUG EDIT***")
        if (this.state.dealHeaderID > 0 && this.state.status !== 'Voided' && date >= this.props.systemDate) {

            var tempdeal = {
                AdjustmentDealID: this.state.adjustmentDealID,
                BuyAmount: this.state.buyAmount, BuyCurrency: this.state.buyCurrency,
                SellAmount: this.state.sellAmount, SellCurrency: this.state.sellCurrency,
                LockedSide: this.state.lockedSide, DealType: this.state.dealType,
                ValueDate: this.state.valueDate, MarketRate: this.state.marketRate,
                CoverCustomerID: this.state.coverDealID, UseInterface: this.state.useInterface,
                CustomerRate: this.state.customerRate, SpreadPoints: this.state.SpreadPoints,
                SpreadPercentage: this.state.spreadPercentage, ProfitAmount: this.state.profitAmount,
                TotalFees: this.state.totalFees
            }
            let endDate = ''
            if (this.state.dealType !== 4 && this.state.dealType !== 6) {
                endDate = this.calculateEndDate(this.state.startDate, this.state.buyCurrency, this.state.sellCurrency);
            } else {
                if (this.state.dealType === 4) {
                    endDate = this.getNextBusinessDateForward(this.state.startDateMaturity, this.props.maxForwardWindow);
                }
                else {   //swap- any date greather than system date (WILLY) 
                    endDate = null;//undefined;//this.getNextBusinessDate(this.state.startDate, this.props.maxForwardWindow, this.state.buyCurrency, this.state.sellCurrency);
                }
            }
            
            let enableDepositControls = false;
            if(this.props.objDealHeader.Deposits !== null &&
                this.props.objDealHeader.Deposits !== undefined &&
                this.props.objDealHeader.Deposits.length > 0){
                var initialDeposit = this.props.objDealHeader.Deposits.find(deposit => deposit.DepositType === 'InitialDeposit');
                if(initialDeposit !== null && initialDeposit !== undefined &&
                    initialDeposit.ReceivedStatus === 'Outstanding'){
                    //this.setState({depositPercentageDisabled: false, depositAmountDisabled: false, depositCurrencyDisabled: false})
                    enableDepositControls = true;
                } else {
                    enableDepositControls = false;
                }
            } else {
                enableDepositControls = true;
            }

            this.setState({
                disableSupportingControls: false,
                editSupportingRates: true,
                showReverseButton: true,
                deal: tempdeal,
                disableTransactionSection: true,
                endDate: endDate,
                dealAlreadyCoveredInInterface: dealAlreadyCoveredInInterface,
                originalMarketRate: this.props.objDealHeader !== undefined ? this.props.objDealHeader.OriginalMarketSpotRate : 0,
                marketRate: this.props.objDealHeader !== undefined ? this.props.objDealHeader.MarketRate : 0,
                enableDepositControls: enableDepositControls,
                editingDeal: true
            });

            console.log("***Disabling Controls***")

            this.disableAllControls();
            this.enforceDisableSupportingControls(false);
        } 
        else if (this.state.dealType === 6 && this.props.objDealHeader.FarLeg !== undefined
            && Array.isArray(this.props.objDealHeader.FarLeg) && this.props.objDealHeader.FarLeg.length > 0
            && this.props.systemDate <= this.state.maturityDate
        ) {
            console.log("***EDITABLE?***", this.props.systemDate <= this.state.maturityDate)
            console.log("***maturityDate***", this.state.maturityDate)
            console.log("***this.props.systemDate***", this.props.systemDate)
            this.allowSwapFarLegEdition();
        }
        else {
            console.log("***NO EDITABLE***")
            console.log("DealHeaderID:" + this.state.dealHeaderID)
            console.log("Status:" + this.state.status)
            console.log("Date:" + date)
            console.log("System Date:" + this.props.systemDate)
        }
    }

    handleReverseClick = () => {        
        this.enforceDisable(true);
        this.enforceDisableSupportingControls(true);
        let objDealHeader = this.state.deal;
        
        let profitPoints = Math.abs(objDealHeader.CustomerRate - objDealHeader.MarketRate);
        let spreadPercentage = 0
        if(objDealHeader.MarketRate !== 0){
            spreadPercentage = ((profitPoints / objDealHeader.MarketRate) * 100).toFixed(2);
        }            
        let spreadPoints = (profitPoints * 10000).toFixed(2);
        
        this.setState({
            disableSupportingControls: true, editSupportingRates: false, showReverseButton: false,
            adjustmentDealID: objDealHeader.AdjustmentDealID, 
            buyAmount: objDealHeader.BuyAmount, buyCurrency: objDealHeader.BuyCurrency,
            sellAmount: objDealHeader.SellAmount, sellCurrency: objDealHeader.SellCurrency,
            lockedSide: objDealHeader.LockedSide, dealType: objDealHeader.DealType,
            valueDate: objDealHeader.ValueDate, marketRate: objDealHeader.MarketRate,
            coverCustomerID: 0, useInterface: objDealHeader.UseInterface,
            customerRate: objDealHeader.CustomerRate, spreadPoints: spreadPoints,
            spreadPercentage: spreadPercentage, profitAmount: objDealHeader.ProfitAmount,
            profitAmountCcy:0,
            totalFees: objDealHeader.TotalFees,
            disableTransactionSection: false
        });
    }

    disableAllControls(){
        //Enable buttons
        var sellAmountDisabled = false, buyAmountDisabled = false, 
        marketRateDisabled = false, customerRateDisabled = false,
        sellCurrencyDisabled = false, buyCurrencyDisabled = false,
        valueDateDisabled = false, forwardTypeDisabled = false,
        depositPercentageDisabled = false, depositAmountDisabled = false,
        requireDepositsDisabled = false, depositCurrencyDisabled = false,
        forwardDaysDisabled = false, maturityDateDisabled = false,
        farLegSellAmountDisabled = false, farLegBuyAmountDisabled = false,
        nearLegMarketRateDisabled = false, farLegMarketRateDisabled = false, 
        nearLegCustomerRateDisabled = false, farLegCustomerRateDisabled = false,
        nearLegSpreadDisabled = false, farLegSpreadDisabled = false,
        nearLegOriginalMarketRateDisabled = false, farLegOriginalMarketRateDisabled = false,
        coverCustomerDisabled = false, useInterfaceDisabled = false,
        disableForwardType = false;

        //Validate if forward has drawdowns
        var doesForwardHasDrawdowns = false;
        if(this.state.dealType === 4 &&
            this.state.totalDisbursed > 0){
                doesForwardHasDrawdowns = true;
        }

        if(this.state.status === 'Voided' ||
            this.state.isAdjustmentDeal ||
            this.state.isSameCurrencyDealRefund ||
            doesForwardHasDrawdowns){
            sellAmountDisabled = buyAmountDisabled = 
            marketRateDisabled = customerRateDisabled = 
            sellCurrencyDisabled = buyCurrencyDisabled = 
            valueDateDisabled = forwardTypeDisabled =
            depositPercentageDisabled = depositAmountDisabled = 
            requireDepositsDisabled = depositCurrencyDisabled =
            forwardDaysDisabled = maturityDateDisabled = 
            farLegSellAmountDisabled = farLegBuyAmountDisabled = 
            nearLegMarketRateDisabled = farLegMarketRateDisabled = 
            nearLegCustomerRateDisabled = farLegCustomerRateDisabled = 
            nearLegSpreadDisabled = farLegSpreadDisabled = 
            nearLegOriginalMarketRateDisabled = farLegOriginalMarketRateDisabled = true;
        }
		
		if(this.state.dealType === 2){
            customerRateDisabled = marketRateDisabled = 
            sellCurrencyDisabled = buyCurrencyDisabled = true;
        }

        //Do not allow market rate updates when using interface
        if(this.state.useInterface){
            marketRateDisabled = true;
        }

        if(this.state.externalInterface === ''){
            useInterfaceDisabled = true;
        }

        //Disable cover dropdown for bank customers
        if(this.props.objCustomer['Customer Type'] === 'Bank'){
            coverCustomerDisabled = true;
        }

        //Disable cover dropdown if creating drawdown from forward not covered
        if(this.state.dealType === 2){
            coverCustomerDisabled = true;
        }
		
		if(this.state.status === 'Voided'){
            maturityDateDisabled = true;
        }
		if (this.state.dealType === 4){
			depositPercentageDisabled = depositAmountDisabled = 
            depositCurrencyDisabled = !this.state.requireDeposits;
			if(this.state.depositID !== 0 && this.state.depositID !== undefined){
                depositPercentageDisabled = depositAmountDisabled = 
                requireDepositsDisabled = depositCurrencyDisabled = true;
                if(this.props.objDealHeader != undefined){
                    var initialDeposit = this.props.objDealHeader.Deposits.find(deposit => deposit.DepositType === 'InitialDeposit');
                    if(initialDeposit !== null && initialDeposit !== undefined &&
                        initialDeposit.ReceivedStatus === 'Outstanding'){
                            depositPercentageDisabled = depositAmountDisabled = 
                            requireDepositsDisabled = depositCurrencyDisabled = false;
                    }
                }
            }
			disableForwardType = valueDateDisabled = !(this.state.dealHeaderID !== 0 && this.state.dealType === 4 )
		}
		
		if(this.state.forwardType === 'Closed'){
            valueDateDisabled = true;
        }

        let editForwardDate= false;
        if(this.props.objDealHeader !== undefined)
        {
            if(this.props.objDealHeader.DealHeaderID> 0 && this.props.objDealHeader.DealType == 4 && this.props.objDealHeader.ForwardType == 'Open' && (this.state.totalDisbursed > 0 && this.state.totalDisbursed <= this.props.objDealHeader.SellAmount) && this.props.objDealHeader.MaturityDate >= this.props.systemDate)
            {
                maturityDateDisabled = false;
                forwardDaysDisabled = false;
                valueDateDisabled = true;
                editForwardDate = true;
                useInterfaceDisabled = true;
                coverCustomerDisabled = true;
                requireDepositsDisabled = true
            }
        }
		
		if(this.state.dealHeaderID > 0 && (this.state.coveredDeals != "" || this.state.coverDealID > 0)){
            coverCustomerDisabled = true;
            useInterfaceDisabled = true;
        }

        
        let externalInterface='';
        let externalInterfaceType='';
        if(this.props.objCustomer['Customer Type'] === 'Bank'){
            let bankFixInterface = this.props.objCustomer['BankFixInterface']
            if(bankFixInterface !== undefined){
                let disableCheck = this.props.bankCustomers.find(function(element){
                    return element.BankInterface === bankFixInterface;
                });
                if(disableCheck !== undefined){
                    useInterfaceDisabled = false;
                    externalInterface=disableCheck.BankInterface;
                    externalInterfaceType=disableCheck.InterfaceType;
                }
            }
        }

        this.setState({
            externalInterface: externalInterface,
            externalInterfaceType: externalInterfaceType,
            sellAmountDisabled: sellAmountDisabled, buyAmountDisabled: buyAmountDisabled, 
            marketRateDisabled: marketRateDisabled, customerRateDisabled: customerRateDisabled,
            sellCurrencyDisabled: sellCurrencyDisabled, buyCurrencyDisabled: buyCurrencyDisabled,
            valueDateDisabled: valueDateDisabled, forwardTypeDisabled: forwardTypeDisabled,
            depositPercentageDisabled: depositPercentageDisabled, depositAmountDisabled: depositAmountDisabled,
            requireDepositsDisabled: requireDepositsDisabled, depositCurrencyDisabled: depositCurrencyDisabled,
            forwardDaysDisabled: forwardDaysDisabled, maturityDateDisabled: maturityDateDisabled,
            farLegSellAmountDisabled: farLegSellAmountDisabled, farLegBuyAmountDisabled: farLegBuyAmountDisabled,
            nearLegMarketRateDisabled: nearLegMarketRateDisabled, farLegMarketRateDisabled: farLegMarketRateDisabled, 
            nearLegCustomerRateDisabled: nearLegCustomerRateDisabled, farLegCustomerRateDisabled: farLegCustomerRateDisabled,
            nearLegSpreadDisabled: nearLegSpreadDisabled, farLegSpreadDisabled: farLegSpreadDisabled,
            nearLegOriginalMarketRateDisabled: nearLegOriginalMarketRateDisabled, farLegOriginalMarketRateDisabled: farLegOriginalMarketRateDisabled,
            coverCustomerDisabled: coverCustomerDisabled, useInterfaceDisabled: useInterfaceDisabled,
            disableForwardType: disableForwardType, editForwardDate: editForwardDate
        });        
    }

    allowSwapFarLegEdition = () => {

        let tempdeal = {
            AdjustmentDealID: this.state.adjustmentDealID,
            BuyAmount: this.state.buyAmount,
            BuyCurrency: this.state.buyCurrency,
            SellAmount: this.state.sellAmount,
            SellCurrency: this.state.sellCurrency,
            LockedSide: this.state.lockedSide,
            DealType: this.state.dealType,
            ValueDate: this.state.valueDate,
            MarketRate: this.state.marketRate,
            CoverCustomerID: this.state.coverDealID,
            UseInterface: this.state.useInterface,
            CustomerRate: this.state.customerRate,
            SpreadPoints: this.state.SpreadPoints,
            SpreadPercentage: this.state.spreadPercentage,
            ProfitAmount: this.state.profitAmount,
            TotalFees: this.state.totalFees
        }
        
        let dealAlreadyCoveredInInterface = false
        if (this.props.objDealHeader !== undefined) {
            let coverInterfaceTradeNumber = this.props.objDealHeader.CoverCustomerID || ""
            if (Number(this.props.objDealHeader.CoverCustomerID) > 0 && coverInterfaceTradeNumber !== "") {
                dealAlreadyCoveredInInterface = true;
            }
        }

        this.setState({
            disableSupportingControls: false,
            editSupportingRates: true,
            showReverseButton: true,
            deal: tempdeal,
            disableTransactionSection: true,
            endDate: undefined,
            dealAlreadyCoveredInInterface: dealAlreadyCoveredInInterface,
            originalMarketRate: this.props.objDealHeader !== undefined ? this.props.objDealHeader.OriginalMarketSpotRate : 0,
            marketRate: this.props.objDealHeader !== undefined ? this.props.objDealHeader.MarketRate : 0,
            //
            farLegBuyAmountDisabled: false,
            farLegSellAmountDisabled: false,
            forwardDaysDisabled: false,
            maturityDateDisabled: false,
            farLegOriginalMarketRateDisabled: false,
            farLegMarketRateDisabled: false,
            farLegSpreadDisabled: false,
            farLegCustomerRateDisabled: false,
            allowEditSwapFarLeg: true,
            editingDeal: true
        })
    }

    enforceDisable(disable){        
        this.setState({
            sellAmountDisabled: disable, buyAmountDisabled: disable, 
            marketRateDisabled: disable, customerRateDisabled: disable,
            sellCurrencyDisabled: disable, buyCurrencyDisabled: disable,
            valueDateDisabled: disable, forwardTypeDisabled: disable,
            depositPercentageDisabled: disable, depositAmountDisabled: disable,
            requireDepositsDisabled: disable, depositCurrencyDisabled: disable,
            forwardDaysDisabled: disable, maturityDateDisabled: disable,
            farLegSellAmountDisabled: disable, farLegBuyAmountDisabled: disable,
            nearLegMarketRateDisabled: disable, farLegMarketRateDisabled: disable, 
            nearLegCustomerRateDisabled: disable, farLegCustomerRateDisabled: disable,
            nearLegSpreadDisabled: disable, farLegSpreadDisabled: disable,
            nearLegOriginalMarketRateDisabled: disable, farLegOriginalMarketRateDisabled: disable,
            coverCustomerDisabled: disable, useInterfaceDisabled: disable,
            disableForwardType: disable, disableSupportingControls: disable
        }); 
    }

    handleVoidClick = (event) => {
        //Validation
        if(this.props.objDealHeader.DealType == 4 && (this.props.objDealHeader.DealPaidStatus == 'Partially Paid' || this.props.objDealHeader.DealPaidStatus == 'Fully Paid'))
        {
            this.showAlert('Validation', 'Forward cannot be voided because is ' + this.props.objDealHeader.DealPaidStatus);
        }
        else
        {
            var valueDate = this.state.valueDate;
            if(this.state.dealType === 4){
                valueDate = this.state.maturityDate;
            }
            let systemDate = new Date(this.props.systemDate);
            if(formatDate(valueDate) === formatDate(systemDate) && 
                this.props.objCustomer['TraderAllowSameDayVoid$'] === false){
                this.showAlert('Validation', 'Customer trader does not have permission for same day void!');
            }
            else{
                this.setState({showVoidConfirmation: true});  
            }  
        }      
    }

    handleAdjustClick = (event) => {
        //Validation
        if(this.props.objCustomer['TraderAllowPreviousDayVoid$'] === false){
            this.showAlert('Validation', 'Customer trader does not have permission for adjustments!');
        }
        else{
            this.setState({showAdjustConfirmation: true});
        }        
    }

    handleRefreshClick = () => {
        this.getRate(false);
    }

    handleBroadCastClick = () => {
        if(this.validateGetRate(false)){
            this.setState({loading: true});
            let dealAmount = this.state.sellAmount;
            if(this.state.lockedSide === 'Buy'){
                dealAmount = this.state.buyAmount;
            }
            //Get Rate
            var valueDate = formatDate(this.state.valueDate);
            var forwardNearDate = formatDate(this.state.valueDate);
            var farLegDate = null;
            var farLegDealAmount = null;
            switch(this.state.dealType){
                case 4:
                    valueDate = formatDate(this.state.maturityDate);
                    break;
                case 6:
                    farLegDate = formatDate(this.state.maturityDate);
                    if(this.state.lockedSide === 'Buy'){
                        farLegDealAmount = this.state.farLegSellAmount;
                    }
                    else{
                        farLegDealAmount = this.state.farLegBuyAmount;
                    }
                    break;
                default:
                    break;
            }
            getBroadcastRates(this.props.objCustomer["Customer ID"],
                    this.props.objContact["Contact ID"],
                    this.state.dealType, 
                    valueDate, 
                    forwardNearDate,
                    this.state.buyCurrency, 
                    this.state.sellCurrency, 
                    dealAmount, 
                    this.state.lockedSide,
                    this.state.totalFees, 
                    false,
                    farLegDealAmount,
                    farLegDate).then(
                (response) => {
                    //alert(JSON.stringify(response));
                    this.setState({broadcastRateOptions: response.rates,
                        loading:false,
                        showRateSelection: true,
                    });
                }
            );
        }
    }

    handleMoreBeneficiariesClick(event){
        event.preventDefault();
    }

    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    resetValidations(validations){
        validations.buyAmount = validations.buyCurrency = 
        validations.sellAmount = validations.sellCurrency =
        validations.valueDate = validations.maturityDate = 
        validations.farLegBuyAmount = validations.farLegSellAmount = true;
        return validations;
    }

    validateGetRate(fromCurrency){
        var result = true;
        var tempValidations = this.state.validations;
        tempValidations = this.resetValidations(tempValidations);
        if(this.state.buyCurrency === '' ||
            this.state.buyCurrency === 'select'){
            result = false;
            tempValidations.buyCurrency = false;
        }

        if(this.state.sellCurrency === '' ||
            this.state.sellCurrency === 'select'){
            result = false;
            tempValidations.sellCurrency = false;
        }

        if(this.state.valueDate === '' || this.state.valueDate === undefined){
            result = false;
            tempValidations.valueDate = false;
        }

        if([4, 6].includes(this.state.dealType)){
            if(this.state.maturityDate === '' || 
                this.state.maturityDate === undefined){
                    if(!fromCurrency)
                    {
                        result = false;
                        tempValidations.maturityDate = false;

                    if(this.state.valueDate > this.state.maturityDate){
                        result = false;
                        tempValidations.valueDate = false;
                        tempValidations.maturityDate = false;
                    }
                    }
                    else
                    {
                        this.setState({maturityDate: this.state.valueDate});                    
                    }
            }            
        }            
        if(!fromCurrency) // refresh when currency changes
        {
            if(this.state.lockedSide === 'Buy'){
                if(this.state.buyAmount === 0){
                    result = false;
                    tempValidations.buyAmount = false;
                    tempValidations.sellAmount = true;
                }
                if(this.state.dealType === 6){
                    if(this.state.farLegSellAmount === 0){
                        result = false;
                        tempValidations.farLegSellAmount = false;
                        tempValidations.farLegBuyAmount = true;
                    }
                }
            }
            else{
                if(this.state.sellAmount === 0){
                    result = false;
                    tempValidations.buyAmount = true;
                    tempValidations.sellAmount = false;
                }
                if(this.state.dealType === 6){
                    if(this.state.farLegBuyAmount === 0){
                        result = false;
                        tempValidations.farLegBuyAmount = false;
                        tempValidations.farLegSellAmount = true;
                    }
                }
            }
        }
        this.setState({validations: tempValidations});
        return result;
    }

    getRate(fromCurrency){
        if(this.state.dealType == 2 && fromCurrency || this.state.editForwardDate )
        {
            return;
        }
        if(this.validateGetRate(fromCurrency)){
            if(!fromCurrency)
            {
            this.setState({loading: true});
            }
            let dealAmount = this.state.sellAmount > 0 ? this.state.sellAmount: 1;
            if(this.state.lockedSide === 'Buy'){
                dealAmount = this.state.buyAmount > 0 ? this.state.buyAmount: 1;
            }
            //Get Rate
            var valueDate = formatDate(this.state.valueDate);
            var forwardNearDate = formatDate(this.state.valueDate);
            var farLegDate = null;
            var farLegDealAmount = null;
            switch(this.state.dealType){
                case 4:
                    valueDate = this.state.maturityDate == '' ? valueDate: formatDate(this.state.maturityDate); //fromCurrency? valueDate: formatDate(this.state.maturityDate);
                    break;
                case 6:
                    farLegDate = this.state.maturityDate == '' ? valueDate: formatDate(this.state.maturityDate); //fromCurrency? valueDate: formatDate(this.state.maturityDate);
                    if(this.state.lockedSide === 'Buy'){
                        farLegDealAmount = this.state.farLegSellAmount > 0 ? this.state.farLegSellAmount: 1;
                    }
                    else{
                        farLegDealAmount = this.state.farLegBuyAmount > 0 ? this.state.farLegBuyAmount: 1;
                    }
                    break;
                default:
                    break;
            }

            //Get external interface
            var externalInterface = '', externalInterfaceType = '';
            if(this.state.useInterface){
                externalInterface = this.state.externalInterface;
                externalInterfaceType = this.state.externalInterfaceType;
            }
            
            getRate(this.props.objCustomer["Customer ID"],
                    this.props.objContact["Contact ID"],
                    this.state.dealType, 
                    valueDate, 
                    forwardNearDate,
                    this.state.buyCurrency, 
                    this.state.sellCurrency, 
                    dealAmount, 
                    this.state.lockedSide,
                    this.state.totalFees, 
                    false,
                    farLegDealAmount,
                    farLegDate,
                    externalInterface,
                    externalInterfaceType).then(
                (response) => {
                    //alert(JSON.stringify(response));
                    if(Number(response.httpStatusCode) === 200){
                        this.readRateObject(response);
                    }
                    else{
                        var httpErrorMessage = 'An error occurred. Please try again.';
                        if(response.httpErrorMessage){
                            httpErrorMessage = response.httpErrorMessage
                        }
                        this.setState({
                            alertTitle: "Error",
                            alertMessage: httpErrorMessage,
                            showAlert: true,
                            loading: false,
                        });
                    }
                }
            );
        }
    }

    readRateObject = (objRate) => {        
        let rateQuoteID = objRate.RateQuoteID;
        let customerRate = Number(objRate.CustomerRate);
        let marketRate = Number(objRate.MarketRate);
        let jsonProperties = '';
        
        let dealAlreadyCoveredInInterface = false
        if (this.props.objDealHeader !== undefined) {
            let dealAlreadyCovered = Number(this.props.objDealHeader.CoverCustomerID)
            let coverInterfaceTradeNumber = this.props.objDealHeader.CoverCustomerID || ""
            if (dealAlreadyCovered > 0 && coverInterfaceTradeNumber !== "") {
                dealAlreadyCoveredInInterface = true;
            }
        }

        /*if (dealAlreadyCoveredInInterface) {
            marketRate = this.props.objDealHeader.MarketRate;
        }
        else {*/
            marketRate = Number(objRate.MarketRate);
        //}

        let rateTerms = objRate.RateTerms;
        
        var farLegCustomerRate = 0, farLegMarketRate = 0, 
        farLegSpread = 0, farLegProfit = 0,farLegProfitCcy = 0,
        farLegForwardPoints = 0, farLegOriginalMarketSpotRate = 0,
        farLegSellAmount = 0, farLegBuyAmount = 0;

        var nearLegCustomerRate = 0, nearLegMarketRate = 0, 
        nearLegSpread = 0, nearLegProfit = 0,nearLegProfitCcy = 0,
        nearLegForwardPoints = 0, nearLegOriginalMarketSpotRate = 0;

        //for rate validations        
        let originalMarketRate = Number(objRate.MarketRate);//-Number(objRate.ForwardPoints);        
        let originalCustomerRate = Number(objRate.CustomerRate);//-Number(objRate.ForwardPoints);        
        var originalMarketSpotRate = 0, originalFarCustomerRate = 0, originalFarMarketRate=0, originalFarMarketSpotRate=0;

        let amounts = this.calculateAmounts(this.state.sellAmount, this.state.buyAmount, rateTerms, customerRate, this.state.lockedSide);
        let sellAmount = Number(amounts.SellAmount);
        let buyAmount = Number(amounts.BuyAmount);
        let depositAmount = Number(amounts.DepositAmount);

        let profitAmount = 0;     
        let profitAmountCcy = 0;

        
        if(amounts.SellAmount > 0 && amounts.BuyAmount > 0)
        {
            profitAmount = Number(objRate.Profit);  
            profitAmountCcy = this.getProfitCCy(amounts.SellAmount, amounts.BuyAmount, rateTerms, marketRate, this.state.lockedSide);
        }

        if(this.state.dealType === 6){
            //Near Leg
            nearLegCustomerRate = Number(objRate.CustomerRate);
            nearLegForwardPoints = 0;
            nearLegMarketRate = Number(objRate.MarketRate);
            nearLegOriginalMarketSpotRate = roundAmountByCurrencyPair(Number(nearLegMarketRate - (Number(objRate.ForwardPoints) / 10000)), this.state.buyCurrency, this.state.sellCurrency)
            nearLegSpread = roundAmountByCurrencyPair(Number((nearLegCustomerRate - nearLegMarketRate) * 10000), this.state.buyCurrency, this.state.sellCurrency)

            if(amounts.SellAmount > 0 && amounts.BuyAmount > 0)
            {
                nearLegProfit = Number(objRate.Profit);
                nearLegProfitCcy = this.getProfitCCy(amounts.SellAmount, amounts.BuyAmount, rateTerms, nearLegMarketRate, this.state.lockedSide);
            }

            originalCustomerRate = Number(objRate.CustomerRate);
            originalMarketRate = Number(objRate.MarketRate);
            originalMarketSpotRate = Number(nearLegOriginalMarketSpotRate);
            //Far Leg
            farLegCustomerRate = Number(objRate.FarLegCustomerRate);
            farLegForwardPoints = 0;
            farLegMarketRate = Number(objRate.FarLegMarketRate);
            farLegOriginalMarketSpotRate = roundAmountByCurrencyPair(Number((farLegMarketRate - (Number(objRate.FarLegForwardPoints) / 10000))), this.state.buyCurrency, this.state.sellCurrency)
            
            farLegSpread = roundAmountByCurrencyPair(Number((farLegCustomerRate - farLegMarketRate) * 10000), this.state.buyCurrency, this.state.sellCurrency)

            originalFarCustomerRate = Number(objRate.FarLegCustomerRate);
            originalFarMarketRate = Number(objRate.FarLegMarketRate);
            originalFarMarketSpotRate = Number(farLegOriginalMarketSpotRate);

            let farLegAmounts = this.calculateAmounts(this.state.farLegBuyAmount, this.state.farLegSellAmount, rateTerms, farLegCustomerRate, this.state.lockedSide);
            farLegSellAmount = Number(farLegAmounts.BuyAmount);
            farLegBuyAmount = Number(farLegAmounts.SellAmount);
            if(farLegAmounts.SellAmount > 0 && farLegAmounts.BuyAmount > 0)
            {
                farLegProfit = Number(objRate.FarLegProfit);
                farLegProfitCcy = this.getProfitCCy(farLegAmounts.SellAmount, farLegAmounts.BuyAmount, rateTerms, originalFarMarketRate, this.state.lockedSide);
            }
        }

        //Spread points and percentage
        let profitPoints = Math.abs(customerRate - marketRate);
        var scaleCCYPair = getScaleForCurrencyPair(this.state.buyCurrency, this.state.sellCurrency);
        let spreadPoints = Number((profitPoints * 10000).toFixed(scaleCCYPair) );
        var spreadPercentage = 0;
        if(Number(marketRate) !== 0){
            spreadPercentage = Number(((profitPoints / marketRate) * 100).toFixed(2));
        }
        
        var rightViewMode = this.state.rightViewMode;
        if(rightViewMode === 'supportingRates'){
            rightViewMode = 'regular';
        }

        //Set one click cover interface
        let oneClickCoverBank = this.state.bankCustomers.find(function(element) {
            return element.Name === objRate.Source;
        });

        var coverCustomerID = this.state.coverCustomerID;
        var useInterface = this.state.useInterface;

        if(objRate.IsBroadcastedRate){
            coverCustomerID = 0;
            useInterface = false;
        }

        if(oneClickCoverBank !== undefined){
            coverCustomerID = oneClickCoverBank.CustomerID;
            useInterface = true;
        }

        if(objRate.JsonProperties)
        {
            jsonProperties =  objRate.JsonProperties;
        }

        this.setState({nearLegEnableControlUpdates: false,
            farLegEnableControlUpdates: false,
            buyAmountEnableUpdatesTrigger: false,
            sellAmountEnableUpdatesTrigger: false,
            depositAmountEnableUpdatesTrigger: false,
            depositPercentageEnableUpdatesTrigger: false,
            customerRateEnableUpdatesTrigger: false,
            marketRateEnableUpdatesTrigger: false,

            buyAmount: buyAmount,
            coverCustomerID: coverCustomerID,
            customerRate: customerRate,
            depositAmount: depositAmount,
            farLegBuyAmount: farLegBuyAmount,
            farLegCustomerRate: farLegCustomerRate,
            farLegForwardPoints: farLegForwardPoints,
            farLegMarketRate: farLegMarketRate,
            farLegOriginalMarketSpotRate: farLegOriginalMarketSpotRate,
            farLegProfit: farLegProfit,
            farLegProfitCcy: farLegProfitCcy,
            farLegSellAmount: farLegSellAmount,
            farLegSpread: farLegSpread,
            loading: false,
            marketRate: marketRate,
            nearLegCustomerRate: nearLegCustomerRate,
            nearLegForwardPoints: nearLegForwardPoints,
            nearLegMarketRate: nearLegMarketRate,
            nearLegOriginalMarketSpotRate: nearLegOriginalMarketSpotRate,
            nearLegProfit: nearLegProfit,
            nearLegProfitCCy: nearLegProfitCcy,
            nearLegSpread: nearLegSpread,
            profitAmount: profitAmount,
            profitAmountCcy: profitAmountCcy,
            rateQuoteID: rateQuoteID,
            rateTerms: rateTerms,
            rightViewMode: rightViewMode,
            sellAmount: sellAmount,
            showRateSelection: false,
            spreadPercentage: spreadPercentage,
            spreadPoints: spreadPoints,
            useInterface: useInterface,
            originalMarketRate: originalMarketRate,
            originalCustomerRate: originalCustomerRate,
            originalMarketSpotRate: originalMarketSpotRate,
            originalFarCustomerRate: originalFarCustomerRate,
            originalFarMarketRate: originalFarMarketRate,
            originalFarMarketSpotRate: originalFarMarketSpotRate,
            jsonProperties: jsonProperties,
            supportingRates: {}
        }, () => {
            this.setState({nearLegEnableControlUpdates: true,
                farLegEnableControlUpdates: true,
                buyAmountEnableUpdatesTrigger: true,
                sellAmountEnableUpdatesTrigger: true,
                depositAmountEnableUpdatesTrigger: true,
                depositPercentageEnableUpdatesTrigger: true,
                customerRateEnableUpdatesTrigger: true,
                marketRateEnableUpdatesTrigger: true,
            });

            this.loadSupportingRates();            
        });
    }

    loadSupportingRates = () => {

        if (this.state.rateQuoteID === '' || this.state.dealType === 2 ||
            this.state.sellCurrency === this.state.baseCurrency || this.state.buyCurrency === this.state.baseCurrency) {
            return
        }

        let maturityDate;
        if (this.state.maturityDate != null && this.state.maturityDate !== '') {
            maturityDate = formatDate(this.state.maturityDate);
        }
        getSupportingRates(this.state.rateQuoteID, this.state.dealType, this.state.sellCurrency, this.state.buyCurrency, formatDate(this.state.valueDate), maturityDate).then(
            (response) => {
                if (response != undefined)
                    this.setState({
                        supportingRates: response
                    });
            }
        );
    }

    updateLocalSupportingRates = (supportingRates) => {
        console.log(supportingRates)
        this.setState({
            supportingRates: supportingRates
        });
    }

    getProfit = async (buyAmount, sellAmount, customerRate, marketRate, isFarLeg) => {
        var profitAmount = 0;
        
        var buyCurrency = this.state.buyCurrency;
        var dealType = this.state.dealType;
        var sellCurrency = this.state.sellCurrency;
        var scaleBaseCCY = getScaleByCurrency(this.state.baseCurrency);
        
        switch(this.state.dealType){
            case 1:
            case 4:
            case 6:
            default:
                if(this.state.rateQuoteID !== '' &&
                    this.state.sellCurrency !== '' &&
                    this.state.buyCurrency !== '' &&
                    sellAmount !== 0 &&
                    buyAmount !== 0 &&
                    this.state.valueDate !== ''){
                        var valueDate = '';
                        switch(this.state.dealType){
                            default:
                                valueDate = formatDate(this.state.valueDate);
                                break;
                            case 2:
                            case 4:
                                valueDate = formatDate(this.state.maturityDate);
                                break;
                            case 6:
                                if(isFarLeg){
                                    buyCurrency = this.state.sellCurrency;
                                    sellCurrency = this.state.buyCurrency;
                                    let tempSellAmount = sellAmount;
                                    sellAmount = buyAmount;
                                    buyAmount = tempSellAmount;
                                    valueDate = formatDate(this.state.maturityDate);
                                }
                                else{
                                    dealType = 1;//Spot
                                    valueDate = formatDate(this.state.valueDate);
                                }
                                break;
                        }

                        await getProfit(this.state.rateQuoteID,
                            dealType, 
                            sellCurrency,
                            buyCurrency,
                            sellAmount,
                            buyAmount,
                            valueDate,
                            this.state.totalFees,
                            customerRate,
                            marketRate).then(
                            (json) => {
                                profitAmount = json.Profit;
                                //this.setState({profitAmount: profitAmount,});
                            }
                    );
                    
                }
                break;
            case 2:
                profitAmount = (this.state.forwardProfitAmount * sellAmount / this.state.forwardSellAmount )
                if(this.state.dealHeaderID === 0){
                    if(sellAmount + this.state.forwardSellTotalDisbursed === this.state.forwardSellAmount){
                        profitAmount = (this.state.forwardProfitAmount - this.state.forwardProfitAmountDisbursed);
                    }
                }
                else{
                    //4444 + 6666 - 2222 = 8888
                    //alert(sellAmount);
                    //alert(this.state.forwardSellTotalDisbursed);
                    //alert(this.state.originalSellAmount);
                    //alert(this.state.forwardSellAmount);
                    if(sellAmount + this.state.forwardSellTotalDisbursed - this.state.originalSellAmount === this.state.forwardSellAmount){
                        profitAmount = (this.state.forwardProfitAmount - this.state.forwardProfitAmountDisbursed + this.state.originalProfit);
                    }
                }                
                //this.setState({profitAmount: profitAmount});
                break;
        }
        if(profitAmount == null)
        {
            profitAmount = 0;
        }

        return profitAmount.toFixed(scaleBaseCCY);
    }

    calculateAmounts(sellAmount, buyAmount, rateTerms, customerRate, lockedSide){
        //alert(sellAmount + '-' + buyAmount + '-' + rateTerms);
        var depositAmount = this.state.depositAmount;
        if(lockedSide === 'Buy'){
            switch(this.state.dealType){
                case 1:
                case 4:
                case 6:
                default:
                    if(rateTerms === 'D'){
                        if(customerRate !== 0){
                            sellAmount = buyAmount / customerRate;
                        }
                    }
                    else{
                        sellAmount = buyAmount * customerRate;
                    }
                    break;
                case 2:
                    if(buyAmount === this.state.forwardBuyAmount - this.state.forwardBuyTotalDisbursed){
                        sellAmount = this.state.forwardSellAmount - this.state.forwardSellTotalDisbursed;
                    }
                    else{
                        sellAmount = buyAmount * this.state.forwardSellAmount / this.state.forwardBuyAmount;
                    }
                    break;
            }
            sellAmount = sellAmount.toFixed(this.state.sellCurrencyDecimals);
        }
        else{
            switch(this.state.dealType){
                case 1:
                case 4:
                case 6:
                default:
                    if(rateTerms === 'D'){
                        buyAmount = sellAmount * customerRate;
                    }
                    else{
                        if(customerRate !== 0){
                            buyAmount = sellAmount / customerRate;
                        }
                    }
                    break;
                case 2:
                    if(sellAmount === this.state.forwardSellAmount - this.state.forwardSellTotalDisbursed){
                        buyAmount = this.state.forwardBuyAmount - this.state.forwardBuyTotalDisbursed;
                    }
                    else{
                        buyAmount = sellAmount * this.state.forwardBuyAmount / this.state.forwardSellAmount;
                    }
                    break;
            }
            
            buyAmount = buyAmount.toFixed(this.state.buyCurrencyDecimals);
        }

        //Forward deposit amount
        if(this.state.depositID === 0){
            depositAmount = (buyAmount * this.state.depositPercent / 100).toFixed(0);
        }

        var results = [];
        results.BuyAmount = Number(buyAmount);
        results.SellAmount = Number(sellAmount);
        results.DepositAmount = depositAmount;
        return results;
    }

    getProfitCCy(sellAmount, buyAmount, rateTerms, marketRate, lockedSide){
        let marketBuyAmount = 0;
        let marketSellAmount = 0;
        if(lockedSide === 'Buy'){
            switch(this.state.dealType){
                case 1:
                case 4:
                case 6:
                default:
                    if(rateTerms === 'D'){
                        if(marketRate !== 0){
                            marketSellAmount = buyAmount / marketRate;
                        }
                    }
                    else{
                        marketSellAmount = buyAmount * marketRate;
                    }
                    break;
                case 2:
                    if(buyAmount === this.state.forwardBuyAmount - this.state.forwardBuyTotalDisbursed){
                        marketSellAmount = this.state.forwardSellAmount - this.state.forwardSellTotalDisbursed;
                    }
                    else{
                        marketSellAmount = buyAmount * this.state.forwardSellAmount / this.state.forwardBuyAmount;
                    }
                    break;
            }
            marketSellAmount = marketSellAmount.toFixed(this.state.sellCurrencyDecimals);
            
                return  marketSellAmount -sellAmount;
             
        }
        else{
            switch(this.state.dealType){
                case 1:
                case 4:
                case 6:
                default:
                    if(rateTerms === 'D'){
                        marketBuyAmount = sellAmount * marketRate;
                    }
                    else{
                        if(marketRate !== 0){
                            marketBuyAmount = sellAmount / marketRate;
                        }
                    }
                    break;
                case 2:
                    if(sellAmount === this.state.forwardSellAmount - this.state.forwardSellTotalDisbursed){
                        marketBuyAmount = this.state.forwardBuyAmount - this.state.forwardBuyTotalDisbursed;
                    }
                    else{
                        marketBuyAmount = sellAmount * this.state.forwardBuyAmount / this.state.forwardSellAmount;
                    }
                    break;
            }
            
            marketBuyAmount = marketBuyAmount.toFixed(this.state.buyCurrencyDecimals);

           return buyAmount - marketBuyAmount;
        
        }
    }

    calculateEndDate(startDate, buyCurrency, sellCurrency){
        //Get spot days
        let spotDays = 2;
        if(this.props.currencyPairs[buyCurrency+sellCurrency] !== undefined){
            spotDays = this.props.currencyPairs[buyCurrency+sellCurrency].SpotDays;
        }
        return this.getNextBusinessDate(startDate, spotDays, buyCurrency, sellCurrency);

        /*
        //Recent holidays
        var holidays = [];
        for(var i=0; i<this.props.holidays.length; i++){
            if(this.props.holidays[i].CurrencyID === 'ANY' ||
            this.props.holidays[i].CurrencyID === sellCurrency ||
            this.props.holidays[i].CurrencyID === buyCurrency){
                holidays.push(this.props.holidays[i].Date);
            }
        }

        //Calculate end date
        var endDate = new Date(startDate);
        var daysToAdd = spotDays;
        
        while(daysToAdd > 0){
            endDate.setDate(endDate.getDate() + 1);
            if(endDate.getDay() === 6 || 
                endDate.getDay() === 0 ||
                holidays.includes(formatDate(endDate))){
                //Nothing at all
            }
            else{
                daysToAdd--;
            } 
        }
        return endDate;*/
    }

    getNextBusinessDate(startDate, days, buyCurrency, sellCurrency){
        //Recent holidays
        var holidays = [];
        for(var i=0; i<this.props.holidays.length; i++){
            if(this.props.holidays[i].CurrencyID === 'ANY' ||
            this.props.holidays[i].CurrencyID === sellCurrency ||
            this.props.holidays[i].CurrencyID === buyCurrency){
                holidays.push(this.props.holidays[i].Date);
            }
        }

        //Calculate next business date
        var endDate = new Date(startDate);
        var daysToAdd = days; // setting startDate in endDate increase 1 day
        while(daysToAdd > 0){
            endDate.setDate(endDate.getDate() + 1);
            if(endDate.getDay() === 6 || 
                endDate.getDay() === 0 ||
                holidays.includes(formatDate(endDate))){
                //Nothing at all
            }
            else{
                daysToAdd--;
            } 
        }
        return endDate;
    }

    getNextBusinessDateForward(startDate, days){

        //Calculate next business date
        var endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + days);
        return endDate;
    }

    handleViewDrawdownsClick = (event) => {
        this.setState({rightViewMode: 'drawdowns'});
    }

    handleCloseDrawdownsClick = () => {
        this.setState({rightViewMode: 'regular'});
    }

    onSaveSupportingRatesClick = async (objSupportingRates) => {
        //alert(JSON.stringify(objSupportingRates));
        this.setState({loading: true});
        saveSupportingRates(objSupportingRates).then(
            async (response) => {
                //alert(JSON.stringify(response));
                if(Number(response.httpStatusCode) === 200){
                    let profitAmount = await this.getProfit(this.state.buyAmount, this.state.sellAmount, this.state.customerRate, this.state.marketRate, false);
                    let profitAmountCcy = this.getProfitCCy(this.state.sellAmount, this.state.buyAmount, this.state.rateTerms, this.state.marketRate, this.state.lockedSide);
                    this.setState({
                        alertMessage: 'Rates updated successfully',
                        alertTitle: 'Success',
                        loading: false,
                        profitAmount: profitAmount,
                        profitAmountCcy: profitAmountCcy,
                        rightViewMode: 'regular',
                        showAlert: true,
                    });
                }
                else{
                    this.setState({alertMessage: 'Error',
                        alertTitle: 'Supporting rates not saved. Please try again or contact the administrator',
                        loading: false,
                        showAlert: true,
                    });
                }
            }
        );
        this.loadSupportingRates();
    }

    onCloseSupportingRatesClick = () => {
        this.setState({rightViewMode: 'regular'});
    }

    //#region Handle updates
    handleUpdateSellAmount = async (event) => {
        var amountEntered = Number(event.target.value);
        var sellAmount = this.state.sellAmount;
        if(this.state.sellAmountEnableUpdatesTrigger &&
            amountEntered !== sellAmount){
            
            if(this.state.dealType === 2){
                if(this.state.dealHeaderID === 0){
                    if(amountEntered <= this.state.forwardSellAmount - this.state.forwardSellTotalDisbursed){
                        sellAmount = amountEntered;
                    }
                }
                else{
                    if(amountEntered <= this.state.forwardSellAmount - this.state.forwardSellTotalDisbursed + this.state.originalSellAmount){
                        sellAmount = amountEntered;
                    }
                }
            }
            else{
                sellAmount = amountEntered;
            }
            this.setState({sellAmount: sellAmount,
                lockedSide: 'Sell',
            });
        }
    }

    handleUpdateSellAmountBlur = async () => {
        let sellAmount = this.state.sellAmount;

        if(this.state.rateQuoteID !== ''){
            let amounts = this.calculateAmounts(sellAmount, this.state.buyAmount, this.state.rateTerms, this.state.customerRate, this.state.lockedSide);
            let buyAmount = amounts.BuyAmount;
            let profitAmount = await this.getProfit(buyAmount, sellAmount, this.state.customerRate, this.state.marketRate, false);
            let profitAmountCcy = this.getProfitCCy(sellAmount, buyAmount, this.state.rateTerms, this.state.marketRate, this.state.lockedSide);
            this.setState({buyAmountEnableUpdatesTrigger: false,
                depositAmount: amounts.DepositAmount,
                depositAmountEnableUpdatesTrigger: false,
                depositPercentageEnableUpdatesTrigger: false,
                buyAmount: amounts.BuyAmount,
                nearLegProfit: profitAmount,
                profitAmount: profitAmount,
                profitAmountCcy: profitAmountCcy,
                nearLegProfitCcy : profitAmountCcy
            }, ()=>{
                    this.setState({buyAmountEnableUpdatesTrigger: true,
                        depositAmountEnableUpdatesTrigger: true,
                        depositPercentageEnableUpdatesTrigger: true,
                    });
                });
        }
    }

    handleUpdateFarLegSellAmount = async (event) => {
        let amountEntered = Number(event.target.value);
     this.setState({farLegSellAmount: amountEntered
            });
    }

    handleUpdateFarLegSellAmountBlur = async () => {

            let amountEntered = this.state.farLegSellAmount;

            let amounts = this.calculateAmounts(0, amountEntered, this.state.rateTerms, this.state.farLegCustomerRate, 'Buy');
            let profitAmount = await this.getProfit(amounts.BuyAmount, amounts.SellAmount, this.state.farLegCustomerRate, this.state.farLegMarketRate, true);
            let profitAmountCcy = this.getProfitCCy(amounts.SellAmount, amounts.BuyAmount, this.state.rateTerms, this.state.farLegMarketRate, this.state.lockedSide);
            
            this.setState({farLegEnableControlUpdates: false,
                farLegBuyAmount: amounts.SellAmount,
                farLegSellAmount: amountEntered,
                farLegProfit: profitAmount,
                farLegProfitCcy : profitAmountCcy
            }, () => {
                this.setState({farLegEnableControlUpdates: true});
            });
        
    }

    handleUpdateFarLegBuyAmount = async (event) => {
        let amountEntered = Number(event.target.value);
        this.setState({
                farLegBuyAmount: amountEntered
            },);
        
    }

    handleUpdateFarLegBuyAmountBlur = async (event) => {
        let amountEntered = this.state.farLegBuyAmount;
       let amounts = this.calculateAmounts(amountEntered, 0, this.state.rateTerms, this.state.farLegCustomerRate, 'Sell');
            let profitAmount = await this.getProfit(amounts.BuyAmount, amounts.SellAmount, this.state.farLegCustomerRate, this.state.farLegMarketRate, true);
            let profitAmountCcy = this.getProfitCCy(amounts.SellAmount, amounts.BuyAmount, this.state.rateTerms, this.state.farLegMarketRate, this.state.lockedSide);
            
            this.setState({farLegEnableControlUpdates: false,
                farLegBuyAmount: amountEntered,
                farLegSellAmount: amounts.BuyAmount,
                farLegProfit: profitAmount,
                farLegProfitCcy: profitAmountCcy
            }, () => {
                this.setState({farLegEnableControlUpdates: true});
            });        
    }

    handleUpdateSellCurrency = (obj) => {
        //alert('handleUpdateSellCurrency');
        if(obj !== undefined 
            && obj.value !== undefined
            && this.state.sellCurrencyEnableUpdatesTrigger){
            //Get currencies
            let sellCurrency = obj.value;
            let buyCurrency = this.state.buyCurrency;
            let sellCurrencyDecimals = this.getNumberOfDecimals(obj.value)

            //Update date selection  
            var startDate = this.state.startDate;
            var endDate = '';
            if(![4, 6].includes(this.state.dealType)){
                endDate = this.calculateEndDate(startDate, buyCurrency, sellCurrency);
            }
            
            //Get default value date
            let valueDate = this.getNextBusinessDate(this.state.startDate, 2, buyCurrency, sellCurrency);

            //Get and reset amounts
            var buyAmount = this.state.buyAmount;
            var sellAmount = this.state.sellAmount;
            if(this.state.lockedSide === 'Buy'){
                sellAmount = 0;
            }
            else{
                buyAmount = 0;
            }
            var maturityDate='';
            var forwardDays = 0;
            if(this.state.dealType == 4 || this.state.dealType == 6)
            {
                maturityDate = valueDate;
                valueDate = startDate;
                forwardDays = this.daysBetweenDates(valueDate, maturityDate);
                /*if(this.state.maturityDate != '' && this.state.maturityDate != undefined)
                {
                    forwardDays = this.daysBetweenDates(this.state.valueDate, this.state.maturityDate);
                    maturityDate= this.state.maturityDate;
                }
                else
                {
                    maturityDate = valueDate;
                }*/
            }

            //Get rate precision
            let ratePrecision = this.getRatePrecision(sellCurrency, buyCurrency);
            let limit = this.getRateLimit(sellCurrency, buyCurrency);

            this.setState({buyAmountEnableUpdatesTrigger: false,
                sellAmountEnableUpdatesTrigger: false,
                buyAmount: buyAmount,
                sellAmount: sellAmount,
                rateQuoteID: '',
                valueDate: valueDate,
                maturityDate: maturityDate,
                forwardDays: forwardDays,
                ratePrecision: ratePrecision,
                sellCurrency: sellCurrency,
                sellCurrencyDecimals: sellCurrencyDecimals,
                endDate: endDate,
                limit: limit, 
                useInterface: sellCurrency === buyCurrency ? false : this.state.useInterface
            }, ()=>{
                this.getRate(true);
                this.setState({buyAmountEnableUpdatesTrigger: true,
                    sellAmountEnableUpdatesTrigger: true,
                });
            });
        }
    }

    handleUpdateBuyAmount = (control) => {
        var buyAmount = this.state.buyAmount;
        var amountEntered = Number(control.target.value);

        if(this.state.buyAmountEnableUpdatesTrigger
            && buyAmount !== amountEntered){
            
            if(this.state.dealType === 2){
                if(this.state.dealHeaderID === 0){
                    if(amountEntered <= this.state.forwardBuyAmount - this.state.forwardBuyTotalDisbursed){
                        buyAmount = amountEntered;
                    }
                }
                else{
                    if(amountEntered <= this.state.forwardBuyAmount - this.state.forwardBuyTotalDisbursed + this.state.originalBuyAmount){
                        buyAmount = amountEntered;
                    }
                }
            }
            else{
                buyAmount = amountEntered;
            }
            this.setState({buyAmount: buyAmount,
                lockedSide: 'Buy',
            });
        }
    }

    handleUpdateBuyAmountBlur = async() =>{
        let buyAmount = this.state.buyAmount;
         
            if(this.state.rateQuoteID !== ''){
                let amounts = this.calculateAmounts(this.state.sellAmount, buyAmount, this.state.rateTerms, this.state.customerRate, this.state.lockedSide)
                let sellAmount = amounts.SellAmount;
                let profitAmount = await this.getProfit(buyAmount, sellAmount, this.state.customerRate, this.state.marketRate, false);
                let profitAmountCcy = this.getProfitCCy(sellAmount, buyAmount, this.state.rateTerms, this.state.marketRate, this.state.lockedSide);
                this.setState({sellAmountEnableUpdatesTrigger: false,
                    depositAmount: amounts.DepositAmount,
                    depositAmountEnableUpdatesTrigger: false,
                    depositPercentageEnableUpdatesTrigger: false,
                    nearLegProfit: profitAmount,
                    profitAmount: profitAmount,
                    profitAmountCcy: profitAmountCcy,
                    nearLegProfitCcy: profitAmountCcy,
                    sellAmount: sellAmount,
                }, ()=>{
                    this.setState({sellAmountEnableUpdatesTrigger: true,
                        depositAmountEnableUpdatesTrigger: true,
                        depositPercentageEnableUpdatesTrigger: true
                    });
                });
            }
    }
    
    handleUpdateBuyCurrency = (obj) => {
        //alert('handleUpdateBuyCurrency:' + JSON.stringify(obj));
        if(obj !== undefined 
            && this.state.buyCurrencyEnableUpdatesTrigger
            && obj.value !== undefined){
            //Get currencies
            let buyCurrency = obj.value;
            let sellCurrency = this.state.sellCurrency;
            let buyCurrencyDecimals = this.getNumberOfDecimals(obj.value);

            //Update date selectio
            var startDate = this.state.startDate;
            var endDate = '';
            if(![4, 6].includes(this.state.dealType)){
                endDate = this.calculateEndDate(startDate, buyCurrency, sellCurrency);
            }

            //Get default value date
            let valueDate = this.getNextBusinessDate(this.state.startDate, 2, buyCurrency, sellCurrency);

            //Get and reset amounts
            var buyAmount = this.state.buyAmount;
            var sellAmount = this.state.sellAmount;
            if(this.state.lockedSide === 'Buy'){
                sellAmount = 0;
            }
            else{
                buyAmount = 0;
            }

            var depositCurrency = this.state.depositCurrency;
            if(this.state.depositID === 0){
                depositCurrency = buyCurrency;
            }

            var maturityDate='';
            var forwardDays = 0;
            
            if(this.state.dealType == 4 || this.state.dealType == 6)
            {
                maturityDate = valueDate;
                valueDate = startDate;
                forwardDays = this.daysBetweenDates(valueDate, maturityDate);
                /*if(this.state.maturityDate != '' && this.state.maturityDate != undefined)
                {
                    forwardDays = this.daysBetweenDates(this.state.valueDate, this.state.maturityDate);
                    maturityDate= this.state.maturityDate;
                }
                else
                {
                    maturityDate = valueDate;
                }*/
            }
            //Get rate precision
            let ratePrecision = this.getRatePrecision(sellCurrency, buyCurrency);
            let limit = this.getRateLimit(sellCurrency, buyCurrency);
            
            this.setState({buyAmountEnableUpdatesTrigger: false,
                depositCurrencyEnableUpdatesTrigger: false,
                depositCurrency: depositCurrency,
                sellAmountEnableUpdatesTrigger: false,
                buyAmount: buyAmount,
                sellAmount: sellAmount,
                ratePrecision: ratePrecision,
                rateQuoteID: '',
                valueDate: valueDate,
                maturityDate: maturityDate,
                forwardDays: forwardDays,
                buyCurrency: buyCurrency,
                buyCurrencyDecimals: buyCurrencyDecimals,
                endDate: endDate,
                limit: limit,
                useInterface: sellCurrency === buyCurrency ? false : this.state.useInterface
            }, ()=>{ 
                this.getRate(true);
                this.setState({buyAmountEnableUpdatesTrigger: true,
                    sellAmountEnableUpdatesTrigger: true,
                    depositCurrencyEnableUpdatesTrigger: true,
                });
            });
        }
    }

    handleUpdateForwardType = (event) => {
        var valueDate = this.state.valueDate;
        var forwardDays = this.state.forwardDays;
        var maturityDate = this.state.maturityDate;
        var valueDateDisabled = false;
        if(event.target.value === 'Closed'){
            forwardDays = 0;
            maturityDate = new Date(localStorage.getItem('SystemDate'));
            valueDate = new Date(localStorage.getItem('SystemDate'));
            valueDateDisabled = true;
        }
        this.setState({
            forwardDays: forwardDays,
            forwardType: event.target.value,
            maturityDate: maturityDate,
            valueDate: valueDate,
            valueDateDisabled: valueDateDisabled,
            forwardDaysDisabled: valueDateDisabled
        });
    }

    handleUpdateRequireDeposit = (event) => {
        if(!this.state.enableDepositControls){
            this.setState({requireDeposits: event.target.checked});
        } else {
            this.setState({requireDeposits: event.target.checked,
                depositPercentageDisabled: false,
                depositAmountDisabled: false,
                depositCurrencyDisabled: false});
        }
    }

    handleUpdateDepositPercentage = (event) => {
        if(this.state.depositPercentageEnableUpdatesTrigger){
            let depositPercent = Number(event.target.value);

            this.setState({
                depositPercent: depositPercent
            });
        }
    }

    handleUpdateDepositPercentageBlur = (event) => {
            let depositPercent = this.state.depositPercent;
            var depositAmount = this.state.depositAmount;
            /*if(this.state.depositID === 0){
                depositAmount = (this.state.buyAmount * depositPercent / 100).toFixed(0);
            }   */     
            depositAmount = ((this.state.buyAmount * depositPercent) / 100).toFixed(getScaleByCurrency(this.state.depositCurrency));
            this.setState({depositAmountEnableUpdatesTrigger: false,
                depositPercent: depositPercent,
                depositAmount: depositAmount
            }, () => {
                this.setState({depositAmountEnableUpdatesTrigger: true});
            });
    }


    handleUpdateDepositAmount = (event) => {
        if(this.state.depositAmountEnableUpdatesTrigger){
            let depositAmount = Number(event.target.value);
            this.setState({
                depositAmount: depositAmount
            });
        }
    }

    handleUpdateDepositAmountBlur = (event) => {
            let depositAmount = this.state.depositAmount;
            let depositPercent = ((depositAmount / this.state.buyAmount) * 100).toFixed(getScaleByCurrency(this.state.depositCurrency));
            this.setState({depositPercentageEnableUpdatesTrigger:false,
                depositAmount: depositAmount,
                depositPercent: depositPercent,
            }, () => {
                this.setState({depositPercentageEnableUpdatesTrigger: true});
            });
        
    }


    handleUpdateDepositCurrency = (obj) => {
        if(obj !== undefined &&
            obj.value !== undefined &&
            this.state.depositCurrencyEnableUpdatesTrigger){
            this.setState({depositCurrency: obj.value});
        }        
    }

    daysBetweenDates(date1, date2){
        var result = 0;
        if(date1 !== '' && date2 !== '' && date1 !== undefined && date2 !== undefined){
            // To calculate the time difference of two dates 
            let date2Date = new Date(date2);
            let date1Date = new Date(date1);

            var Difference_In_Time = date2Date.getTime() - date1Date.getTime(); 
            
            // To calculate the no. of days between two dates 
            result = ( Difference_In_Time / (1000 * 3600 * 24) ).toFixed(0);
        }  
        
        return result;
    }

    handleUpdateValueDate = (date) => {
        //Calculate forward days
        var forwardDays = this.state.forwardDays;
        var maturityDate = this.state.maturityDate;
        var startDateMaturity = this.state.startDateMaturity;
        var endDate = this.state.endDate;
        if(this.state.dealType === 4)
        {
            if(
            this.state.maturityDate !== '' &&
            this.state.maturityDate !== undefined){
                if(this.state.forwardType == 'Open')
                {
                    startDateMaturity = date;
                    endDate = this.getNextBusinessDateForward(date, this.props.maxForwardWindow);
                }
            forwardDays = this.daysBetweenDates(date, this.state.maturityDate);            
        }
        }
        else{
            if(forwardDays > 0){
                maturityDate = new Date();
                maturityDate.setDate(date.getDate() + forwardDays);
            }
        }

        //Clear rateQuoteID only if it is not a drawdown
        var rateQuoteID = this.state.rateQuoteID;
        if(this.state.dealType !== 2){
            rateQuoteID = '';
        }
        this.setState({forwardDaysEnableUpdatesTrigger: false,
            maturityDate: maturityDate,
            valueDate: date, 
            rateQuoteID:rateQuoteID,
            forwardDays: forwardDays,
            endDate: endDate,
            startDateMaturity: startDateMaturity
        }, () => {this.getRate(true);
            this.setState({forwardDaysEnableUpdatesTrigger: true});
        });
    }

    handleUpdateForwardDays = (event) =>{
        if(this.state.forwardDaysEnableUpdatesTrigger){
            let forwardDays = Number(event.target.value);
            this.setState({forwardDays: forwardDays
               // rateQuoteID: '',
            });            
        }        
    }

    updateForwardDaysBlur = (event) => {
        let forwardDays = this.state.forwardDays;
        var valueDate = new Date(this.state.valueDate);
        var maturityDate = '';
        if(valueDate !== '' && valueDate !== undefined){
            maturityDate = new Date(this.state.valueDate);
            maturityDate.setDate(maturityDate.getDate() + forwardDays);
            //Handle weekend and/or holidays
            var holidays = [];
            for(var i=0; i<this.props.holidays.length; i++){
                if(this.props.holidays[i].CurrencyID === 'ANY' ||
                this.props.holidays[i].CurrencyID === this.state.sellCurrency ||
                this.props.holidays[i].CurrencyID === this.state.buyCurrency){
                    holidays.push(this.props.holidays[i].Date);
                }
            }

            var additionalDays = 0;
            while(maturityDate.getDay() === 6 ||
                maturityDate.getDay() === 0 ||
                holidays.includes(formatDate(maturityDate))){
                maturityDate.setDate(maturityDate.getDate() + 1);  
                additionalDays++;
            }
            forwardDays += additionalDays;
        }
        this.setState({forwardDaysEnableUpdatesTrigger: false,
            forwardDays: forwardDays,
            maturityDate: maturityDate},
            ()=>{
                this.getRate(true);
                this.setState({forwardDaysEnableUpdatesTrigger: true});
            });
    }

    handleUpdateMaturityDate = (date) => {
        var valueDate = this.state.valueDate;
        if(this.state.forwardType === 'Closed'){
            valueDate = date;
        }
        let forwardDays = this.daysBetweenDates(valueDate, date);
      
        let rateQuoteID='';
        if(this.state.editForwardDate)
        {
            rateQuoteID = this.state.rateQuoteID;
        }
        this.setState({forwardDaysEnableUpdatesTrigger: false,
            maturityDate: date, 
            rateQuoteID:rateQuoteID,
            forwardDays: forwardDays,
            valueDate: valueDate,
        }, () => {this.getRate(true);
            this.setState({forwardDaysEnableUpdatesTrigger: true}
                );
        });
    }

    handleUpdateMarketRate = async (event) => {
        if(isNaN(event.target.value)){
            return false;
        }

        let marketRate = event.target.value;
        if(this.state.marketRateEnableUpdatesTrigger
            && marketRate !== this.state.marketRate){
             
                this.setState({marketRate: marketRate});            
        }
    }

    handleValidateMarketRate = async (event)  => {       
        let marketRate = this.state.marketRate;
        let oldMarketRate = this.state.oldMarketRate;  
        //let oldMarketRate = this.state.marketRate;
        let customerRate = this.state.customerRate;

        //Spread points and percentage
        let profitPoints = Math.abs(customerRate - marketRate);
        var scaleCCYPair = getScaleForCurrencyPair(this.state.buyCurrency, this.state.sellCurrency);
        let spreadPoints = (profitPoints * 10000).toFixed(scaleCCYPair);
        var spreadPercentage = 0;
        if(Number(marketRate) !== 0){
            spreadPercentage = ((profitPoints / marketRate) * 100).toFixed(2);
        }

        let buyAmount = this.state.buyAmount;
        let sellAmount = this.state.sellAmount;

        let profitAmount = await this.getProfit(buyAmount, sellAmount, customerRate, marketRate, false);
        let profitAmountCcy = this.getProfitCCy(sellAmount, buyAmount, this.state.rateTerms, marketRate, this.state.lockedSide);
        this.setState({marketRate: marketRate,
            spreadPoints: spreadPoints,
            spreadPercentage: spreadPercentage,
            profitAmount: profitAmount,
            profitAmountCcy: profitAmountCcy,
            oldMarketRate: oldMarketRate
        });

         var validRate = this.validateRateLimits(this.state.originalMarketRate, marketRate);
     }

    //just show a warning, let the user enter the values
    validateRateLimits = (originalRate, rate) => {
        //Get rate precision
        let ratePrecision = this.getRatePrecision(this.state.sellCurrency, this.state.buyCurrency);

        let marginError = this.state.limit / 100;
        let limit = Number((originalRate * marginError).toFixed(ratePrecision));
        let upperLimit = Number((originalRate + limit).toFixed(ratePrecision));
        let lowerLimit = Number((originalRate - limit).toFixed(ratePrecision));

        if (rate > upperLimit || rate < lowerLimit) {
            var alertMessage = 'Rate input is out of range. Rate has to be between ' + lowerLimit + ' and ' + upperLimit;
            var alertTitle = 'Warning';

            this.setState({ alertTitle: alertTitle, alertMessage: alertMessage, showAlert: true });
            return false;
        }
        else {
            return true;
        }
    }

    handleUpdateUseInterface = (event) => {        
        this.setState({
            rateQuoteID: event.target.checked ? '' : this.state.rateQuoteIDNoInterface,
            rateQuoteIDNoInterface: event.target.checked ? this.state.rateQuoteID : '',
            useInterface: event.target.checked,
        });
    }

    handleUpdateCoverCustomer = (obj) => {
        if(obj!== undefined 
            && obj.value !== undefined){
            let coverCustomerID = 0;
            if (obj.InterfaceType === "AutoCover") {
                coverCustomerID = Number(obj.value);
            }
            let objCoverBank = this.state.bankCustomers.find(function(element){
                return Number(element.CustomerID) === Number(obj.value);
            });
            var externalInterface = '', externalInterfaceType = '';
            var useInterface = false;
            //var disableCoverCustomer = false;
            if(objCoverBank !== undefined){
                externalInterface = objCoverBank.BankInterface;
                externalInterfaceType = objCoverBank.InterfaceType;
                if(objCoverBank.BankInterface !== ''){
                    useInterface = this.state.useInterface;
                    //disableCoverCustomer = true;
                }
            } 
            this.setState({
                coverCustomerID: coverCustomerID,
                externalInterface: externalInterface,
                externalInterfaceType: externalInterfaceType,
                //rateQuoteID: '',
                useInterfaceDisabled: useInterface,
                useInterface: useInterface,
            });
        }
    }

    handleUpdateNearLegOriginalMarketRate = async (event) => {
        let nearLegOriginalMarketSpotRate = Number(event.target.value);               
        if (nearLegOriginalMarketSpotRate === 0) {
            this.setState({ nearLegOriginalMarketSpotRate: 0 })            
        }
        else if(this.state.nearLegEnableControlUpdates
            && nearLegOriginalMarketSpotRate !== this.state.nearLegOriginalMarketSpotRate){
                this.setState({ nearLegOriginalMarketSpotRate: nearLegOriginalMarketSpotRate })  
        }
    }

    handleValidateNearLegOriginalMarketRate = async (event) => {        
        let nearLegOriginalMarketSpotRate = this.state.nearLegOriginalMarketSpotRate;
        let oldMarketSpotRate = this.state.oldMarketSpotRate;

        if(nearLegOriginalMarketSpotRate > 0) {
    
            let nearLegMarketRate = roundAmountByCurrencyPair(Number(nearLegOriginalMarketSpotRate + ( this.state.nearLegForwardPoints / 10000 )), this.state.buyCurrency, this.state.sellCurrency)
            let nearLegSpread = Number(((this.state.nearLegCustomerRate - nearLegMarketRate) * 10000).toFixed(2));
            // set rates nearLegMarketRate
            let profitAmount = 0; //await this.getProfit(this.state.buyAmount, this.state.sellAmount, this.state.nearLegCustomerRate, nearLegMarketRate, false);
            let profitAmountCcy = 0;//this.getProfitCCy(this.state.sellAmount, this.state.buyAmount, this.state.rateTerms, nearLegMarketRate, this.state.lockedSide);
            
            var customerRate = this.state.customerRate;
            var marketRate =  this.state.marketRate;
            if(this.state.dealType == 6)
            {
                customerRate = this.state.nearLegCustomerRate;
                marketRate = nearLegMarketRate;
            }

            this.setState({
                nearLegEnableControlUpdates: false,
                nearLegMarketRate: nearLegMarketRate,
                nearLegOriginalMarketSpotRate: nearLegOriginalMarketSpotRate,
                nearLegProfit: profitAmount,
                nearLegProfitCcy: profitAmountCcy,
                nearLegSpread: nearLegSpread,
                customerRate: customerRate,
                marketRate: marketRate,
                oldMarketSpotRate: oldMarketSpotRate,
                //by default 0 fwdpoints and spreads y id mktrate is entered the customerrate if the same
                nearLegForwardPoints: 0,                
                nearLegSpread: 0,
                nearLegCustomerRate: nearLegMarketRate
            }, () => {
                this.setState({ nearLegEnableControlUpdates: true });
            });
        }
        var validRate = this.validateRateLimits(this.state.originalMarketSpotRate, nearLegOriginalMarketSpotRate);
    }

    handleUpdateNearLegForwardPoints = async (event) => {
        let nearLegForwardPoints = Number(event.target.value);  
        if (nearLegForwardPoints === 0) {
            let nearLegMarketRate = roundAmountByCurrencyPair(Number(this.state.nearLegOriginalMarketSpotRate), this.state.buyCurrency, this.state.sellCurrency);
            this.setState({ nearLegForwardPoints: 0, nearLegMarketRate: nearLegMarketRate })
        }
        else if(this.state.nearLegEnableControlUpdates
            && nearLegForwardPoints !== this.state.nearLegForwardPoints)
        {            
            let nearLegMarketRate = roundAmountByCurrencyPair(Number(this.state.nearLegOriginalMarketSpotRate) + (Number(nearLegForwardPoints) / 10000), this.state.buyCurrency, this.state.sellCurrency);            
            let nearLegSpread = Number(((this.state.nearLegCustomerRate - nearLegMarketRate) * 10000).toFixed(2));
            let profitAmount = await this.getProfit(this.state.buyAmount, this.state.sellAmount, this.state.nearLegCustomerRate, nearLegMarketRate, false);
            let profitAmountCcy = this.getProfitCCy(this.state.sellAmount, this.state.buyAmount, this.state.rateTerms, nearLegMarketRate, this.state.lockedSide);
            
            var customerRate = this.state.customerRate;
            var marketRate =  this.state.marketRate;
            if(this.state.dealType == 6)
            {
                customerRate = this.state.nearLegCustomerRate;
                marketRate = nearLegMarketRate;
            }


            this.setState({
                nearLegEnableControlUpdates: false,
                nearLegForwardPoints: nearLegForwardPoints,
                nearLegMarketRate: nearLegMarketRate,
                nearLegProfit: profitAmount,
                nearLegProfitCcy: profitAmountCcy,
                nearLegSpread: nearLegSpread,
                customerRate: customerRate,
                marketRate: marketRate
            }, () => {
                this.setState({nearLegEnableControlUpdates: true});
            });
        }
    }

    handleUpdateFarLegOriginalMarketRate = async (event) => {
        let farLegOriginalMarketSpotRate = Number(event.target.value);
        if (farLegOriginalMarketSpotRate === 0) {
            this.setState({ farLegOriginalMarketSpotRate: 0 })            
        } else if(this.state.farLegEnableControlUpdates
            && farLegOriginalMarketSpotRate !== this.state.farLegOriginalMarketSpotRate){
                this.setState({ farLegOriginalMarketSpotRate: farLegOriginalMarketSpotRate })  
        }
    }

    handleValidateFarLegOriginalMarketRate = (event) => {
        
        let farLegOriginalMarketSpotRate = this.state.farLegOriginalMarketSpotRate;
        let oldFarMarketSpotRate = this.state.oldFarMarketSpotRate;
        if(farLegOriginalMarketSpotRate > 0)
        {
            let farLegMarketRate = roundAmountByCurrencyPair(Number(farLegOriginalMarketSpotRate) + Number(this.state.farLegForwardPoints / 10000), this.state.buyCurrency, this.state.sellCurrency);
            let farLegSpread = Number(((this.state.farLegCustomerRate - farLegMarketRate) * 10000).toFixed(2));
            // set rates farLegMarketRate
            let profitAmount = 0;//await this.getProfit(this.state.farLegSellAmount, this.state.farLegBuyAmount, this.state.farLegCustomerRate, farLegMarketRate, true);
            let profitAmountCcy = 0; //this.getProfitCCy(this.state.farLegBuyAmount, this.state.farLegSellAmount, this.state.rateTerms, farLegMarketRate, this.state.lockedSide);

            this.setState({farLegEnableControlUpdates: false,
                farLegMarketRate: farLegMarketRate,
                farLegOriginalMarketSpotRate: farLegOriginalMarketSpotRate,
                farLegProfit: profitAmount,
                farLegProfitCcy: profitAmountCcy,
                farLegSpread: farLegSpread,
                oldFarMarketSpotRate: oldFarMarketSpotRate,
                //by default 0 fwdpoints and spreads y id mktrate is entered the customerrate if the same                               
                farLegSpread: 0,
                farLegForwardPoints: 0,  
                farLegCustomerRate: farLegMarketRate
            }, () => {
                this.setState({farLegEnableControlUpdates: true});
            });
        }
        var validRate = this.validateRateLimits(this.state.originalFarMarketSpotRate, farLegOriginalMarketSpotRate);
    }

    handleUpdateFarLegForwardPoints = async (event) => {
        let farLegForwardPoints = Number(event.target.value);
        if (farLegForwardPoints === 0) {
            let farLegMarketRate = roundAmountByCurrencyPair(Number(this.state.farLegOriginalMarketSpotRate), this.state.buyCurrency, this.state.sellCurrency);
            this.setState({ farLegForwardPoints: 0, farLegMarketRate: farLegMarketRate })            
        } else if (this.state.farLegEnableControlUpdates
            && farLegForwardPoints !== this.state.farLegForwardPoints)
        {
            let farLegMarketRate = roundAmountByCurrencyPair(Number(this.state.farLegOriginalMarketSpotRate) + Number(farLegForwardPoints / 10000), this.state.buyCurrency, this.state.sellCurrency);
            let farLegSpread = Number(((this.state.farLegCustomerRate - farLegMarketRate) * 10000).toFixed(2));
            let profitAmount = await this.getProfit(this.state.farLegSellAmount, this.state.farLegBuyAmount, this.state.farLegCustomerRate, farLegMarketRate, true);
            let profitAmountCcy = this.getProfitCCy(this.state.farLegBuyAmount, this.state.farLegSellAmount, this.state.rateTerms, farLegMarketRate, this.state.lockedSide);

            this.setState({
                farLegEnableControlUpdates: false,
                farLegForwardPoints: farLegForwardPoints,
                farLegMarketRate: farLegMarketRate,
                farLegProfit: profitAmount,
                farLegProfitCcy: profitAmountCcy,
                farLegSpread: farLegSpread,
            }, () => {
                this.setState({farLegEnableControlUpdates: true});
            });
        }
    }

    handleUpdateNearLegMarketRate = async (event) => {
        let nearLegMarketRate = Number(event.target.value);
        if (nearLegMarketRate === 0) {
            this.setState({ nearLegMarketRate: 0 })
        }
        else if (this.state.nearLegEnableControlUpdates
            && nearLegMarketRate !== this.state.nearLegMarketRate) {
                this.setState({ nearLegMarketRate: nearLegMarketRate })
        }
    }

    handleValidateNearLegMarketRate = async (event) => {
        
        let nearLegMarketRate = this.state.nearLegMarketRate;
        let oldMarketRate = this.state.oldMarketRate;

        if(nearLegMarketRate > 0)
        {
            let nearLegSpread = Number(((this.state.nearLegCustomerRate - nearLegMarketRate) * 10000).toFixed(2));
            let profitAmount = await this.getProfit(this.state.buyAmount, this.state.sellAmount, this.state.nearLegCustomerRate, nearLegMarketRate, false);
            let profitAmountCcy = this.getProfitCCy(this.state.sellAmount, this.state.buyAmount, this.state.rateTerms, nearLegMarketRate, this.state.lockedSide);

            var customerRate = this.state.customerRate;
            var marketRate = this.state.marketRate;
            if (this.state.dealType == 6) {
                customerRate = this.state.nearLegCustomerRate;
                marketRate = nearLegMarketRate;
            }

            this.setState({
                nearLegEnableControlUpdates: false,
                nearLegOriginalMarketSpotRate: nearLegMarketRate,
                nearLegForwardPoints: 0,
                nearLegMarketRate: nearLegMarketRate,
                nearLegProfit: profitAmount,
                nearLegProfitCcy: profitAmountCcy,
                nearLegSpread: nearLegSpread,
                customerRate: customerRate,
                marketRate: marketRate,
                oldMarketRate: oldMarketRate
            }, () => {
                this.setState({ nearLegEnableControlUpdates: true });
            });
        }

        var validRate = this.validateRateLimits(this.state.originalMarketRate, nearLegMarketRate);
    }

    handleUpdateFarLegMarketRate = async (event) => {
        let farLegMarketRate = Number(event.target.value);
        if (farLegMarketRate === 0) {
            this.setState({ farLegMarketRate: 0 })
        } else if (this.state.farLegEnableControlUpdates
            && farLegMarketRate !== this.state.farLegMarketRate) {
                this.setState({ farLegMarketRate: farLegMarketRate });
        }
    }

    handleValidateFarLegMarketRate = async (event) => {        
        let farLegMarketRate = this.state.farLegMarketRate;
        let oldFarMarketRate = this.state.oldFarMarketRate;

        if(farLegMarketRate > 0)
        {
            let farLegSpread = Number(((this.state.farLegCustomerRate - farLegMarketRate) * 10000).toFixed(2));
            let profitAmount = await this.getProfit(this.state.farLegSellAmount, this.state.farLegBuyAmount, this.state.farLegCustomerRate, farLegMarketRate, true);
            let profitAmountCcy = this.getProfitCCy(this.state.farLegBuyAmount, this.state.farLegSellAmount, this.state.rateTerms, farLegMarketRate, this.state.lockedSide);

            this.setState({
                farLegEnableControlUpdates: false,
                farLegOriginalMarketSpotRate: farLegMarketRate,
                farLegForwardPoints: 0,
                farLegMarketRate: farLegMarketRate,
                farLegProfit: profitAmount,
                farLegProfitCcy: profitAmountCcy,
                farLegSpread: farLegSpread,
                oldFarMarketRate: oldFarMarketRate
            }, () => {
                this.setState({ farLegEnableControlUpdates: true });
            });
        }

        var validRate = this.validateRateLimits(this.state.originalFarMarketRate, farLegMarketRate);
    }

    handleUpdateNearLegSpread = async (event) => {
        let nearLegSpread = Number(event.target.value);
        if (this.state.nearLegEnableControlUpdates
            && nearLegSpread !== this.state.nearLegSpread){
                this.setState({ nearLegSpread: nearLegSpread});
        }
    }

    handleUpdateNearLegSpreadBlur = async (event) => {
        let nearLegSpread = this.state.nearLegSpread;
        if (nearLegSpread === 0) {
            let nearLegCustomerRate = roundAmountByCurrencyPair(Number(this.state.nearLegOriginalMarketSpotRate) + Number(this.state.nearLegForwardPoints / 10000), this.state.buyCurrency, this.state.sellCurrency);
            this.setState({ nearLegSpread: 0, nearLegCustomerRate: nearLegCustomerRate })            
        } else {
            let nearLegCustomerRate = roundAmountByCurrencyPair(Number(this.state.nearLegMarketRate) + Number(nearLegSpread / 10000), this.state.buyCurrency, this.state.sellCurrency);
            let amounts = this.calculateAmounts(this.state.sellAmount, this.state.buyAmount, this.state.rateTerms, nearLegCustomerRate, this.state.lockedSide);
            let profitAmount = await this.getProfit(amounts.BuyAmount, amounts.SellAmount, nearLegCustomerRate, this.state.nearLegMarketRate, false);
            let profitAmountCcy = this.getProfitCCy(amounts.SellAmount, amounts.BuyAmount, this.state.rateTerms, this.state.nearLegMarketRate, this.state.lockedSide);
            
            var customerRate = this.state.customerRate;
            var marketRate =  this.state.marketRate;
            if(this.state.dealType == 6)
            {
                customerRate = nearLegCustomerRate;
                marketRate = this.state.nearLegMarketRate;
            }

            this.setState({buyAmountEnableUpdatesTrigger: false,
                nearLegEnableControlUpdates: false,
                sellAmountEnableUpdatesTrigger: false,
                
                buyAmount: amounts.BuyAmount,
                nearLegCustomerRate: nearLegCustomerRate,
                nearLegProfit: profitAmount,
                nearLegProfitCcy: profitAmountCcy,
                nearLegSpread: nearLegSpread,
                sellAmount: amounts.SellAmount,
                customerRate: customerRate,
                marketRate: marketRate
            }, () => {
                this.setState({buyAmountEnableUpdatesTrigger: true,
                    nearLegEnableControlUpdates: true,
                    sellAmountEnableUpdatesTrigger: true,
                });
            })
        }
    }


    handleUpdateFarLegSpread = async (event) => {
        
        let farLegSpread = Number(event.target.value);
        if(this.state.farLegEnableControlUpdates
            && farLegSpread !== this.state.farLegSpread){
            this.setState({ farLegSpread: farLegSpread});
        }
    }

    handleUpdateFarLegSpreadBlur = async (event) => {
        let farLegSpread = this.state.farLegSpread;
        if (farLegSpread === 0) {
            let farLegCustomerRate = roundAmountByCurrencyPair(Number(this.state.farLegOriginalMarketSpotRate) + Number(this.state.farLegForwardPoints / 10000),this.state.buyCurrency, this.state.sellCurrency);
            this.setState({ farLegSpread: 0, farLegCustomerRate: farLegCustomerRate })            
        } else {
            let farLegCustomerRate = roundAmountByCurrencyPair(Number(this.state.farLegMarketRate) + Number(farLegSpread / 10000),this.state.buyCurrency, this.state.sellCurrency);
            let amounts = this.calculateAmounts(this.state.farLegBuyAmount, this.state.farLegSellAmount, this.state.rateTerms, farLegCustomerRate, this.state.lockedSide);
            let profitAmount = await this.getProfit(amounts.BuyAmount, amounts.SellAmount, farLegCustomerRate, this.state.farLegMarketRate, true);
            let profitAmountCcy = this.getProfitCCy(amounts.SellAmount, amounts.BuyAmount, this.state.rateTerms, this.state.farLegMarketRate, this.state.lockedSide);

            this.setState({farLegEnableControlUpdates: false,
                farLegBuyAmount: amounts.SellAmount,
                farLegCustomerRate: farLegCustomerRate,
                farLegProfit: profitAmount,
                farLegProfitCcy: profitAmountCcy,
                farLegSellAmount: amounts.BuyAmount,
                farLegSpread: farLegSpread,
            }, () => {
                this.setState({farLegEnableControlUpdates: true});
            })
        }
    }


    handleUpdateNearLegCustomerRate = async (event) => {
        let nearLegCustomerRate = Number(event.target.value);
        if (nearLegCustomerRate === 0) {
            this.setState({ nearLegCustomerRate: 0 })
        }
        else {
            this.setState({ nearLegCustomerRate: nearLegCustomerRate });
        }
        /*if(this.state.nearLegEnableControlUpdates
            && nearLegCustomerRate !== this.state.nearLegCustomerRate){
            let oldCustomerRate = this.state.nearLegCustomerRate;
            let nearLegSpread = Number(((nearLegCustomerRate - this.state.nearLegMarketRate) * 10000).toFixed(2));
            let amounts = this.calculateAmounts(this.state.sellAmount, this.state.buyAmount, this.state.rateTerms, nearLegCustomerRate, this.state.lockedSide);
            let profitAmount = await this.getProfit(amounts.BuyAmount, amounts.SellAmount, nearLegCustomerRate, this.state.nearLegMarketRate, false);
            
            var customerRate = this.state.customerRate;
            var marketRate =  this.state.marketRate;
            if(this.state.dealType == 6)
            {
                customerRate = nearLegCustomerRate;
                marketRate = this.state.nearLegMarketRate;
            }

            this.setState({buyAmountEnableUpdatesTrigger: false,
                nearLegEnableControlUpdates: false,
                sellAmountEnableUpdatesTrigger: false,

                buyAmount: amounts.BuyAmount,
                nearLegCustomerRate: nearLegCustomerRate,
                nearLegProfit: profitAmount,
                nearLegSpread: nearLegSpread,
                sellAmount: amounts.SellAmount,
                customerRate: customerRate,
                marketRate: marketRate,
                oldCustomerRate: oldCustomerRate
            }, () => {
                    this.setState({buyAmountEnableUpdatesTrigger: true,
                        nearLegEnableControlUpdates: true,
                        sellAmountEnableUpdatesTrigger: true,
                });
            });
        }*/
    }

    handleValidateNearLegCustomerRate = async (event) => {        
        let nearLegCustomerRate = this.state.nearLegCustomerRate;
        
        let oldCustomerRate = this.state.nearLegCustomerRate;
            let nearLegSpread = Number(((nearLegCustomerRate - this.state.nearLegMarketRate) * 10000).toFixed(2));
            let amounts = this.calculateAmounts(this.state.sellAmount, this.state.buyAmount, this.state.rateTerms, nearLegCustomerRate, this.state.lockedSide);
            let profitAmount = await this.getProfit(amounts.BuyAmount, amounts.SellAmount, nearLegCustomerRate, this.state.nearLegMarketRate, false);
            let profitAmountCcy = this.getProfitCCy(amounts.SellAmount, amounts.BuyAmount, this.state.rateTerms, this.state.nearLegMarketRate, this.state.lockedSide);

            var customerRate = this.state.customerRate;
            var marketRate =  this.state.marketRate;
            if(this.state.dealType == 6)
            {
                customerRate = nearLegCustomerRate;
                marketRate = this.state.nearLegMarketRate;
            }

            this.setState({buyAmountEnableUpdatesTrigger: false,
                nearLegEnableControlUpdates: false,
                sellAmountEnableUpdatesTrigger: false,

                buyAmount: amounts.BuyAmount,
                nearLegCustomerRate: nearLegCustomerRate,
                nearLegProfit: profitAmount,
                nearLegSpread: nearLegSpread,
                sellAmount: amounts.SellAmount,
                customerRate: customerRate,
                marketRate: marketRate,
                oldCustomerRate: oldCustomerRate
            }, () => {
                    this.setState({buyAmountEnableUpdatesTrigger: true,
                        nearLegEnableControlUpdates: true,
                        sellAmountEnableUpdatesTrigger: true,
                });
                this.validateRateLimits(this.state.originalCustomerRate, nearLegCustomerRate);
            });
    }

    handleUpdateFarLegCustomerRate = async (event) => {
        let farLegCustomerRate = Number(event.target.value);
        if (farLegCustomerRate === 0) {
            this.setState({ farLegCustomerRate: 0 })            
        } else {
            this.setState({ farLegCustomerRate: farLegCustomerRate });
        }

       /* if(this.state.farLegEnableControlUpdates
            && farLegCustomerRate !== this.state.farLegCustomerRate){
            let oldFarCustomerRate = this.state.farLegCustomerRate;
            let farLegSpread = ((farLegCustomerRate - this.state.farLegMarketRate) * 10000).toFixed(2);
            let amounts = this.calculateAmounts(this.state.farLegBuyAmount, this.state.farLegSellAmount, this.state.rateTerms, farLegCustomerRate, this.state.lockedSide);
            let profitAmount = await this.getProfit(amounts.BuyAmount, amounts.SellAmount, farLegCustomerRate, this.state.farLegMarketRate, true);
            this.setState({farLegEnableControlUpdates: false,
                farLegBuyAmount: amounts.SellAmount,
                farLegCustomerRate: farLegCustomerRate,
                farLegProfit: profitAmount,
                farLegSellAmount: amounts.BuyAmount,
                farLegSpread: farLegSpread,
                oldFarCustomerRate: oldFarCustomerRate
            }, () => {
                this.setState({farLegEnableControlUpdates: true});
            });
        }*/
    }

    handleValidateFarLegCustomerRate = async (event) => {        
        let farLegCustomerRate = this.state.farLegCustomerRate;
        
        let oldFarCustomerRate = this.state.farLegCustomerRate;
            let farLegSpread = ((farLegCustomerRate - this.state.farLegMarketRate) * 10000).toFixed(2);
            let amounts = this.calculateAmounts(this.state.farLegBuyAmount, this.state.farLegSellAmount, this.state.rateTerms, farLegCustomerRate, this.state.lockedSide);
            let profitAmount = await this.getProfit(amounts.BuyAmount, amounts.SellAmount, farLegCustomerRate, this.state.farLegMarketRate, true);
            let profitAmountCcy = this.getProfitCCy(amounts.SellAmount, amounts.BuyAmount, this.state.rateTerms, this.state.farLegMarketRate, this.state.lockedSide);

            this.setState({farLegEnableControlUpdates: false,
                farLegBuyAmount: amounts.SellAmount,
                farLegCustomerRate: farLegCustomerRate,
                farLegProfit: profitAmount,
                farLegProfitCcy: profitAmountCcy,
                farLegSellAmount: amounts.BuyAmount,
                farLegSpread: farLegSpread,
                oldFarCustomerRate: oldFarCustomerRate
            }, () => {
                this.setState({farLegEnableControlUpdates: true});
                this.validateRateLimits(this.state.originalFarCustomerRate, farLegCustomerRate);
            });
 
    }

    handleUpdateCustomerRate = async (event) => {        
        if(isNaN(event.target.value)){
            return false;
        }
        let customerRate = event.target.value;        

        this.setState({customerRate: customerRate});
        /*if(this.state.customerRateEnableUpdatesTrigger
            && customerRate !== this.state.customerRate){
            let marketRate = this.state.marketRate;
            let oldCustomerRate = this.state.customerRate;
             
                //Spread points and percentage
                let profitPoints = Math.abs(customerRate - marketRate);
                var scaleCCYPair = getScaleForCurrencyPair(this.state.buyCurrency, this.state.sellCurrency);
                let spreadPoints = (profitPoints * 10000).toFixed(scaleCCYPair);
                var spreadPercentage = 0;
                if(Number(marketRate) !== 0){
                    spreadPercentage = ((profitPoints / marketRate) * 100).toFixed(2);
                }

                //Recalculate amounts
                var buyAmount = this.state.buyAmount;
                var sellAmount = this.state.sellAmount;
                var depositAmount = this.state.depositAmount;
                if(this.state.rateTerms !== ''){

                    
                    let amounts =this.calculateAmounts(sellAmount, buyAmount, this.state.rateTerms, Number(customerRate), this.state.lockedSide);
                    buyAmount = amounts.BuyAmount;
                    sellAmount = amounts.SellAmount;
                    depositAmount = amounts.DepositAmount;
                }
                
                let profitAmount = await this.getProfit(buyAmount, sellAmount, customerRate, marketRate, false);                
                
                //Update state
                this.setState({buyAmountEnableUpdatesTrigger: false,
                    sellAmountEnableUpdatesTrigger: false,
                    customerRate: customerRate,
                    spreadPoints: spreadPoints,
                    spreadPercentage: spreadPercentage,
                    buyAmount: buyAmount,
                    sellAmount: sellAmount,
                    depositAmount: depositAmount,
                    profitAmount: profitAmount,
                    oldCustomerRate: oldCustomerRate
                }, ()=>{
                    this.setState({buyAmountEnableUpdatesTrigger: true,
                        sellAmountEnableUpdatesTrigger: true,
                    });
                });
            
        }
        */
    }

    handleValidateCustomerRate = async(event)  =>
    {   

        let customerRate = this.state.customerRate;
        
            let marketRate = this.state.marketRate;
            let oldCustomerRate = this.state.customerRate;
             
                //Spread points and percentage
                let profitPoints = Math.abs(customerRate - marketRate);
                var scaleCCYPair = getScaleForCurrencyPair(this.state.buyCurrency, this.state.sellCurrency);
                let spreadPoints = (profitPoints * 10000).toFixed(scaleCCYPair);
                var spreadPercentage = 0;
                if(Number(marketRate) !== 0){
                    spreadPercentage = ((profitPoints / marketRate) * 100).toFixed(2);
                }

                //Recalculate amounts
                var buyAmount = this.state.buyAmount;
                var sellAmount = this.state.sellAmount;
                var depositAmount = this.state.depositAmount;
                if(this.state.rateTerms !== ''){

                    
                    let amounts =this.calculateAmounts(sellAmount, buyAmount, this.state.rateTerms, Number(customerRate), this.state.lockedSide);
                    buyAmount = amounts.BuyAmount;
                    sellAmount = amounts.SellAmount;
                    depositAmount = amounts.DepositAmount;
                }
                
                let profitAmount = await this.getProfit(buyAmount, sellAmount, customerRate, marketRate, false);                
                let profitAmountCcy = this.getProfitCCy(sellAmount, buyAmount, this.state.rateTerms, marketRate, this.state.lockedSide);

                //Update state
                this.setState({buyAmountEnableUpdatesTrigger: false,
                    sellAmountEnableUpdatesTrigger: false,
                    customerRate: customerRate,
                    spreadPoints: spreadPoints,
                    spreadPercentage: spreadPercentage,
                    buyAmount: buyAmount,
                    sellAmount: sellAmount,
                    depositAmount: depositAmount,
                    profitAmount: profitAmount,
                    profitAmountCcy: profitAmountCcy,
                    oldCustomerRate: oldCustomerRate
                }, ()=>{
                    this.setState({buyAmountEnableUpdatesTrigger: true,
                        sellAmountEnableUpdatesTrigger: true,
                    });
                    var validRate = this.validateRateLimits(this.state.originalCustomerRate, customerRate);
                });                
    }

    handleUpdateDealFee = (control) => {
        var dealFeeTemp = this.state.dealFeeList;
        let controlID = control.target.id;
        let index = controlID.substring(controlID.indexOf('-') + 1);
        let amount = control.target.value;
        dealFeeTemp[index].FeeAmount = amount;

        var totalFees = 0;
        for(var i=0; i<dealFeeTemp.length;i++){
            totalFees += Number(dealFeeTemp[i].FeeAmount);
        }

        this.setState({dealFeeList: dealFeeTemp, 
            totalFees:totalFees,
        });
    }
    //#endregion

    okGlobalAlert = () => {
        this.setState({showAlert: false});
    }

    okVoidConfirmation = () => {
        this.setState({showVoidConfirmation: false});
    }

    okVoidOnPartnerConfirmation= () => {
        this.setState({showVoidConfirmation: false});
    }

    okAdjustConfirmation = () => {
        this.setState({ showVoidOnPartnerConfirmation: false });
    }

    yesVoidConfirmation = () => {
        //Void deal
        this.setState({loading: true,
            showVoidConfirmation: false,
        });
        voidDealHeader(this.state.dealHeaderID, localStorage.getItem('UserID'), this.state.updateToken).then(
            (json) => {
                var status = this.state.status;
                var dealsRefreshFlag = this.state.dealsRefreshFlag;
                let voidOnInterface = false;
                if(json.ValidationMessage !== undefined){
                    this.showAlert("Validation", json.ValidationMessage);
                }
                else{
                    var alertTitle = '';
                    var alertMessage = '';
                    switch(json.httpStatusCode){
                        case 200:
                            if (this.props.dealStatusWasUpdated !== undefined) {
                                this.props.dealStatusWasUpdated('Voided');
                            }
                            alertTitle = 'Confirmation';
                            alertMessage = 'Deal was voided!'
                            status = 'Voided';
                            dealsRefreshFlag++;
                            this.enforceDisable(true);
                            break;
                        case 202:
                            voidOnInterface = true;
                            break;    
                        case 409:
                            alertTitle = 'Error';
                            alertMessage = 'Deal not voided! Please try again or contact the administrator.';
                            if(json.httpErrorMessage !== undefined){
                                alertMessage = json.httpErrorMessage;
                            }
                            break;
                        default:
                            alertTitle = 'Error';
                            alertMessage = 'Deal not voided! Please try again or contact the administrator.';
                            break;
                    }
                    if (voidOnInterface) {
                        this.setState({ showVoidOnPartnerConfirmation: true, voidOnPartnerConfirmationMessage: json.ConfirmationOnPartnerRequiredMessage })
                    } else {
                        this.showAlert(alertTitle, alertMessage);
                    }                    
                }
                this.setState({loading: false,
                    status: status,
                    dealsRefreshFlag: dealsRefreshFlag,
                });
            }
        );    
    }

    yesVoidOnPartnerConfirmation = () => {
        //Void deal
        this.setState({
            loading: true,
            showVoidOnPartnerConfirmation: false,
        });
        voidDealHeader2(this.state.dealHeaderID, localStorage.getItem('UserID'), this.state.updateToken, true).then(
            (json) => {
                var status = this.state.status;
                var dealsRefreshFlag = this.state.dealsRefreshFlag;
                if (json.ValidationMessage !== undefined) {
                    this.showAlert("Validation", json.ValidationMessage);
                }
                else {
                    var alertTitle = '';
                    var alertMessage = '';
                    
                    switch (json.httpStatusCode) {
                        case 200:
                            if (this.props.dealStatusWasUpdated !== undefined) {
                                this.props.dealStatusWasUpdated('Voided');
                            }
                            alertTitle = 'Confirmation';
                            alertMessage = 'Deal was voided!'
                            status = 'Voided';
                            dealsRefreshFlag++;
                            this.enforceDisable(true);
                            break;
                                                
                        case 409:
                            alertTitle = 'Error';
                            alertMessage = 'Deal not voided! Please try again or contact the administrator.';
                            if (json.httpErrorMessage !== undefined) {
                                alertMessage = json.httpErrorMessage;
                            }
                            break;
                        default:
                            alertTitle = 'Error';
                            alertMessage = 'Deal not voided! Please try again or contact the administrator.';
                            break;
                    }                                                
                    this.showAlert(alertTitle, alertMessage);
                }
                this.setState({
                    loading: false,
                    status: status,
                    dealsRefreshFlag: dealsRefreshFlag,
                });
            }
        );
    }

    yesAdjustConfirmation = () => {
        this.setState({loading: true,
            showAdjustConfirmation: false,
        })

        adjustDealHeader(this.state.dealHeaderID, localStorage.getItem('UserID'), this.state.updateToken).then(
            (json) => {
                var status = this.state.status;
                var dealsRefreshFlag = this.state.dealsRefreshFlag;
                if(json.ValidationMessage !== undefined){
                    this.showAlert('Validation', json.ValidationMessage);
                }
                else{
                    if(json.Result){
                        this.showAlert('Confirmation', 'Deal was adjusted with a new deal: ' + json.AdjustmentDealID);
                        dealsRefreshFlag++;
                        if(this.props.dealStatusWasUpdated !== undefined){
                            this.props.dealStatusWasUpdated('Adjusted');
                        }
                    }
                    else{
                        this.showAlert('Error', 'Deal not adjusted! Please contact administrator.');
                    }
                }
                this.setState({loading: false,
                    status: status,
                    dealsRefreshFlag: dealsRefreshFlag,
                });
            }
        );
    }

    verifyHoliday = (day) => {
        var holidays = [];
        for(var i=0; i<this.props.holidays.length; i++){
            if(this.props.holidays[i].CurrencyID === 'ANY' ||
            this.props.holidays[i].CurrencyID === this.state.sellCurrency ||
            this.props.holidays[i].CurrencyID === this.state.buyCurrency){
                holidays.push(this.props.holidays[i].Date);
            }
        }

        return holidays.includes(formatDate(day));
    }

    onClickTab(value){
        if (value === 11) {
            return;
        }
        debugger;
        if(Number(value) !== 2 && this.state.dealType !== 2){
            var endDate = new Date();
            let forwardDays = 0;
            var maturityDate='';
            if([4, 6].includes(Number(value))){
                //endDate = this.getNextBusinessDate(this.state.startDate, this.props.maxForwardWindow, this.state.buyCurrency, this.state.sellCurrency);
                maturityDate = new Date(this.props.systemDate);
                if (value === 4) {
                    //var date = Date(this.state.startDate);
                    endDate = this.getNextBusinessDateForward(this.state.startDateMaturity, this.props.maxForwardWindow);
                    let startDate = new Date(this.props.systemDate)
                    maturityDate = this.getNextBusinessDate(startDate, 2, this.state.buyCurrency, this.state.sellCurrency);
                    forwardDays = this.daysBetweenDates(startDate, maturityDate);
                }
                else if (value === 6) {
                    //endDate = undefined;
                    endDate = null;
                    let startDate = new Date(this.props.systemDate)
                    maturityDate = this.getNextBusinessDate(startDate, 2, this.state.buyCurrency, this.state.sellCurrency);
                    forwardDays = this.daysBetweenDates(startDate, maturityDate);
                }
                else {   //swap- any date greather than system date (WILLY) 
                    endDate = null;//undefined;//this.getNextBusinessDate(this.state.startDate, this.props.maxForwardWindow, this.state.buyCurrency, this.state.sellCurrency);
                }
            }
            else{
                endDate = this.calculateEndDate(this.state.startDate, this.state.buyCurrency, this.state.sellCurrency);
            }
            if(this.state.dealHeaderID > 0){
                this.handleNewDealClick();
            }
            let maturityDateDisabled = false;
            let valueDateDisabled = false;
            if(this.props.objDealHeader != undefined)
            {
                if(this.props.objDealHeader.DealHeaderID > 0 && this.props.objDealHeader.DealType == 4 && this.props.objDealHeader.ForwardType == 'Open' && (this.state.totalDisbursed > 0 && this.state.totalDisbursed <= this.props.objDealHeader.SellAmount) && this.props.objDealHeader.MaturityDate >= this.props.systemDate)
                {
                    valueDateDisabled = true;
                }
            }

            this.setState({dealType: value,
                endDate: endDate,
                rateQuoteID: '',
                valueDate: new Date(this.props.systemDate),
                maturityDate: maturityDate,
                forwardDays: forwardDays,
                valueDateDisabled: valueDateDisabled,
                forwardDaysDisabled:maturityDateDisabled,
                maturityDateDisabled: maturityDateDisabled,
                forwardType:'Open'
            }, () => this.getRate(true));
        }
    }

    handleDealClick = (objDeal) => {
        this.updateDealHeader(objDeal, false, true);
        this.enforceDisable(true);
    }

    handleForwardClick = (objForward) => {
        //do not open closed forwards
        if (objForward !== undefined && objForward["Forward Type"] === 'Closed') {
            this.updateDealHeader(objForward, false);
        }
        this.updateDealHeader(objForward, true);
    }

    handleQuoteClick = (objQuote) => {
        this.props.onClearDisbursements();
        getQuoteByID(objQuote['Quote No']).then(
            (result) => {
                if(result !== undefined){
                    let objDealHeader = result;
                    objDealHeader.DealHeaderID = 0;
                    objDealHeader.Status = '';
                    objDealHeader.ValueDate = new Date(objDealHeader.ValueDate);
                    if(objDealHeader.MaturityDate !== null){
                        objDealHeader.MaturityDate = new Date(objDealHeader.MaturityDate);
                    }
                    this.setState({dealHeaderID:0, status:''});
                    this.props.onUpdateDealHeader(objDealHeader);
                }
            }
        ); 
    }

    updateDealHeader(objDeal, forDrawdownCreation, isTransaction){
        var dealHeaderID = objDeal['Deal Header ID'];
        if(Number(objDeal['DealType$']) === 6){
            if(objDeal['ParentDealHeaderID$'] !== null){
                dealHeaderID = objDeal['ParentDealHeaderID$'];
            }
        }
        this.loadDealHeader(dealHeaderID, forDrawdownCreation, false,isTransaction);
    }

    loadDealHeader = (dealHeaderID, forDrawdownCreation, fromCloseout,isTransaction) => {
        getFullDealHeader(dealHeaderID).then(
            (result) => {
                
                if(result !== undefined){
                    let objDealHeader = result;

                    objDealHeader.ForDrawdownCreation = forDrawdownCreation;
                    objDealHeader.ValueDate = new Date(objDealHeader.ValueDate);
                    if(objDealHeader.MaturityDate !== null){
                        objDealHeader.MaturityDate = new Date(objDealHeader.MaturityDate);
                    }
                    if(objDealHeader.OutgoingFunds === null){
                        objDealHeader.OutgoingFunds = [];
                    }
                    if(objDealHeader.IncomingFunds === null){
                        objDealHeader.IncomingFunds = [];
                    }
                    if (forDrawdownCreation) {
                        objDealHeader.ParentDealHeaderID = objDealHeader.DealHeaderID;
                        this.enforceDisable(false);
                        let depositsBalance = objDealHeader.Deposits !== undefined && objDealHeader.Deposits !== null ?
                            objDealHeader.Deposits.filter(x => x.Balance > 0 && x.ReceivedStatus === 'Fully paid' && x.DepositType === 'InitialDeposit').reduce((acc, cur) => acc + cur.Balance, 0) :
                            0;

                        let depositsBalanceInfo = ''
                        if (depositsBalance > 0) {
                            let deposits = objDealHeader.Deposits.filter(x => x.Balance > 0 && x.ReceivedStatus === 'Fully paid')                            
                            deposits = deposits.reduce((acc, obj) => {
                                if (acc.filter(x => x.DepositID === obj.DepositID).length === 0) {
                                    acc.push({ DepositID: obj.DepositID, Balance: obj.Balance, CurrencyID: obj.CurrencyID });
                                }
                                return acc;
                            }, []);                            
                            let depositsInfo = deposits.map(x => `Deposit ID ${x.DepositID} Balance: ${x.Balance} ${x.CurrencyID}`)
                            depositsBalanceInfo = depositsInfo.join(', ')                            
                        }
                        this.setState({ marketRateDisabled: true, customerRateDisabled: true, sellCurrencyDisabled: true, buyCurrencyDisabled: true, depositsBalance: depositsBalance, depositsBalanceInfo: depositsBalanceInfo })
                    }

                    this.props.onUpdateDealHeader(objDealHeader);
                    if(fromCloseout)
                    {
                        this.setState({isCloseout: true});
                    }
                    if (isTransaction === undefined) {
                        if (objDealHeader.DealType === 4 && objDealHeader.ForwardType === 'Closed') {
                            this.handleNextClick();
                            //this.setState({ rateQuoteID: objDealHeader.RateQuoteID }, () => this.handleNextClick())
                        }
                    }
                }
            }
        );
    }

    closeBroadcastRateSelection = () => {
        this.setState({showRateSelection: false});
    }

    onSelectBroadcastedRate = (objRate) => {
        //alert(JSON.stringify(objRate));
        this.readRateObject(objRate);
    }

    validationMarketRate = () => {
        if(this.state.rateQuoteID !== '' && this.state.marketRate === 0){
            return true;
        }else{
            return false;
        }        
    }

    handleShowEmailConfirmationClick = () => {
        //if(this.state.contacts_length>0){
        if(this.props.objContact['Contact ID'] > 0 /*&& this.props.objContact['Status'] === 'Active'*/) {
            this.setState({ showDealConfirmation: true });            
        }
        else{
            this.setState({ showMessage: true, messageMessage: 'There are no active customer contacts for this customer.' });            
        }
    };

    closeDealConfirmation = () => {
        this.setState({ showDealConfirmation: false, showDealConfirmationForm: false });
    };

    setContacts_length = (n) => {
        this.setState({ contacts_length: n });
    };

    onAcceptLinkDeal = (dealId) => {
        let dealHeaderId = this.props.objDealHeader !== undefined ? this.props.objDealHeader.DealHeaderID : 0        
        this.setState({ dealIdToLink: dealId }, () => {
            getEntityProperties(dealHeaderId, 'Deal').then(response => {
                console.log('getEntityProperties ', response)
                let customerDealIdProperty = response.entityProperties.find(x => x.Description === 'Customer deal ID');
                let entityPropertyId = customerDealIdProperty !== undefined ? customerDealIdProperty.EntityPropertyID : ''
                let updateToken = customerDealIdProperty !== undefined ? customerDealIdProperty.UpdateToken : ''
                if (customerDealIdProperty !== undefined) {

                }
                linkSwapToDeal(dealHeaderId, dealId, entityPropertyId, updateToken, localStorage.getItem('UserID')).then(response => {
                    console.log('linkSwapToDeal ', response)
                    if (response.httpStatusCode === 200) {
                        this.showAlert('Success', 'Deal ' + dealHeaderId + ' linked to Deal ' + dealId);
                    }
                });
            })
        })
    }

    handleShowLinkDeal = () => {
        this.setState({
            showLinkToDeal: true
        });
    };

    closeLinkToDeal = (dealHeaderId) => {        
        this.setState({
            showLinkToDeal: false
        }, () => {
            if (dealHeaderId !== undefined && dealHeaderId > 0) {
                this.loadDealHeader(dealHeaderId)
            }
        });
    };

    handleCloseoutClick = () => {
        var totalDrawDowns = this.state.forwardSellTotalDisbursed;
        var balance = this.state.sellAmount - this.state.forwardSellTotalDisbursed;
        var message = "This forward will be updated to match the total of drawdowns ("+totalDrawDowns + " " + this.state.sellCurrency+"). After closing out this forward there will be no outstanding balance. You can create a new forward or spot deal if required for the balance ("+balance + " " + this.state.sellCurrency+") . Are you sure you want to close out this forward?";
        this.setState({closeoutMessage: message,
            balanceCloseout: 0
            , balanceCloseoutCurrency: ''
            , closeoutComment: '' }
            , () => this.setState({ showCloseoutConfirmation: true}));
    }

    yesCloseoutConfirmation = () => {
        
        this.setState({loading: true,
            showCloseoutConfirmation: false,
        });
        closeoutForward(this.state.dealHeaderID, localStorage.getItem('UserID'), this.state.updateToken).then(
            (json) => {
                if(json.ValidationMessage !== undefined){
                    this.showAlert("Validation", json.ValidationMessage);
                }
                else{
                    var alertTitle = '';
                    var alertMessage = '';
                    switch(json.httpStatusCode){
                        case 200:
                            var message = 'The forward has been closed out. Do you want to create a new deal with the balance ('+ json.balance +' '+ this.state.sellCurrency+')?';
                            var dealsRefreshFlag = this.state.dealsRefreshFlag;
                            dealsRefreshFlag++;
                            this.setState({balanceCloseout: json.balance
                                , closeoutComment: json.comment
                                , balanceCloseoutCurrency: this.state.sellCurrency
                                , closeoutMessage: message
                                , dealsRefreshFlag: dealsRefreshFlag
                                },()=> this.setState({showCloseoutResult: true}));
                                this.enforceDisable(true);
                            break;
                        case 409:
                            alertTitle = 'Error';
                            alertMessage = 'Deal not closeout! Please try again or contact the administrator.';
                            if(json.httpErrorMessage !== undefined){
                                alertMessage = json.httpErrorMessage;
                            }
                            break;
                        default:
                            alertTitle = 'Error';
                            alertMessage = 'Deal not closeout! Please try again or contact the administrator.';
                            break;
                    }
                    if(alertMessage != '')
                    {
                        this.showAlert(alertTitle, alertMessage);
                    }
                }
                this.setState({loading: false
                });
            }
        );    
    }

    okCloseoutConfirmation = () => {
        this.setState({ showCloseoutConfirmation: false });
    }

    yesCloseoutResult = () => {
        this.handleNewDealClick();
        let objDealHeader = {DealHeaderID: 0, QuoteNo: 0, OutgoingFunds:[],
            SellAmount: this.state.balanceCloseout,
             SellCurrency: this.state.balanceCloseoutCurrency,
            InternalComments: this.state.closeoutComment,
            ValueDate: new Date(this.props.systemDate),
            MaturityDate: this.state.maturityDate,
        };
            //near =system far= original
        this.props.onUpdateDealHeader(objDealHeader, () => {
            this.setState({ showCloseoutResult: false,
                sellAmount: this.state.balanceCloseout, sellCurrency: this.state.balanceCloseoutCurrency,
                maturityDate: objDealHeader.MaturityDate, valueDate: new Date(this.props.systemDate),
                forwardDays: this.daysBetweenDates(new Date(this.props.systemDate), objDealHeader.MaturityDate)
            });
        } );
    }

    okCloseoutResult = () => {
        this.loadDealHeader(this.state.dealHeaderID, false, true);
        this.setState({ showCloseoutResult: false });
    }

    handleDealVersionsClick = () => {
        this.setState({openDealVersions: true});
    }

    closeDealVersionsClick = () => {
        this.setState({openDealVersions: false});
    }

    onCloseMessage = () => {
        this.setState({ showMessage: false });
    }
    
    okClickMessage = () => {
        this.setState({ showMessage: false });
    }

    onBuybackSuccess = (deal) => {
        this.setState({ dealsRefreshFlag: this.state.dealsRefreshFlag + 1, editSupportingRates: false })
        this.enforceDisable(true);
        this.setState({ showReverseButton: false });
        console.log('deal', deal)
        this.props.loadDeal(deal, false)
    }

    closeValidationSMSCode = () => {
        this.setState({ showValidationSMSCode: false, messageValidationSMSCode: '', titleValidationSMSCode: '' });
    }

    sendSMSCode = async () => {
        //Code to send sms.
        let mobileNumber = this.state.contactMobile;//this.props.objContact['Mobile Phone'];
        let companyName = this.props.objCustomer['Customer Name'];
        const model = {
            numberMobile: mobileNumber,
            token: '',
            companyName: companyName != null ? companyName : ''
        };
        const json = await generateSMSCode(model)
        if (json.httpStatusCode !== 200) {
            this.showAlert('Error', json.Message);
        } else {
            this.showAlert('Success', 'New code sent.');
        }
    }

    validatePhone = async (smsCode) => {
        if(smsCode!== undefined && smsCode!== null && smsCode !== '') {
            const model = {
                numberMobile: this.state.contactMobile,//this.props.objContact['Mobile Phone'],
                token: smsCode
            };
            const json = await validateSMSCode(model);
            if (json.httpStatusCode !== 200) {
                this.showAlert('Error', json.Message);
            } else {
                if (json.validation) {
                    //this.setState({ showSuccessAlert: true, alertTitle: 'Success', alertMessage: 'Code validated.', phoneValidated: true });
                    this.setState({ showValidationSMSCode: false, messageValidationSMSCode: '', titleValidationSMSCode: '', phoneValidated: true });
                    //this.handleBookNowClick(this.state.confirmedByClient);
                    this.handleBookNowClick(true);
                } else {
                    this.showAlert('Error', 'Invalid code.');
                }
            }
        }
    }

    handleBackLink() {
        this.setState({
            showDealConfirmationForm: false
        });
    }

    render() {

        let treatForwardAsSpot = this.state.dealHeaderID !== 0 && this.state.dealType === 4 && this.state.forwardType === "Closed"
        let buttonStyle= {marginTop:'10px'};

        var cantEdit = false;
        let isDrawdown = false;
        if(this.state.dealType === 2){ isDrawdown=true; }
        var cantEditComments = false;

        //Validate if forward has drawdowns
        var doesForwardHasDrawdowns = false;
        var outgoingComplete = undefined;
        if(this.props.objDealHeader.OutgoingFunds && this.props.objDealHeader.OutgoingFunds !== undefined 
            && this.props.objDealHeader.OutgoingFunds.length > 0)
            {
                outgoingComplete =this.props.objDealHeader.OutgoingFunds.find(o => o.Status === 'Completed');
            }

        if(this.state.dealType === 4 &&
            (this.state.totalDisbursed > 0 || 
                (this.props.objDealHeader.OutgoingFunds !== undefined 
                    && this.props.objDealHeader.OutgoingFunds.length > 0
                    && outgoingComplete !== undefined))){
                doesForwardHasDrawdowns = true;
        }

        if(this.props.objDealHeader !== undefined)
        {
            if(this.props.objDealHeader.DealPaidStatus === 'Partially Paid' || this.props.objDealHeader.DealPaidStatus === 'Fully Paid' || doesForwardHasDrawdowns){
                if(this.props.objDealHeader.DealType === 4)
                {
                    cantEditComments = false;
                }
                else
                {
                    cantEditComments = true;
                    cantEdit = true;
                }
            }   
            if (this.state.allowEditSwapFarLeg) {
                cantEdit = false
            }         
        }

        let profitCCyLabel = '';
        let dealCCy = this.state.lockedSide === 'Buy' ? this.state.sellCurrency : this.state.buyCurrency;

        if(this.state.sellCurrency !== this.state.baseCurrency && this.state.buyCurrency !== this.state.baseCurrency)
        {
            profitCCyLabel = ` / ${formatToCurrencyWithScale(this.state.profitAmountCcy, dealCCy)} ${dealCCy}`;
        }

        var disableSupportingControls = this.state.disableSupportingControls;
        if(this.state.dealHeaderID > 0 && !this.state.editSupportingRates){
            //this.setState({disableSupportingControls: true});
            disableSupportingControls = true;
        }
        var rightView;
        switch(this.state.rightViewMode){
            case 'regular':
            default:
                rightView = (<WizardTransactionsView
                    objCustomer={this.props.objCustomer}
                    objDealHeader={this.props.objDealHeader}
                    handleDealClick={this.handleDealClick}
                    handleForwardClick={this.handleForwardClick}
                    handleQuoteClick={this.handleQuoteClick}
                    quotesRefreshFlag={this.state.quotesRefreshFlag}
                    dealsRefreshFlag={this.state.dealsRefreshFlag}
                    handleSaveComments={this.handleSaveDealInfo}
                    enableClick= {!cantEditComments}
                    disabled={this.state.disableTransactionSection}
                />);
                break;
            case 'drawdowns':
                rightView = (<WizardDrawdownsView
                    forwardID={this.state.dealHeaderID}
                    handleDealClick={this.handleDealClick}
                    handleCloseDrawdownsClick={this.handleCloseDrawdownsClick}
                />);
                break;
            case 'supportingRates':
                rightView = (<WizardSupportingRates id="wizard-supporting-rates"
                    buyCurrency={this.state.buyCurrency}
                    dealType={this.state.dealType}
                    maturityDate={this.state.maturityDate}
                    rateQuoteID={this.state.rateQuoteID}
                    sellCurrency={this.state.sellCurrency}
                    valueDate={this.state.valueDate}
                    customerRate={this.state.customerRate}
                    marketRate={this.state.marketRate}
                    nearLegOriginalMarketSpotRate ={this.state.nearLegOriginalMarketSpotRate}
                    nearLegMarketRate ={this.state.nearLegMarketRate}
                    farLegOriginalMarketSpotRate ={this.state.farLegOriginalMarketSpotRate}
                    farLegMarketRate ={this.state.farLegMarketRate}
                    nearLegCustomerRate={this.state.nearLegCustomerRate}
                    farLegCustomerRate={this.state.farLegCustomerRate}
                    supportingRates={this.state.supportingRates}
                    updateLocalSupportingRates={this.updateLocalSupportingRates}
                    //disableControls={this.state.disableSupportingControls}
                    disableControls={disableSupportingControls}
                    onSaveClick={this.onSaveSupportingRatesClick}
                    onCloseClick={this.onCloseSupportingRatesClick} 
                    currencyPairs={this.props.currencyPairs}
                />);
                break;
        }

        var dealFeeRows = [];

        let relatedWireFeeID = this.props.objCustomer['RelatedWireFeeID$'];
        let feeStructureID = this.props.objCustomer['FeeStructureID$'];
        let defaultDealFeeStructure = localStorage.getItem(GlobalSystemParametersNames.DefaultDealFeeStructure);

        if(this.state.dealFeeList !== undefined){
            for(var i=0; i<this.state.dealFeeList.length; i++){
                var disabled = false;
                if(this.state.dealFeeList[i].ReadOnly === true){
                    disabled = true;
                }
                
                if((feeStructureID === undefined || feeStructureID === null) && (relatedWireFeeID === undefined || relatedWireFeeID === null))
                {
                    if(this.state.dealFeeList[i].Description === defaultDealFeeStructure)
                    {
                        disabled = true;
                    }
                }

                if(this.state.status === 'Voided'){
                    disabled = true;
                }
                
                dealFeeRows.push(
                    <tr key={i}>
                        <td>
                            {this.state.dealFeeList[i].FeeDescription}
                        </td>
                        <td>
                        <NumberInput id={"dealfee-" + i} type="Currency" className="uk-input" 
                            scale={getScaleByCurrency(this.state.buyCurrency)}
                            value={this.state.dealFeeList[i].FeeAmount} 
                            onChange={this.handleUpdateDealFee}
                            disabled={disabled || cantEdit}/>
                        </td>
                    </tr>
                );
            }
        }

        //Deal fees sections
        var dealFeesSection;
        if([1,2].includes(this.state.dealType)){
            dealFeesSection = (
                <div>
                    <h5 className="uk-heading-divider" 
                        uk-toggle="target : #feeSection;"
                        style={{...getStyle().titleStyle, cursor: 'pointer'}}>Total Fees &nbsp; {formatToCurrencyWithScale(this.state.totalFees, this.state.buyCurrency)} <i id="feeSection" className="fa fa-fw fa-sort-desc" style={{verticalAlign: 'top'}}/><i id="feeSection" className="fa fa-fw fa-sort-asc" style={{verticalAlign: 'bottom'}} hidden/>
                    </h5>
                    <div id="feeSection" hidden className="uk-accordion-content border-table">
                        <table className="uk-table uk-table-divider uk-table-hover">
                            <thead>
                                <tr>
                                    <th>Fee</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dealFeeRows}
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }

        var buyLocked = '';
        var sellLocked = '';
        if(this.state.lockedSide === 'Buy'){
            buyLocked = '*';
        }
        else{
            sellLocked = '*';
        }

        var quoteNoLabel = '';
        if(Number(this.state.dealHeaderID) === 0 &&
            Number(this.state.quoteNo) !== 0 &&
            this.state.quoteNo !== undefined){
                quoteNoLabel = 'Quote No:' + this.state.quoteNo;
        }        

        let dealIdToLinkText = this.state.dealIdToLink > 0 ? 'Link To Deal (' + this.state.dealIdToLink + ')' : 'Link To Deal'
        let allowTradeUpdates = this.props.traderUser !== undefined && this.props.traderUser.AllowTradeUpdates        

        //Buttons
        var saveQuoteButton, voidButton, adjustButton,
            nextButton, refreshButton, printPDFButton,
            duplicateButton, bookNowButton,
            bookNowAndConfirmButton, broadcastButton, uncoveredDealsButton,
            editButton, reverseButton, closeoutButton, dealVersionButton, linkToDeal,
            sendInwardButton;   
            
        let buyBackButton = [];
        let tradeScreenEnableBuyBackDeal = ''
        if (localStorage.getItem(GlobalSystemParametersNames.TradeScreenEnableBuyBackDeal) !== undefined
            && localStorage.getItem(GlobalSystemParametersNames.TradeScreenEnableBuyBackDeal) !== null) {
            tradeScreenEnableBuyBackDeal = localStorage.getItem(GlobalSystemParametersNames.TradeScreenEnableBuyBackDeal).toLowerCase()
        }

        let enableBuyBackButton = tradeScreenEnableBuyBackDeal === 'true' && Number(this.state.dealHeaderID) > 0;
        
        if (this.state.dealType === 11) {
            rightView = [];
        } else if (enableBuyBackButton) {
            buyBackButton.push(<button key={'buyBackButton'} className="uk-button uk-button-green" onClick={f => this.setState({ 'dealType': 11 })
            } style={buttonStyle} > Buy back</button >)
        }
        

        if (this.state.dealType === 4 &&
            this.state.dealHeaderID !== 0 &&
            this.state.totalDisbursed > 0 &&
            !treatForwardAsSpot) {
                //drawdownsButton = (<button className="uk-button uk-button-green" onClick={this.handleViewDrawdownsClick} style={buttonStyle}>View drawdowns</button>);
                if(this.state.totalDisbursed < this.state.sellAmount)
                {
                    closeoutButton = (<button className="uk-button uk-button-green" onClick={this.handleCloseoutClick} style={buttonStyle}>Close out</button>);
                }
        }

        if(this.state.dealHeaderID !== 0
            && this.state.dealType !== 2 && this.state.dealType !== 11
            && !this.state.isSameCurrencyDealRefund){
            duplicateButton = (<button className="uk-button uk-button-green" onClick={this.handleDuplicateClick} style={buttonStyle}>Duplicate</button>);
        }

        if(this.state.status !== 'Voided'){

            if(this.state.dealHeaderID === 0 &&
                [1, 4].includes(this.state.dealType) &&
                this.state.quoteNo === 0){
                saveQuoteButton = (<button className="uk-button uk-button-green" disabled={this.validationMarketRate()} onClick={this.handleSaveQuoteClick} style={buttonStyle}>Save as Quote</button>);
            }
            
            if(!this.state.isAdjustmentDeal){              
                if(this.state.dealHeaderID !== 0 && this.state.dealType !== 11){
                    //date validations
                    var systemDate = new Date(this.props.systemDate);
                    systemDate.setHours(0, 0, 0, 0);

                    var valueDate = new Date(this.state.valueDate);
                    if(valueDate === undefined || valueDate === ''){
                        valueDate = new Date(this.props.systemDate);
                    }
                    valueDate.setHours(0, 0, 0, 0);
                    var maturityDate = new Date(this.props.systemDate);
                    if(this.state.dealType === 4){
                        maturityDate = new Date(this.state.maturityDate);
                    }
                    maturityDate.setHours(0, 0, 0, 0);

                    printPDFButton = (<button className="uk-button uk-button-green" onClick={this.handlePrintPDFClick} style={buttonStyle}>Print PDF</button>);
                    sendInwardButton = (<button hidden={!this.state.allowAdditionalSettlements} className="uk-button uk-button-green" onClick={this.handleSendInwardClick} style={buttonStyle}>Email Inward confirmation</button>);
                    if (!this.state.isSameCurrencyDealRefund && this.state.dealType !== 11) {
                        if ((this.state.dealType !== 4 && valueDate >= systemDate) ||
                            (this.state.dealType === 4 && maturityDate >= systemDate && !doesForwardHasDrawdowns)) {
                            voidButton = this.state.editingDeal && (<button className="uk-button uk-button-green" onClick={this.handleVoidClick} style={buttonStyle}>Void</button>);

                        }
                        else {
                            adjustButton = (<button className="uk-button uk-button-green" onClick={this.handleAdjustClick} style={buttonStyle}>Adjust</button>);
                        }
                    }
                }

                if((this.state.dealHeaderID === 0 || valueDate >= systemDate || this.state.allowEditSwapFarLeg)
                    && !this.state.isSameCurrencyDealRefund){
                    if([1, 6].includes(this.state.dealType) ||
                        (this.state.dealType === 4 && (this.state.totalDisbursed === 0 || this.state.totalDisbursed === undefined))){
                        refreshButton = (
                            <i className="fa fa-fw fa-refresh pointer" title="Get rate" style={{ fontSize: '1.75em', verticalAlign: 'middle' }} onClick={this.handleRefreshClick}
                                />
                            
                        );
                        broadcastButton = (
                            <i className="fa fa-fw fa-globe pointer" title="Multirate" style={{ fontSize: '1.75em', verticalAlign: 'middle' }} onClick={this.handleBroadCastClick}
                                />
                            );
                    }
                    nextButton = (this.state.dealType !== 11 && <button className="uk-button uk-button-green uk-align-right" disabled={this.validationMarketRate()} onClick={this.handleNextClick} style={buttonStyle}>Next</button>);
                }
            }
            if(this.state.dealHeaderID > 0)
            {
                dealVersionButton = ( this.state.dealType !== 11 && <button className="uk-button uk-button-green" onClick={this.handleDealVersionsClick} style={buttonStyle}>Deal Versions</button>);
                if((this.props.objDealHeader.DealPaidStatus === 'Partially Paid' || this.props.objDealHeader.DealPaidStatus === 'Fully Paid' 
                || doesForwardHasDrawdowns) && this.props.objDealHeader.DealType === 4 && !this.state.maturityDateDisabled){}
                else
                {
                    editButton = (this.state.dealType !== 11 && allowTradeUpdates && this.state.editDeal && !this.state.showReverseButton && <button className="uk-button uk-button-green" onClick={this.handleEditClick} style={buttonStyle}>Edit</button>);
                }
            }
            reverseButton = (this.state.showReverseButton && <button className="uk-button uk-button-green" onClick={this.handleReverseClick} style={buttonStyle}>Revert</button>);
           

            if ([6].includes(this.state.dealType) && this.state.dealHeaderID > 0 && !this.state.editingDeal) {
                linkToDeal = <button className="uk-button uk-button-green" onClick={this.handleShowLinkDeal} style={buttonStyle}>{dealIdToLinkText}</button>
            }
            
        }

        if(cantEdit)
        {
            refreshButton = '';
            broadcastButton = '';
        }

        if(this.state.dealType !== 2 && this.state.dealType !== 11 && (this.state.dealHeaderID === 0 || ( this.state.dealHeaderID > 0 && !cantEdit && this.state.showReverseButton))) {
            bookNowButton = (<button className="uk-button uk-button-green" disabled={this.validationMarketRate()} onClick={() => this.handleBookNowClick(false)} style={buttonStyle}>Book Now</button>);
            bookNowAndConfirmButton = (<button className="uk-button uk-button-green" disabled={this.validationMarketRate()} onClick={() => this.handleBookNowClick(true)} style={buttonStyle}>Book Now + Confirm</button>);

        }

        if(this.state.dealHeaderID === 0 && this.state.dealType !== 2){
           if(this.props.objCustomer['Customer Type'] === 'Bank'){
                uncoveredDealsButton = (<button className="uk-button uk-button-green" onClick={this.handleUncoveredDealsClick} style={buttonStyle}>Uncovered Deals</button>);
            }
        }

        var supportingRatesButton;
        if(this.state.rateQuoteID !== '' && this.state.dealType !== 2 && this.state.dealType !== 11 && 
            this.state.sellCurrency !== this.state.baseCurrency &&
            this.state.buyCurrency !== this.state.baseCurrency){
            supportingRatesButton = (<button className="uk-button uk-button-green" disabled={this.validationMarketRate()} onClick={this.onSupportingRatesClick} style={buttonStyle}>Supporting Rates</button>);
        }        
        
        //Disable controls
        /*var sellAmountDisabled = false, buyAmountDisabled = false, 
        marketRateDisabled = false, customerRateDisabled = false,
        sellCurrencyDisabled = false, buyCurrencyDisabled = false,
        valueDateDisabled = false, forwardTypeDisabled = false,
        depositPercentageDisabled = false, depositAmountDisabled = false,
        requireDepositsDisabled = false, depositCurrencyDisabled = false,
        forwardDaysDisabled = false, maturityDateDisabled = false,
        farLegSellAmountDisabled = false, farLegBuyAmountDisabled = false,
        nearLegMarketRateDisabled = false, farLegMarketRateDisabled = false, 
        nearLegCustomerRateDisabled = false, farLegCustomerRateDisabled = false,
        nearLegSpreadDisabled = false, farLegSpreadDisabled = false,
        nearLegOriginalMarketRateDisabled = false, farLegOriginalMarketRateDisabled = false,
        coverCustomerDisabled = false, useInterfaceDisabled = false;*/

        /*if(this.state.status === 'Voided' ||
            this.state.isAdjustmentDeal ||
            this.state.isSameCurrencyDealRefund ||
            doesForwardHasDrawdowns){
            sellAmountDisabled = buyAmountDisabled = 
            marketRateDisabled = customerRateDisabled = 
            sellCurrencyDisabled = buyCurrencyDisabled = 
            valueDateDisabled = forwardTypeDisabled =
            depositPercentageDisabled = depositAmountDisabled = 
            requireDepositsDisabled = depositCurrencyDisabled =
            forwardDaysDisabled = maturityDateDisabled = 
            farLegSellAmountDisabled = farLegBuyAmountDisabled = 
            nearLegMarketRateDisabled = farLegMarketRateDisabled = 
            nearLegCustomerRateDisabled = farLegCustomerRateDisabled = 
            nearLegSpreadDisabled = farLegSpreadDisabled = 
            nearLegOriginalMarketRateDisabled = farLegOriginalMarketRateDisabled = true;
        }*/

        //Do not allow customer rate and market rate updates for drawdowns
        /*if(this.state.dealType === 2){
            customerRateDisabled = marketRateDisabled = 
            sellCurrencyDisabled = buyCurrencyDisabled = true;
        }

        //Do not allow market rate updates when using interface
        if(this.state.useInterface){
            marketRateDisabled = true;
        }

        if(this.state.externalInterface === ''){
            useInterfaceDisabled = true;
        }

        //Disable cover dropdown for bank customers
        if(this.props.objCustomer['Customer Type'] === 'Bank'){
            coverCustomerDisabled = true;
        }

        //Disable cover dropdown if creating drawdown from forward not covered
        if(this.state.dealType === 2){
            coverCustomerDisabled = true;
        }*/
        
        //Forward Controls
        var maturityDateControl;
        var forwardDaysControl;
        var forwardTypeControl;
        var depositControls;

        if([4, 6].includes(this.state.dealType)){
            /*if(this.state.status === 'Voided'){
                maturityDateDisabled = true;
            }*/

            //Forward day
            forwardDaysControl = (
                <div className="uk-width-1-3 uk-form-controls">
                    <label className="uk-form-label" htmlFor="form-forward-days">Days</label>
                    <NumberInput type="Integer" value={this.state.forwardDays} onChange={this.handleUpdateForwardDays} onBlur={this.updateForwardDaysBlur} disabled={this.state.forwardDaysDisabled || cantEdit} className="uk-input"></NumberInput>
                </div>
            );

            //Maturity date
            maturityDateControl = (
                <div className="uk-width-1-3 uk-form-controls">
                    <label className="uk-form-label" htmlFor="form-maturity-date">Far Date</label>
                    <DatePicker disabledDays={[0,6]} 
                        className={this.formatValidInput(this.state.validations.maturityDate)} 
                        startDate={this.state.startDateMaturity} 
                        endDate={this.state.endDate} 
                        onDayChange={this.handleUpdateMaturityDate}
                        isHoliday={this.verifyHoliday}
                        value={this.state.maturityDate}
                        readOnly={true}
                        disabled={this.state.maturityDateDisabled  || cantEdit}
                        disableDropdown={true}/>
                </div>);   
        }

        //forwardType
        if (this.state.dealType === 4){// && !treatForwardAsSpot) {  
            /*depositPercentageDisabled = depositAmountDisabled = 
            depositCurrencyDisabled = !this.state.requireDeposits;*/

            //Do not allow updates when deposit was already created
            /*if(this.state.depositID !== 0 && this.state.depositID !== undefined){
                depositPercentageDisabled = depositAmountDisabled = 
                requireDepositsDisabled = depositCurrencyDisabled = true;
            } */   

            //var disableForwardType = (this.state.dealHeaderID !== 0 && this.state.dealType === 4 ); 

            //Forward type
            forwardTypeControl = (
                <div>
                    <div uk-grid="true">
                        <div className="uk-width-1-2 uk-form-controls">
                            <label className="uk-form-label" htmlFor="form-forward-type">Forward Type</label>
                            <select id="form-forward-type" 
                                className="uk-select"
                                value={this.state.forwardType}
                                onChange={this.handleUpdateForwardType}
                                disabled={this.state.forwardTypeDisabled  || cantEdit || this.state.disableForwardType}>
                                <option value="Open">Open</option>
                                <option value="Closed">Closed</option>
                            </select>
                        </div>
                    </div>
                </div>
            );

            //Deposit controls
            depositControls = (
                <div>
                    <div uk-grid="true">
                        <div className="uk-width-1-4" style={{marginTop: 20}}>
                            <label className="uk-form-label" htmlFor="form-create-deposit"><input id="form-create-deposit" type="checkbox" checked={this.state.requireDeposits} onChange={this.handleUpdateRequireDeposit} className="uk-checkbox" disabled={this.state.requireDepositsDisabled}/>Requires deposit?</label>
                        </div>
                        <div className="uk-width-1-4 uk-form-controls">
                            <label className="uk-form-label" htmlFor="form-deposit-percentage">Percentage</label>
                            <NumberInput id="form-deposit-percentage" type="Currency" scale={getScaleByCurrency(this.state.depositCurrency)} value={this.state.depositPercent} onChange={this.handleUpdateDepositPercentage} onBlur={this.handleUpdateDepositPercentageBlur} className="uk-input" disabled={this.state.depositPercentageDisabled || !this.state.requireDeposits}/>
                        </div>
                        <div className="uk-width-1-4 uk-form-controls">
                            <label className="uk-form-label" htmlFor="form-deposit-amount">Margin Amount</label>
                            <NumberInput id="form-deposit-amount" type="Currency" scale={getScaleByCurrency(this.state.depositCurrency)} value={this.state.depositAmount} onChange={this.handleUpdateDepositAmount} onBlur={this.handleUpdateDepositAmountBlur} className="uk-input" disabled={this.state.depositAmountDisabled || !this.state.requireDeposits}/>
                        </div>
                        <div className="uk-width-1-4 uk-form-controls">
                            <label className="uk-form-label" htmlFor="form-deposit-currency">Currency</label>
                            <DynamicSelect
                                className={this.formatValidSelect(this.state.validations.depositCurrency)}
                                placeholder="Select a currency..."
                                data={this.state.depositCurrencies}
                                objValue={{value:this.state.depositCurrency}}
                                getValue={this.handleUpdateDepositCurrency}
                                disabled={this.state.depositCurrencyDisabled || !this.state.requireDeposits}/>
                        </div>
                    </div>
                </div>
            );
        }

        /*if(this.state.forwardType === 'Closed'){
            valueDateDisabled = true;
        }*/

        //Deal type tabs
        var classNameSpot = '', classNameDrawdown = '', classNameForward = '', classNameSwap = '';
        let classNameBuyback = '';
        switch (this.state.dealType) {
            case 1: //Spot
            default:
                classNameSpot = 'uk-active';
                break;
            case 2: //Drawdown
                classNameDrawdown = 'uk-active';
                break;
            case 4: //Forward
                classNameForward = 'uk-active';
                break;
            case 6: //Swap
                classNameSwap = 'uk-active';
                break;
            case 11: //Use this case for Buyback deals
                classNameBuyback = 'uk-active';
                break;
        }
   
      /*  if (treatForwardAsSpot) { 
        
            classNameSpot = 'uk-active'
            classNameDrawdown = ''
            classNameForward = ''
            classNameSwap = '';
        } */

        //Date controls
        var valueDateLabel = 'Value Date';
        var valueDateClassName = 'uk-width-1-2 uk-form-controls';
        if([4, 6].includes(this.state.dealType)){
            valueDateClassName = 'uk-width-1-3 uk-form-controls';
            valueDateLabel = 'Near Date';
        }
        var dateControls;
        dateControls = (
            <div>
                <div uk-grid="true">
                    <div className={valueDateClassName}>
                        <label className="uk-form-label" htmlFor="form-value-date">{valueDateLabel}</label>
                        <DatePicker disabledDays={[0,6]} 
                            className={this.formatValidInput(this.state.validations.valueDate)} 
                            startDate={this.state.startDate} 
                            endDate={this.state.endDate} 
                            onDayChange={this.handleUpdateValueDate}
                            isHoliday={this.verifyHoliday}
                            value={this.state.valueDate}
                            readOnly={true}
                            disabled={this.state.valueDateDisabled  || cantEdit}
                            disableDropdown={true}/>
                    </div>
                    {forwardDaysControl}
                    {maturityDateControl}
                </div>
            </div>
        );

        //Market rate label
        var marketRateLabel = this.state.dealType === 6?'Forward Market Rate': 'Market Rate';
        if(this.state.currentMarketRate > 0){
            marketRateLabel += ' (Current market rate:' + this.state.currentMarketRate + ')';
        }

        /*if(this.state.dealHeaderID > 0 && (this.state.coveredDeals != "" || this.state.coverDealID > 0))
        {
            coverCustomerDisabled = true;
            useInterfaceDisabled = true;
        }*/

        //Cover bank control
        var coverBankControl;
        var useInterfaceControl;

        let sameCurrencyDeal = this.state.sellCurrency === this.state.buyCurrency
        let coverOtherDeals = this.props.objDealHeader !== undefined && this.props.objDealHeader.Customer !== undefined &&
            this.props.objDealHeader.Customer['Customer Type'] === 'Bank'

        let showDealCoverForSwap = this.props.objDealHeader !== undefined && this.props.objDealHeader.CoverDealID > 0
        
        let swapProfitBuyLabel = ''
        let swapProfitSellLabel = ''
        
        if (this.state.dealType === 6) {
            if (this.state.baseCurrency !== this.state.buyCurrency && this.state.sellCurrency !== this.state.baseCurrency) {
                let swapProfitBuy = this.state.buyAmount - this.state.farLegSellAmount                
                let swapBuyProfit = GetAmounts(this.state.baseCurrency, this.state.buyCurrency,
                    this.state.supportingRates !== undefined ? this.state.supportingRates.FarLegMarketRate2 : 1, swapProfitBuy, 0, false);                
                swapProfitBuyLabel = this.state.buyCurrency + ' ' + formatToCurrencyWithScale(swapProfitBuy, this.state.buyCurrency) + ' / '
                    + this.state.baseCurrency + ' ' + formatToCurrencyWithScale(swapBuyProfit, this.state.buyCurrency)
            }
            else if (this.state.baseCurrency !== this.state.buyCurrency) {
                let swapProfitBuy = this.state.buyAmount - this.state.farLegSellAmount
                let swapBuyProfit = GetAmounts(this.state.baseCurrency, this.state.buyCurrency, this.state.farLegMarketRate !== 0 ? this.state.farLegMarketRate : 1, swapProfitBuy, 0, false);                
                swapProfitBuyLabel = this.state.buyCurrency + ' ' + formatToCurrencyWithScale(swapProfitBuy, this.state.buyCurrency) + ' / '
                    + this.state.baseCurrency + ' ' + formatToCurrencyWithScale(swapBuyProfit, this.state.buyCurrency)
            }
            else if (this.state.lockedSide !== 'Buy' || this.state.buyCurrency !== this.state.baseCurrency || this.state.buyAmount !== this.state.farLegSellAmount) {
                let swapProfitBuy = this.state.buyAmount - this.state.farLegSellAmount
                swapProfitBuyLabel = this.state.buyCurrency + ' ' + formatToCurrencyWithScale(swapProfitBuy, this.state.buyCurrency)
            }

            if (this.state.baseCurrency !== this.state.sellCurrency && this.state.buyCurrency !== this.state.baseCurrency) {
                let swapProfitSell = this.state.farLegBuyAmount - this.state.sellAmount
                let swapSellProfit = GetAmounts(this.state.baseCurrency, this.state.sellCurrency, this.state.supportingRates !== undefined ? this.state.supportingRates.FarLegMarketRate1 : 1, swapProfitSell, 0, false);                
                swapProfitSellLabel = this.state.sellCurrency + ' ' + formatToCurrencyWithScale(swapProfitSell, this.state.sellCurrency) + ' / '
                    + this.state.baseCurrency + ' ' + formatToCurrencyWithScale(swapSellProfit, this.state.sellCurrency)
            }
            else if (this.state.baseCurrency !== this.state.sellCurrency) {
                let swapProfitSell = this.state.farLegBuyAmount - this.state.sellAmount                
                let swapSellProfit = GetAmounts(this.state.baseCurrency, this.state.sellCurrency, this.state.farLegMarketRate !== 0 ? this.state.farLegMarketRate : 1, swapProfitSell, 0, false);                
                swapProfitSellLabel = this.state.sellCurrency + ' ' + formatToCurrencyWithScale(swapProfitSell, this.state.sellCurrency) + ' / '
                    + this.state.baseCurrency + ' ' + formatToCurrencyWithScale(swapSellProfit, this.state.sellCurrency)
            }
            else if (this.state.lockedSide !== 'Sell' || this.state.sellCurrency !== this.state.baseCurrency || this.state.farLegBuyAmount !== this.state.sellAmount) {
                let swapProfitSell = this.state.farLegBuyAmount - this.state.sellAmount
                swapProfitSellLabel = this.state.sellCurrency + ' ' + formatToCurrencyWithScale(swapProfitSell, this.state.sellCurrency)
            }
        }        

        if(this.state.dealHeaderID === 0 || this.state.dealsToCover == undefined || this.state.dealsToCover.length == 0 ){
            coverBankControl = (
                <div>
                    <label className="uk-form-label" htmlFor="form-one-click-cover">One Click Cover</label>
                    <DynamicSelect
                        className="uk-select"
                        placeholder="Select a Bank..."
                        placeholderValue={0}
                        data={this.state.bankCustomers}
                        objValue={{value:this.state.coverCustomerID}}
                        getValue={this.handleUpdateCoverCustomer}
                        disabled = {this.state.coverCustomerDisabled || cantEdit || isDrawdown }
                        />
                </div>                
            );
            useInterfaceControl = (
                <div>
                    <label className="uk-form-label" htmlFor="form-use-interface">Use interface?</label>
                    <input className="uk-checkbox" type="checkbox" checked={this.state.useInterface} onChange={this.handleUpdateUseInterface} disabled={this.state.useInterfaceDisabled  || cantEdit || isDrawdown || sameCurrencyDeal} />
                </div>
            );
        }        
        
        //Deal form
        var dealForm;
        switch(this.state.dealType){
            case 1:
            case 2:
            case 4:
            default:
                dealForm = (
                    <div className="uk-width-expand@m uk-form-stacked">
                        <div>
                            <label className="uk-form-label" htmlFor="form-sell-amount">We Sell{sellLocked}</label>
                            <div uk-grid="true">
                                <div className="uk-width-1-2 uk-form-controls">
                                    <NumberInput className={this.formatValidInput(this.state.validations.sellAmount)} id="form-sell-amount" type="Currency" scale={getScaleByCurrency(this.state.sellCurrency)} /*scale={this.state.sellCurrencyDecimals}*/ value={this.state.sellAmount} onChange={this.handleUpdateSellAmount} onBlur={this.handleUpdateSellAmountBlur} disabled={this.state.sellAmountDisabled || cantEdit}/>
                                </div>
                                <div className="uk-width-1-2 uk-form-controls">
                                    <DynamicSelect
                                        className={this.formatValidSelect(this.state.validations.sellCurrency)}
                                        placeholder="Select a currency..."
                                        data={this.state.paymentCurrencies}
                                        objValue={{value:this.state.sellCurrency}}
                                        getValue={this.handleUpdateSellCurrency}
                                        disabled={this.state.sellCurrencyDisabled || cantEdit}/>
                                </div>
                            </div>
                        </div>

                        <div>
                            <label className="uk-form-label" htmlFor="form-buy-amount">We Buy{buyLocked}</label>
                            <div uk-grid="true">
                                <div className="uk-width-1-2 uk-form-controls">
                                    <NumberInput className={this.formatValidInput(this.state.validations.buyAmount)} id="form-buy-amount" type="Currency" scale={getScaleByCurrency(this.state.buyCurrency)} /*scale={this.state.buyCurrencyDecimals}*/ value={this.state.buyAmount} onChange={this.handleUpdateBuyAmount} onBlur={this.handleUpdateBuyAmountBlur} disabled={this.state.buyAmountDisabled || cantEdit}/>
                                </div>
                                <div className="uk-width-1-2 uk-form-controls">
                                    <DynamicSelect
                                        className={this.formatValidSelect(this.state.validations.buyCurrency)}
                                        placeholder="Select a currency..."
                                        data={this.state.settlementCurrencies}
                                        objValue={{value:this.state.buyCurrency}}
                                        getValue={this.handleUpdateBuyCurrency}
                                        disabled={this.state.buyCurrencyDisabled || cantEdit}/>
                                </div>
                            </div>
                        </div>

                        {forwardTypeControl}

                        {dateControls}

                        <div>
                            <div uk-grid="true">
                                <div className="uk-width-1-2 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-market-rate">{marketRateLabel}</label>
                                    <NumberInput className="uk-input" id="form-market-rate" type="Rate" scale={getScaleForCurrencyPair(this.state.buyCurrency, this.state.sellCurrency)} 
                                    value={this.state.marketRate} onChange={this.handleUpdateMarketRate} onBlur={this.handleValidateMarketRate} 
                                    disabled={this.state.marketRateDisabled || cantEdit /*|| coverOtherDeals*//*this.state.dealAlreadyCoveredInInterface*/}/>
                                </div>
                                
                                <div className="uk-width-1-2 uk-form-controls">
                                    <table width="100%">
                                        <tbody>
                                            <tr>
                                                <td width="75%">
                                                    {coverBankControl}
                                                </td>
                                                <td>
                                                    {useInterfaceControl}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                        <div>
                            <label className="uk-form-label" htmlFor="form-customer-rate">Customer Rate</label>
                            <div uk-grid="true">
                                <div className="uk-width-1-2 uk-form-controls">
                                    <NumberInput className="uk-input" id="form-customer-rate" type="Rate" scale={getScaleForCurrencyPair(this.state.buyCurrency, this.state.sellCurrency)} value={this.state.customerRate} onChange={this.handleUpdateCustomerRate} onBlur={this.handleValidateCustomerRate}  disabled={this.state.customerRateDisabled || cantEdit}/>
                                </div>
                                <div className="uk-width-1-2 uk-form-controls">
                                        {refreshButton}
                                        {broadcastButton}
                                </div>
                            </div>
                        </div>

                        <div className="uk-margin-small-top">
                            <table className="uk-table uk-table-divider border-table">
                                <tbody>
                                    <tr>
                                        <td>
                                            <label className="uk-form-label" htmlFor="form-spread-points">Spread(points)</label>
                                            <div className="uk-form-controls">
                                                {roundAmountByCurrencyPair(this.state.spreadPoints, this.state.buyCurrency, this.state.sellCurrency)}
                                            </div>
                                        </td>
                                        <td>
                                            <label className="uk-form-label" htmlFor="form-spread-percentage">Spread(%)</label>
                                            <div className="uk-form-controls">
                                                {formatToCurrency(this.state.spreadPercentage)}
                                            </div>
                                        </td>
                                        <td>
                                            <label className="uk-form-label" htmlFor="form-profit">Profit amount</label>
                                            <div className="uk-form-controls">
                                                {`${formatToCurrencyWithScale(this.state.profitAmount, this.state.baseCurrency)} ${this.state.baseCurrency}${profitCCyLabel}`}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                        {depositControls}

                        {dealFeesSection}

                    </div>
                );
                break;
            case 6:
                dealForm = (
                    <div className="uk-width-expand@m uk-form-stacked">
                        <div>
                            <div uk-grid="true">
                                <div className="uk-width-2-8 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-sell-amount" style={{ textAlign: 'right' }}>Near Leg</label>
                                </div>
                                <div className="uk-width-2-8 uk-form-controls"></div>
                                <div className="uk-width-2-8 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-sell-amount" style={{ textAlign: 'right' }}>Far Leg</label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div uk-grid="true">
                                <div className="uk-width-2-8 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-sell-amount">We Sell{sellLocked}</label>
                                    <NumberInput className={this.formatValidInput(this.state.validations.sellAmount)} id="form-sell-amount" type="Currency" scale={getScaleByCurrency(this.state.sellCurrency)} /* scale={this.state.sellCurrencyDecimals} */ value={this.state.sellAmount} onChange={this.handleUpdateSellAmount} onBlur={this.handleUpdateSellAmountBlur} disabled={this.state.sellAmountDisabled}/>
                                </div>
                                <div className="uk-width-2-8 uk-form-controls">
                                    <label className="uk-form-label">&nbsp;</label>
                                    <DynamicSelect
                                        className={this.formatValidSelect(this.state.validations.sellCurrency)}
                                        placeholder="Select a currency..."
                                        data={this.state.paymentCurrencies}
                                        objValue={{value:this.state.sellCurrency}}
                                        getValue={this.handleUpdateSellCurrency}
                                        disabled={this.state.sellCurrencyDisabled}/>
                                </div>
                                <div className="uk-width-2-8 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-sell-amount">&nbsp;</label>
                                    <NumberInput className={this.formatValidInput(this.state.validations.farLegSellAmount)} id="form-far-leg-sell-amount" type="Currency" scale={getScaleByCurrency(this.state.buyCurrency)} /*scale={this.state.buyCurrencyDecimals}*/ value={this.state.farLegSellAmount} onChange={this.handleUpdateFarLegSellAmount} onBlur={this.handleUpdateFarLegSellAmountBlur} disabled={this.state.farLegSellAmountDisabled}/>
                                </div>
                                <div className="uk-width-2-8 uk-form-controls" style={{ marginLeft: '-30px' }}>
                                    <label className="uk-form-label">&nbsp;</label>
                                    <input readOnly style={{ border: 'none', height: '30px', outline: 'none', width: '100%' }}
                                        value={swapProfitBuyLabel} />
                                </div>    
                            </div>
                        </div>

                        <div>
                            <div uk-grid="true">
                                <div className="uk-width-2-8 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-buy-amount">We Buy{buyLocked}</label>
                                    <NumberInput className={this.formatValidInput(this.state.validations.buyAmount)} id="form-buy-amount" type="Currency" scale={getScaleByCurrency(this.state.buyCurrency)} /*scale={this.state.buyCurrencyDecimals}*/ value={this.state.buyAmount} onChange={this.handleUpdateBuyAmount} onBlur={this.handleUpdateBuyAmountBlur} disabled={this.state.buyAmountDisabled}/>
                                </div>
                                <div className="uk-width-2-8 uk-form-controls">
                                <label className="uk-form-label">&nbsp;</label>
                                    <DynamicSelect
                                        className={this.formatValidSelect(this.state.validations.buyCurrency)}
                                        placeholder="Select a currency..."
                                        data={this.state.settlementCurrencies}
                                        objValue={{value:this.state.buyCurrency}}
                                        getValue={this.handleUpdateBuyCurrency}
                                        disabled={this.state.buyCurrencyDisabled}/>
                                </div>
                                <div className="uk-width-2-8 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-sell-amount">&nbsp;</label>
                                    <NumberInput className={this.formatValidInput(this.state.validations.farLegBuyAmount)} id="form-far-leg-buy-amount" type="Currency" scale={getScaleByCurrency(this.state.sellCurrency)} /*scale={this.state.sellCurrencyDecimals}*/ value={this.state.farLegBuyAmount} onChange={this.handleUpdateFarLegBuyAmount} onBlur={this.handleUpdateFarLegBuyAmountBlur} disabled={this.state.farLegBuyAmountDisabled}/>
                                </div>
                                <div className="uk-width-2-8 uk-form-controls" style={{ marginLeft: '-30px' }}>
                                    <label className="uk-form-label">&nbsp;</label>
                                    <input readOnly style={{ border: 'none', height: '30px', outline: 'none' }}
                                        value={swapProfitSellLabel} />
                                </div>
                            </div>
                        </div>      

                        {/* {dateControls} */}
                        <div> 
                            <div uk-grid="true">
                                <div className="uk-width-2-8 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-value-date">{valueDateLabel}</label>
                                        <DatePicker disabledDays={[0,6]} 
                                            className={this.formatValidInput(this.state.validations.valueDate)} 
                                            startDate={this.state.startDate} 
                                            endDate={this.state.endDate} 
                                            onDayChange={this.handleUpdateValueDate}
                                            isHoliday={this.verifyHoliday}
                                            value={this.state.valueDate}
                                            readOnly={true}
                                            disabled={this.state.valueDateDisabled  || cantEdit}
                                            disableDropdown={true}/>
                                </div>
                                <div className="uk-width-2-8 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-forward-days">Days</label>
                                        <NumberInput type="Integer" value={this.state.forwardDays} onChange={this.handleUpdateForwardDays} onBlur={this.updateForwardDaysBlur} disabled={this.state.forwardDaysDisabled || cantEdit} className="uk-input"></NumberInput>
                                </div>
                                <div className="uk-width-2-8 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-maturity-date">Far Date</label>
                                    <DatePicker disabledDays={[0,6]} 
                                        className={this.formatValidInput(this.state.validations.maturityDate)} 
                                        startDate={this.state.startDateMaturity} 
                                        endDate={this.state.endDate} 
                                        onDayChange={this.handleUpdateMaturityDate}
                                        isHoliday={this.verifyHoliday}
                                        value={this.state.maturityDate}
                                        readOnly={true}
                                        disabled={this.state.maturityDateDisabled  || cantEdit}
                                        disableDropdown={true}/>
                                </div>
                            </div>
                        </div>

                        <div>
                        &nbsp;
                        </div>

                        <div>
                        <hr/>
                        </div>

                        <div style={{ display: 'none' }}>
                        <label className="uk-form-label" htmlFor="form-forward-market-rate">{this.state.dealType == 6 ? 'Spot' : 'Original Market Rate'}</label>
                            <div uk-grid="true">
                                <div className="uk-width-2-8 uk-form-controls">
                                    <NumberInput className="uk-input" id="form-near-leg-forward-market-rate" type="Rate" scale={getScaleForCurrencyPair(this.state.buyCurrency, this.state.sellCurrency)} value={this.state.nearLegOriginalMarketSpotRate} /*onChange={this.handleUpdateNearLegOriginalMarketRate} onBlur={this.handleValidateNearLegOriginalMarketRate}*/ disabled={this.state.nearLegOriginalMarketRateDisabled}/>
                                </div>
                                <div className="uk-width-2-8 uk-form-controls"></div>
                                <div className="uk-width-2-8 uk-form-controls">
                                    <NumberInput className="uk-input" id="form-far-leg-original-market-rate" type="Rate" scale={getScaleForCurrencyPair(this.state.buyCurrency, this.state.sellCurrency)} value={this.state.farLegOriginalMarketSpotRate} /*onChange={this.handleUpdateFarLegOriginalMarketRate} onBlur={this.handleValidateFarLegOriginalMarketRate}*/ disabled={this.state.farLegOriginalMarketRateDisabled}/>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'none' }}>
                            <label className="uk-form-label" htmlFor="form-forward-points">Forward Points</label>
                            <div uk-grid="true">
                                <div className="uk-width-2-8 uk-form-controls">
                                    <NumberInput className="uk-input" id="form-near-leg-fwd-points" type="Rate" scale={this.state.forwardPointsScale} value={this.state.nearLegForwardPoints} /*onChange={this.handleUpdateNearLegForwardPoints}*/ disabled={false} negative={true}/>
                                </div>
                                <div className="uk-width-2-8 uk-form-controls"></div>
                                <div className="uk-width-2-8 uk-form-controls">
                                    <NumberInput className="uk-input" id="form-far-leg-fwd-points" type="Rate" scale={this.state.forwardPointsScale} value={this.state.farLegForwardPoints} /*onChange={this.handleUpdateFarLegForwardPoints}*/ disabled={false} negative={true}/>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div uk-grid="true">
                                <div className="uk-width-2-8 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-market-rate">Market Rate</label>
                                    <NumberInput className="uk-input" id="form-near-leg-market-rate" type="Rate" scale={getScaleForCurrencyPair(this.state.buyCurrency, this.state.sellCurrency)} value={this.state.nearLegMarketRate} onChange={this.handleUpdateNearLegMarketRate} onBlur={this.handleValidateNearLegMarketRate} disabled={this.state.nearLegMarketRateDisabled}/>
                                </div>
                                <div className="uk-width-2-8 uk-form-controls">
                                    {/* {coverBankControl} */}
                                </div>
                                <div className="uk-width-2-8 uk-form-controls">
                                    <label className="uk-form-label">&nbsp;</label>
                                    <NumberInput className="uk-input" id="form-far-leg-market-rate" type="Rate" scale={getScaleForCurrencyPair(this.state.buyCurrency, this.state.sellCurrency)} value={this.state.farLegMarketRate} onChange={this.handleUpdateFarLegMarketRate} onBlur={this.handleValidateFarLegMarketRate} disabled={this.state.farLegMarketRateDisabled}/>
                                </div>
                            </div>
                        </div>

                        <div>
                            
                            <div uk-grid="true">
                                <div className="uk-width-2-8 uk-form-controls">
                                    <label className="uk-form-label" htmlFor="form-spread">Spread</label>
                                    <NumberInput className="uk-input" id="form-near-leg-spread" type="Currency" scale={2} value={this.state.nearLegSpread} onChange={this.handleUpdateNearLegSpread} onBlur={this.handleUpdateNearLegSpreadBlur} disabled={this.state.nearLegSpreadDisabled} negative={true}/>
                                </div>
                                <div className="uk-width-2-8 uk-form-controls">
                                    {/* {useInterfaceControl} */}
                                </div>
                                <div className="uk-width-2-8 uk-form-controls">
                                    <label className="uk-form-label">&nbsp;</label>
                                    <NumberInput className="uk-input" id="form-far-leg-spread" type="Currency" scale={2} value={this.state.farLegSpread} onChange={this.handleUpdateFarLegSpread} onBlur={this.handleUpdateFarLegSpreadBlur} disabled={this.state.farLegSpreadDisabled} negative={true}/>
                                </div>
                            </div>
                        </div>

                        <div>
                            <label className="uk-form-label" htmlFor="form-customer-rate">Customer Rate</label>
                            <div uk-grid="true">
                                <div className="uk-width-2-8 uk-form-controls">
                                    <NumberInput className="uk-input" id="form-near-leg-customer-rate" type="Rate" scale={getScaleForCurrencyPair(this.state.buyCurrency, this.state.sellCurrency)} value={this.state.nearLegCustomerRate} onChange={this.handleUpdateNearLegCustomerRate} onBlur={this.handleValidateNearLegCustomerRate} disabled={this.state.nearLegCustomerRateDisabled}/>
                                </div>
                                <div className="uk-width-2-8 uk-form-controls">
                                    
                                </div>
                                <div className="uk-width-2-8 uk-form-controls">
                                    <NumberInput className="uk-input" id="form-far-leg-customer-rate" type="Rate" scale={getScaleForCurrencyPair(this.state.buyCurrency, this.state.sellCurrency)} value={this.state.farLegCustomerRate} onChange={this.handleUpdateFarLegCustomerRate} onBlur={this.handleValidateFarLegCustomerRate} disabled={this.state.farLegCustomerRateDisabled}/>
                                </div>
                            </div>
                        </div>

                        <div>
                            <label className="uk-form-label" htmlFor="form-near-leg-profit">{`Profit (${this.state.baseCurrency})`}</label>
                            <div uk-grid="true">
                                <div className="uk-width-2-8 uk-form-controls">
                                    <NumberInput className="uk-input" id="form-near-leg-profit" type="Currency" scale={getScaleByCurrency(this.state.baseCurrency)} value={this.state.nearLegProfit} negative={true} disabled={true}/>
                                </div>
                                <div className="uk-width-2-8 uk-form-controls"></div>
                                <div className="uk-width-2-8 uk-form-controls">
                                    <NumberInput className="uk-input" id="form-far-leg-profit" type="Currency" scale={getScaleByCurrency(this.state.baseCurrency)} value={this.state.farLegProfit} negative={true} disabled={true}/>
                                </div>
                            </div>
                        </div>
                        {dealCCy !== this.state.baseCurrency &&
                        <div>
                            <label className="uk-form-label" htmlFor="form-near-leg-profit">{`Profit (${dealCCy})`}</label>
                            <div uk-grid="true">
                                <div className="uk-width-2-8 uk-form-controls">
                                    <NumberInput className="uk-input" id="form-near-leg-profit" type="Currency" scale={getScaleByCurrency(dealCCy)} value={this.state.nearLegProfitCcy} negative={true} disabled={true}/>
                                </div>
                                <div className="uk-width-2-8 uk-form-controls"></div>
                                <div className="uk-width-2-8 uk-form-controls">
                                    <NumberInput className="uk-input" id="form-far-leg-profit" type="Currency" scale={getScaleByCurrency(dealCCy)} value={this.state.farLegProfitCcy} negative={true} disabled={true}/>
                                </div>
                            </div>
                        </div>}

                        <div hidden={!showDealCoverForSwap}>
                            <label className="uk-form-label" htmlFor="form-near-leg-profit">Cover Deal</label>
                            <div uk-grid="true">
                                <div className="uk-width-2-8 uk-form-controls">
                                    <NumberInput className="uk-input" id="form-FarLegBuy-NearLegSell" type="Integer" value={this.props.objDealHeader.CoverDealID} disabled={true} />
                                </div>
                            </div>
                        </div>

                    </div>
                );
                break;
        
            case 11:
                let bCurr = this.props.objDealHeader !== undefined ? this.props.objDealHeader.SellCurrency : ''
                let sCurr = this.props.objDealHeader !== undefined ? this.props.objDealHeader.BuyCurrency : ''
                dealForm = <div className="uk-width-expand@m uk-form-stacked">
                    <BuybackForm cancelBuyback={() => this.setState({ dealType: 1 })} customerDeal={this.props.objDealHeader || {}}                        
                        valueDate={this.getNextBusinessDate(this.props.systemDate, 2, bCurr, sCurr)} bankCustomers={this.state.bankCustomers}
                        onBuybackSuccess={this.onBuybackSuccess}/>
                </div>
                break;
            }

        var rateSelectionView;
        if(this.state.showRateSelection){
            rateSelectionView = (<BroadcastRateSelectionDialog 
                    onClose={this.closeBroadcastRateSelection}
                    broadcastRateOptions={this.state.broadcastRateOptions}
                    onSelectBroadcastedRate={this.onSelectBroadcastedRate}
                />);
        }

        
                    
        return (
            <div className="uk-form-stacked">
                <div uk-grid="true">
                    <div className="uk-width-1-1">
                        <h3>{quoteNoLabel}</h3>
                        <ul className="uk-tab">
                            <li className={classNameSpot}><a onClick={this.onClickTab.bind(this, 1)}>Spot</a></li>
                            <li className={classNameForward}><a onClick={this.onClickTab.bind(this, 4)}>Forward</a></li>
                            <li className={classNameDrawdown}><a onClick={this.onClickTab.bind(this, 2)}>Drawdown</a></li>
                            <li className={classNameSwap}><a onClick={this.onClickTab.bind(this, 6)}>Swap</a></li>
                            <li className={classNameBuyback}><a onClick={this.onClickTab.bind(this, 11)} hidden={this.state.dealType !== 11}>Buy back</a></li>
                        </ul>
                    </div>
                    
                    {dealForm}
                    
                    <div style={{width: '5px'}}>
                        <hr className="uk-hr-vertical uk-divider-vertical"></hr>
                    </div>
                    <div className="uk-width-expand@m">
                        {rightView}
                    </div>
                </div>

                <hr className="uk-hr"/>

                <div uk-grid="true">
                <div className="uk-width-4-5">
                    <button className="uk-button uk-button-green" onClick={this.handleNewCustomer} style={buttonStyle}>New Customer</button>
                    <button className="uk-button uk-button-green" onClick={this.handleNewDealClick} style={buttonStyle}>New Deal</button>                    
                    {duplicateButton}
                    {saveQuoteButton}
                    {bookNowButton}
                    {bookNowAndConfirmButton}
                    {uncoveredDealsButton}
                    {voidButton}
                    {adjustButton}
                    {buyBackButton}
                    {printPDFButton}
                    {/*{drawdownsButton}*/}
                    {supportingRatesButton}
                    {editButton}                    
                    {linkToDeal}                    
                    {closeoutButton}
                    {reverseButton}
                    {dealVersionButton}                    
                   
                    {this.state.dealType !== 11 && this.state.status === 'Accepted' && <button className="uk-button uk-button-green" style={buttonStyle} onClick={this.handleShowEmailConfirmationClick}>Email Confirmation</button>}
                    {sendInwardButton}
                    
                </div>
                <div className="uk-width-1-5">
                {nextButton}
                <button className="uk-button uk-button-green uk-align-right" style={buttonStyle} onClick={this.handleBackClick}>Back</button>
                    
                </div>
                </div>
                <LoadingBox loading={this.state.loading}/>

                <AlertBox id="global-alert" 
                    open={this.state.showAlert} 
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    type="Ok"
                    okClick={this.okGlobalAlert}/>

                <AlertBox id="void-confirmation"
                    open={this.state.showVoidConfirmation}
                    title="Confirmation"
                    message="Are you sure you want to void this deal?"
                    type="Yes/No"
                    onClose={this.okVoidConfirmation}
                    yesClick={this.yesVoidConfirmation}
                    noClick={this.okVoidConfirmation}/>
                
                <AlertBox id="void-onpartner-confirmation"
                    open={this.state.showVoidOnPartnerConfirmation}
                    title="Confirmation"
                    message={this.state.voidOnPartnerConfirmationMessage}
                    type="Yes/No"
                    onClose={this.okVoidOnPartnerConfirmation}
                    yesClick={this.yesVoidOnPartnerConfirmation}
                    noClick={this.okVoidOnPartnerConfirmation}/>

                <AlertBox id="adjust-confirmation"
                    open={this.state.showAdjustConfirmation}
                    title="Confirmation"
                    message="Are you sure you want to adjust this deal?"
                    type="Yes/No"
                    onClose={this.okAdjustConfirmation}
                    yesClick={this.yesAdjustConfirmation}
                    noClick={this.okAdjustConfirmation}/>

                    {rateSelectionView}
                {this.state.showDealConfirmation && <EmailConfirmationDialog
                    open            = {this.state.showDealConfirmation}
                    onClose         = {this.closeDealConfirmation}
                    dealHeaderId    = {this.state.dealHeaderID}
                    customerId      = {this.state.customerID}
                    contacts_length = {this.setContacts_length}
                />}
                {this.state.showDealConfirmationForm && <NewDealConfirmationFormDialog
                    open                            = {this.state.showDealConfirmationForm}
                    onClose                         = {this.handleBackLink.bind(this)} 
                    dealHeaderInfo                  = {this.state.deal} 
                    dealHeaderId                    = {this.state.dealHeaderID}
                    lookUpTables                    = {this.props.lookUpTables}
                    showIncludeInwardConfirmation   = {this.state.allowAdditionalSettlements}
                />}
                <LinkToDeal
                    open={this.state.showLinkToDeal}
                    onClose={this.closeLinkToDeal}
                    onAcceptLinkDeal={this.onAcceptLinkDeal}
                    buyCurrency={this.state.buyCurrency}
                    sellCurrency={this.state.sellCurrency}
                    sellAmount={this.state.sellAmount}
                    buyAmount={this.state.buyAmount}
                    valueDate={this.state.valueDate}
                    dealHeaderIdToCover={this.state.dealHeaderID}
                    coverDealId={this.props.objDealHeader.CoverDealID}
                    dealCustomerType={this.props.objCustomer !== undefined ? this.props.objCustomer['Customer Type'] : ''}
                    customerId={this.state.customerID}
                    dealIdToLink={this.state.dealIdToLink}
                />
                <AdditionalInformationScreen
                    open={this.state.addInfo}
                    close={this.closeAddInformation}
                    returnObj={this.addInformationObj}
                />
                <DealVersionViewer
                    open={this.state.openDealVersions}
                    close={this.closeDealVersionsClick}
                    dealHeaderID={this.state.dealHeaderID}
                />
                <AlertBox id="closeout-confirmation"
                    open={this.state.showCloseoutConfirmation}
                    title="Confirmation"
                    message={this.state.closeoutMessage}
                    type="Yes/No"
                    onClose={this.okCloseoutConfirmation}
                    yesClick={this.yesCloseoutConfirmation}
                    noClick={this.okCloseoutConfirmation}/>
                <AlertBox id="closeout-result"
                    open={this.state.showCloseoutResult}
                    title="Confirmation"
                    message={this.state.closeoutMessage}
                    type="Yes/No"
                    onClose={this.okCloseoutResult}
                    yesClick={this.yesCloseoutResult}
                    noClick={this.okCloseoutResult}/>
                <AlertBox id="message"
                    open={this.state.showMessage}
                    title="Message"
                    message={this.state.messageMessage}
                    type="Ok"
                    onClose={this.onCloseMessage}
                    okClick={this.okClickMessage}/>
                {this.state.showValidationSMSCode && <SMSValidationBox id="validate-sms-code" 
                    open={this.state.showValidationSMSCode} 
                    onClose={this.closeValidationSMSCode} 
                    title={this.state.titleValidationSMSCode} 
                    message={this.state.messageValidationSMSCode} 
                    type="Validate" 
                    noClick={this.closeValidationSMSCode}
                    sendSMSCode={this.sendSMSCode}
                    validateSMSCode={this.validatePhone.bind(this)}/>}
            </div>
        );
    }
}